export const mode = [
    { value: 'm1', label: 'Online' },
    { value: 'm2', label: 'Call' },
    { value: 'm3', label: 'Email' },
    { value: 'm4', label: 'Presonal Visit' }
]

export const priority = [
    { value: 'p1', label: 'Low' },
    { value: 'p2', label: 'Medium' },
    { value: 'p3', label: 'High' },
    { value: 'p4', label: 'Critical' }
]

export const status = [
    { value: 's1', label: 'New' },
    { value: 's2', label: 'Assigned' },
    { value: 's3', label: 'Blocked' },
    { value: 's4', label: 'Approval Pending' },
    { value: 's5', label: 'Rejected' },
    { value: 's6', label: 'Resolved' }
]

export const location = [
    { value: 'l1', label: 'Bangalore' },
    { value: 'l2', label: 'Delhi' },
]

export const categories = [
    { label: 'Application Login', href: '/user/serviceList?sl=1001', current: true, description: 'This is application login related issue ', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Device Request', href: '/user/serviceList?sl=1002', current: true, description: 'Make a new device request', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Email', href: '/user/serviceList?sl=1003', current: true, description: 'Outlook configuration etc.', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Hardware', href: '/user/serviceList?sl=1004', current: true, description: 'Hardware related issues', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Tally', href: '/user/serviceList?sl=1005', current: true, description: 'Tally related issue', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Internet', href: '/user/serviceList?sl=1006', current: true, description: 'Network related issue', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Software', href: '/user/serviceList?sl=1007', current: true, description: 'Software related issue', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'User Management', href: '/user/serviceList?sl=1008', current: true, description: 'Device relocation', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'URL Access', href: '/user/serviceList?sl=1009', current: true, description: 'Blocked site related issue', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
    { label: 'Communication', href: '/user/serviceList?sl=1010', current: true, description: 'Communication related issue', imagelink: '../../assets/img/react.jpg', icon: 'fa fa-laptop' },
]

export const categorydata =
{
    "1001": [
        { value: 'T001', label: 'Application Crashes', href: '/user/newRequest?tt=T001', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T002', label: 'Unable to copy data from file server', href: '/user/newRequest?tt=T002', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T003', label: 'Unable to login into file server', href: '/user/newRequest?tt=T003', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T004', label: 'Unable to login into PC', href: '/user/newRequest?tt=T004', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1002": [
        { value: 'T005', label: 'Desktop Request', href: '/user/newRequest?tt=T005', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T006', label: 'Headset Request', href: '/user/newRequest?tt=T006', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T007', label: 'Laptop Request', href: '/user/newRequest?tt=T007', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T008', label: 'Mac Device Request', href: '/user/newRequest?tt=T008', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T009', label: 'Mobile Device Request', href: '/user/newRequest?tt=T009', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T010', label: 'Monitor Request', href: '/user/newRequest?tt=T010', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1003": [
        { value: 'T011', label: 'Outlook configuration', href: '/user/newRequest?tt=T011', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T012', label: 'Email account configuration in Mobile', href: '/user/newRequest?tt=T012', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T013', label: 'Email sending issue', href: '/user/newRequest?tt=T013', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T014', label: 'Mail fetching', href: '/user/newRequest?tt=T014', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1004": [
        { value: 'T015', label: 'Keyboard problem', href: '/user/newRequest?tt=T015', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T016', label: 'Monitor display problem', href: '/user/newRequest?tt=T016', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T017', label: 'Mouse not working', href: '/user/newRequest?tt=T017', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T018', label: 'PC does not boot', href: '/user/newRequest?tt=T018', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T019', label: 'Printer problem', href: '/user/newRequest?tt=T019', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1005": [
        { value: 'T020', label: 'Provide tally access', href: '/user/newRequest?tt=T020', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T021', label: 'Tally login issue', href: '/user/newRequest?tt=T021', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T022', label: 'Open file', href: '/user/newRequest?tt=T022', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1006": [
        { value: 'T023', label: 'Network is slow', href: '/user/newRequest?tt=T023', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T024', label: 'Wireless connection is not working', href: '/user/newRequest?tt=T024', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1007": [
        { value: 'T025', label: 'Software installation issue', href: '/user/newRequest?tt=T025', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1008": [
        { value: 'T026', label: 'System movement request', href: '/user/newRequest?tt=T026', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1009": [
        { value: 'T027', label: 'Unable to browse', href: '/user/newRequest?tt=T027', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ],
    "1010": [
        { value: 'T028', label: 'Skype call issue', href: '/user/newRequest?tt=T028', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T029', label: 'Teams call issue', href: '/user/newRequest?tt=T029', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T030', label: 'Zoom call issue', href: '/user/newRequest?tt=T030', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T031', label: 'Telephone not workking', href: '/user/newRequest?tt=T031', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ]
}


export const categorydatalist =
    [
        { value: 'T001', label: 'Application Crashes', href: '/user/newRequest?sl=1001&tt=T001', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T002', label: 'Unable to copy data from file server', href: '/user/newRequest?sl=1001&tt=T002', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T003', label: 'Unable to login into file server', href: '/user/newRequest?sl=1001&tt=T003', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T004', label: 'Unable to login into PC', href: '/user/newRequest?sl=1001&tt=T004', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T005', label: 'Desktop Request', href: '/user/newRequest?sl=1002&tt=T005', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T006', label: 'Headset Request', href: '/user/newRequest?sl=1002&tt=T006', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T007', label: 'Laptop Request', href: '/user/newRequest?sl=1002&tt=T007', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T008', label: 'Mac Device Request', href: '/user/newRequest?sl=1002&tt=T008', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T009', label: 'Mobile Device Request', href: '/user/newRequest?sl=1002&tt=T009', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T010', label: 'Monitor Request', href: '/user/newRequest?sl=1002&tt=T010', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T011', label: 'Outlook configuration', href: '/user/newRequest?sl=1002&tt=T011', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T012', label: 'Email account configuration in Mobile?sl=1002&tt=T012', href: '/user/newRequest', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T013', label: 'Email sending issue', href: '/user/newRequest?sl=1002&tt=T013', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T014', label: 'Mail fetching', href: '/user/newRequest?sl=1002&tt=T014', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T015', label: 'Keyboard problem', href: '/user/newRequest?sl=1002&tt=T015', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T016', label: 'Monitor display problem', href: '/user/newRequest?sl=1002&tt=T016', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T017', label: 'Mouse not working', href: '/user/newRequest?sl=1002&tt=T017', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T018', label: 'PC does not boot', href: '/user/newRequest?sl=1002&tt=T018', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T019', label: 'Printer problem', href: '/user/newRequest?sl=1002&tt=T019', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T020', label: 'Provide tally access', href: '/user/newRequest?sl=1002&tt=T020', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T021', label: 'Tally login issue', href: '/user/newRequest?sl=1002&tt=T021', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T022', label: 'Open file', href: '/user/newRequest?sl=1002&tt=T022', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T023', label: 'Network is slow', href: '/user/newRequest?sl=1002&tt=T023', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T024', label: 'Wireless connection is not working', href: '/user/newRequest?sl=1002&tt=T024', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T025', label: 'Software installation issue', href: '/user/newRequest?sl=1002&tt=T025', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T026', label: 'System movement request', href: '/user/newRequest?sl=1002&tt=T026', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T027', label: 'Unable to browse', href: '/user/newRequest?sl=1002&tt=T027', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T028', label: 'Skype call issue', href: '/user/newRequest?sl=1002&tt=T028', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T029', label: 'Teams call issue', href: '/user/newRequest?sl=1002&tt=T029', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T030', label: 'Zoom call issue', href: '/user/newRequest?sl=1002&tt=T030', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
        { value: 'T031', label: 'Telephone not workking', href: '/user/newRequest?sl=1002&tt=T031', current: true, description: ' ', imagelink: '../../assets/img/react.jpg' },
    ]


export const engineerlist =
    [
        { value: '4', label: 'Sailesh Kumar' },
        { value: '2', label: 'Amit Kumar' },
        { value: '1', label: 'Avinash Kumar' }
    ]

export const hsnCategory =
    [
        { value: 'Applicable', label: 'Applicable' },
        { value: 'Not Applicable', label: 'Not Applicable' }
    ]

export const hsnGST =
    [
        { value: 'Applicable', label: 'Applicable' },
        { value: 'Not Applicable', label: 'Not Applicable' }
    ]

export const hsnUnits = [
    { value: 'Nos', label: 'Nos' },
    { value: 'Days', label: 'Days' },
    { value: 'Month', label: 'Month' },
    { value: 'Pcs', label: 'Pcs' },
    { value: 'Sqfts', label: 'Sqfts' },
    { value: 'Sqmts', label: 'Sqmts' },
]

export const hsnTaxabilityType = [
    { value: 'Exempt', label: 'Exempt' },
    { value: 'Nil Rated', label: 'Nil Rated' },
    { value: 'Non-GST', label: 'Non-GST' },
    { value: 'Taxable', label: 'Taxable' },
]

export const hsnSupplyType = [
    { value: 'Capital Goods', label: 'Capital Goods' },
    { value: 'Goods', label: 'Goods' },
    { value: 'Services', label: 'Services' }
]

export const hsnCreditType = [
    { value: 'Capital Goods', label: 'Capital Goods' },
    { value: 'Input Goods', label: 'Input Goods' },
    { value: 'Input Services', label: 'Input Services' }
]

export const optionSelect = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
]

export const approvalRequiredTypes = ['USB Allow Request',
    'New Software Installation',
    'New User Creation/Deletion',
    'SSD Upgrade',
    'Ram upgrade',
    'Mail ID Creation',
    'Mail ID Deletion',
    'Voucher Cancellation',
    'New Company Creation',
    'Voucher Uploader Issue',
    'Vendor Creation',
    'Book Open/Close request',
    'Voucher Access',
    'User Creation/Alteration',
    'Cost center Creation/Alteration',
    'Group creation/Alteration',
    'HSN Creation',
    'HSN Alteration',
    'GL Code Creation/Alteration',
    'Multiple State Address Update(Company/vendor/GST details)',
    'Others'
]

export const anydeskRequiredTypes = [
    'System Slow',
    'Windows/Drivers update',
    'USB Allow Request',
    'New Software Installation',
    'Digital Signature Issue (DSC)',
    'Accops Issue',
    'Remote Desktop Issue',
    'Laptop Login Issue',
    'Network/Shared Drive Issue',
    'Windows Issue',
    'Keypad Issue',
    'Touchpad Issue',
    'Screen Issue',
    'Charging Issue',
    'Battery Issue',
    'Printer Issue',
    'PC Does not Boot',
    'Outlook Configuration',
    'Mail Forwarding',
    'Mail Storage Issue',
    'wifi Issue',
    'Lan Issue',
    'Slow Browsing',
    'Website Link Not Working',
    'Tally Slow Issue',
    'Voucher Uploader Issue',
    'Importing and Exporting Reports Issue',
    'Voucher Cancellation',
    'Others'
]


import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import { getData } from "../../serverRequest";
import UserSolutions from "../../components/Cards/UserSolutions.js";
import ApproverTicketsTable from "../../components/Tables/ApproverTicketsTable";
import LoadingSpinner from "../../components/Loadder";

export default function ApproverTickets({ color }) {
    const [loading, setLoading] = useState(true);
    // const [approvalTickets, setApprovalTickets] = useState([]);
    const [totalTickets, setTotalTickets] = React.useState([]);
    const searchParams = new URLSearchParams(window.location.search);
    
    const ticketFilter = searchParams.get('filter');
    //console.log(ticketFilter);
    const filterTicketData = (tickets_data) => {
        //console.log(tickets_data);
        let openTicket = [];
        let closeTicket = [];
        let totalTicket = [];
        if(tickets_data){
          for (let i = 0; i < tickets_data.length; i++) {
            if (tickets_data[i]['status'] === 'Approval Pending') {
              openTicket.push(tickets_data[i]);
              totalTicket.push(tickets_data[i]);
            }
            else if (tickets_data[i]['status'] !== 'Approval Pending') {
              closeTicket.push(tickets_data[i]);
              totalTicket.push(tickets_data[i]);
            }
            else{
              totalTicket.push(tickets_data[i]);
            }
          }
        }
        
        if(ticketFilter === 'approvalPending'){
          totalTicket = openTicket;
        }
        else if(ticketFilter === 'approved'){
          totalTicket = closeTicket
        }
        
        //console.log(totalTicket);
        setTotalTickets(totalTicket);
        return ({ "open": openTicket, "close": closeTicket, "total": totalTicket })
      }

    React.useEffect(() => {

        async function fetchData() {
            let resData = await getData('/api/tickets/')
            let approval_tickets = resData['data']['approval_tickets_data']
            //console.log(approval_tickets);
            let ticketData = await filterTicketData(approval_tickets)

            setLoading(false);
        }
        fetchData();


    }, [])
    return (
        <>
            {loading ? (
                <LoadingSpinner></LoadingSpinner>
            ) : (<>
                <div
                    className={
                        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
                    }
                >
                    <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                                
                            </div>
                        </div>
                    </div>
                    <div className="block w-full overflow-x-auto mb-0 px-4 py-3">
                        <ApproverTicketsTable approvalTickets={totalTickets}></ApproverTicketsTable>
                    </div>
                </div>
            </>)}
        </>
    );
}
UserSolutions.defaultProps = {
    color: "light",
};

UserSolutions.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};

import React, { useState } from "react";
import { getData } from "../../../../serverRequest";
import LoadingSpinner from "../../../../components/Loadder";
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "../DatePicker.css";

// components
import StockListTable from "../../../../components/Tables/StockListTable";

export default function StockList() {
    const [open, setOpen] = React.useState(true);
    const [totalStocks, setTotalStocks] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const stockFilter = searchParams.get('filter');
    //console.log(stockFilter);
    const toggleDrawer = () => {
      setOpen(!open);
    };
  
    const formatDate = (dateString) => {
      return moment(dateString).format('DD/MM/YYYY');
    };
  
    const filterStockData = (stocks_data) => {
      //console.log(tickets_data);
      let totalstock = [];
      if(stocks_data){
        for (let i = 0; i < stocks_data.length; i++) {
            totalstock.push(stocks_data[i])
        }
      }
     
      setTotalStocks(totalstock);
      return ({ "total": totalStocks })
    }
    React.useEffect(() => {
      async function fetchData() {
        let resData = await getData('/api/employee/stocks/?is_stock=1')
        let stocks_data = resData['data']['stocks']
        console.log(stocks_data);
        let stockData = await filterStockData(stocks_data)
  
       
        setLoading(false);
      }
      fetchData();
  
  
    }, [])
    return (
        <>

            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Stock</h6>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w- mb-3">
                                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} placeholderText="Start Date" className="w-full mb-3 px-3 py-2 border rounded"/>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w- mb-3">
                                    <DatePicker selected={endDate} onChange={date => setEndDate(date)} placeholderText="End Date" className="w-full mb-3 px-3 py-2 border rounded"/>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (<>
                        <div
                            className={
                                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                                ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                            }
                        >

                            <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                                {/* Projects table */}
                                <StockListTable totalStocks={totalStocks}></StockListTable>
                            </div>
                        </div>
                    </>)}
                </div>
            </main>

        </>
    );
}

import React, { useState } from "react";
import LoadingSpinner from "../../../components/Loadder";
import { postData } from "../../../serverRequest";
import Select from 'react-select';
import moment from 'moment';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function TicketForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requesterEmail, setRequesterEmail] = useState();
  const [requesterEntity, setRequesterEntity] = useState();
  const [ticketType, setTicketType] = useState();
  const [ticketConnectionId, setTicketConnectionId] = useState();
  const [ticketMode, setTicketMode] = useState();
  const [ticketModes, setTicketModes] = useState();
  const [ticketPriority, setTicketPriority] = useState();
  const [ticketPriorities, setTicketPriorities] = useState();
  const [ticketNotify, setTicketNotify] = useState();
  const [ticketStatus, setTicketStatus] = useState();
  const [ticketStatusList, setTicketStatusList] = useState();
  const [ticketAssignedTo, setTicketAssignedTo] = useState();
  const [ticketTitle, setTicketTitle] = React.useState();
  const [ticketComments, setTicketComments] = useState();
  const [ticketDescription, setTicketDescription] = React.useState();
  const [companyAdmins, setCompanyAdmins] = useState();
  const [dataUrls, setDataUrls] = useState([]);
  const [approvalDate, setApprovalDate] = useState();
  const [approvalRequired, setApprovalRequired] = useState();

  const formatDate = (dateString) => {
    var localTime = moment.utc(dateString).toDate();
    localTime = moment(localTime).subtract({ 'hours': 0, 'minutes': 0 }).format('YYYY-MM-DD HH:mm:ss');
    return localTime;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const { requesterEmail, requesterEntity, ticketConnectionId, ticketType, ticketMode, ticketPriority, ticketNotify, approvalRequired, approvalDate, ticketStatus, ticketAssignedTo, ticketTitle, ticketComments, ticketDescription, dataUrls } = document.forms[0];

    const formData = {
      'requesterEmail': requesterEmail ? requesterEmail.value : '',
      'requesterEntity': requesterEntity ? requesterEntity.value : '',
      'ticketConnectionId': ticketConnectionId ? ticketConnectionId.value : '',
      'ticketType': ticketType ? ticketType.value : '',
      'ticketMode': ticketMode ? ticketMode.value : '',
      'ticketPriority': ticketPriority ? ticketPriority.value : '',
      'ticketNotify': ticketNotify ? ticketNotify.value : '',
      'approvalRequired': approvalRequired ? approvalRequired.value : '',
      'approvalDate': approvalDate ? approvalDate.value : '',
      'ticketStatus': ticketStatus ? ticketStatus.value : '',
      'ticketAssignedTo': ticketAssignedTo ? ticketAssignedTo.value : '',
      'ticketTitle': ticketTitle ? ticketTitle.value : '',
      'ticketComments': ticketComments ? ticketComments.value : '',
      'ticketDescription': ticketDescription ? ticketDescription.value : '',
      'dataUrls': dataUrls,
    };

    try {
      const res = await postData("/api/employee/", formData);

      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = '/admin/admins';
      } else {
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  return (
    <div className="ticket-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Ticket Form</h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Requester
              </h6>
              <div className="flex flex-wrap justify-between">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Email Id
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={requesterEmail}
                      name="requesterEmail"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Entity
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={requesterEntity}
                      name="requesterEntity"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Anydesk Id
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={ticketConnectionId}
                      name="ticketConnectionId"
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Basic Details
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Ticket Type
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={ticketType}
                      name="ticket_type" />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Mode
                    </label>
                    <Select options={ticketModes} defaultValue={ticketMode} name="mode" onChange={setTicketMode} />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Priority
                    </label>
                    <Select options={ticketPriorities} defaultValue={ticketPriority} name="priority" onChange={setTicketPriority} />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Approver Email
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={ticketNotify}
                      name="notify_to"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Approval
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={approvalRequired ? (approvalDate ? 'Approved On: ' + approvalDate : 'Required') : 'Not Required'}     
                      name=""
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Status
                    </label>
                    <Select options={ticketStatusList} defaultValue={ticketStatus} name="status" onChange={setTicketStatus} />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Assigned To
                    </label>
                    <Select name="assigned_to" options={companyAdmins} defaultValue={ticketAssignedTo} onChange={setTicketAssignedTo} />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Requirement Specific
              </h6>
              <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                          <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password">
                              Subject
                          </label>
                          <input
                              type="text" name="subject"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue={ticketTitle}
                              required />
                      </div>
                  </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                      <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password">
                          Description
                      </label>
                      <SunEditor name="description" height="250px" defaultValue={ticketDescription} />                                        
                  </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Attachments
              </h6>
              <div className="flex flex-wrap">
                  {dataUrls.map((file, index) => (
                      <div key={index} className="preview w-full lg:w-3/12 px-4">
                          <img src={file} />

                      </div>
                  ))}
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Remarks
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Remarks
                    </label>
                    <textarea
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={ticketComments}
                      rows="4"
                      name="remarks"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="Submit" 
                  value="Submit" />                
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

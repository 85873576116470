import React, { useState } from "react";
import LoadingSpinner from "../../../components/Loadder"; 
import { postData } from "../../../serverRequest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function TimesheetForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);  
  const [timesheetDate, setTimesheetDate] = useState(null);  
  const [timesheetTask] = useState();  
  const [timesheetTasktype] = useState();  
  const [timesheetStatus] = useState(); 

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    
    const { date, task, task_type, status } = document.forms[0];
  
    const formData = {
      'date': date ? date.toISOString() : "",
      'task': task ? task.value : '',
      'task_type': task_type ? task_type.value :'',
      'status': status ? status.value :'',
    };
  
    try {
      const res = await postData("/api/employee/", formData);
      
      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = '/admin/admins';
      } else {
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };
   
  return (
    <div className="timesheet-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Timesheet Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Date</label>
                <DatePicker
                       selected={timesheetDate}
                       onChange={(date) => setTimesheetDate(date)}
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       dateFormat="yyyy-MM-dd" defaultValue={timesheetDate} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Task</label>
                <input type="email"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="email" defaultValue={timesheetTask} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Task Type</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="company" defaultValue={timesheetTasktype} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Status</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="location" defaultValue={timesheetStatus} />
              </div>
            </div>  
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"
                />                            
            </div>          
          </div>
        </form>
      )}
    </div>
  );
}

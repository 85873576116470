import React, { useState } from "react";
import LoadingSpinner from "../../../components/Loadder"; 
import { getData, postData } from "../../../serverRequest";

export default function EntityData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const entityId = searchParams.get('entity_id');
  const [showModal, setShowModal] = React.useState(false);
  const [entityName, setEntityName] = useState();
  const [entityEmail, setEntityEmail] = useState();  
  const [companyAlias, setCompanyAlias] = useState();
  const [companyCode, setCompanyCode] = useState();
  const [city, setCity] = useState();
  const [tanNumber, setTanNumber] = useState();
  const [gstNumber, setGstNumber] = useState();
  const [isOnboarded]  = useState();
  const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
     //Prevent page reload
     setLoading(true);
     event.preventDefault();
     let { name, email, company_alias, company_code, city, tan_number, gst_number, is_onboarded } = document.forms[0];
     let reqData = {
       'company_id': entityId,
       'id': entityId,
       'name': name ? name.value : '',
       'email': email ? email.value : '',
       'company_alias': company_alias ? company_alias.value : '', 
       'company_code': company_code ? company_code.value : '',
       'city': city ? city.value : '',
       'tan_number': tan_number ? tan_number.value : '',
       'gst_number': gst_number ? gst_number.value : '',
       'is_onboarded': is_onboarded ? is_onboarded.value : ''
     }
     
     let resData = await postData('/api/company/', reqData); 
     if (resData['data']['code'] === 200) {
       setLoading(false);
       setShowModal(true);
       window.location.href = '/admin/entities'; 
     }
   };

   React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/company?company_id='+entityId)
      let entities_data = resData['data']['entities']
      console.log(entities_data);
      if(entities_data){
        setEntityName(entities_data['name']);
        setEntityEmail(entities_data['email']);
        setCompanyAlias(entities_data['company_alias']);
        setCompanyCode(entities_data['company_code']);
        setCity(entities_data['city']);
        setTanNumber(entities_data['tan_number']);
        setGstNumber(entities_data['gst_number']);
      }
     
      setLoading(false);
    }
    fetchData();


  }, [])

  return (
    <div className="entity-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Entity ID : {entityId}</h6>
                <button className="cursor-pointer opacity-30  px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" onClick={() => setIsEditable(isEditable?false:true)}>
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Name</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name= "name" defaultValue={entityName} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Email</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true} 
                       name="type" defaultValue={entityEmail} />
              </div>
            </div>           
              <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Company Alias</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name="companyAlias" defaultValue={companyAlias} />
              </div>
            </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Company Code</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name="companyCode" defaultValue={companyCode} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">City</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name="city" defaultValue={city} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">TAN Number</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name="tanNumber" defaultValue={tanNumber} />
              </div>
            </div>
            </div>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">GST Number</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name="gstNumber" defaultValue={gstNumber} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Is Onboarded</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled={isEditable?false:true}
                       name="isOnboarded" defaultValue={isOnboarded?false:true} />
              </div>
            </div>                 
            </div>  
            {isEditable && (
            <div className="text-center mt-6 lg:w-3/12 px-4">
              <input
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                  type="Submit"
                  value="Submit"/>                            
            </div>
            )}  
            </div>   
        </form>
      )}
    </div>
  );
}

import React, { useState } from "react";
import Select from 'react-select';
import { getCompanyList } from "../../../serverRequest";
import { Link } from 'react-router-dom';
import AdminTicketList from "../../../components/Cards/AdminTicketList.js";

export default function AdminTickets() {
  const [companies, setCompanies] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = selectedOption => {
    setCompanyData(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  const fetchCompanyData = async () => {
    let companyList = await getCompanyList();
    if (companyList && companyList['data']) {
      companyList = companyList['data']
      let companyData = companyList[0]['value']
      await setCompanyData(companyData);
      setCompanies(companyList);
      setSelectedOption(companyList[0]);
    }
  }

  React.useEffect(() => {
    fetchCompanyData()
  }, [])

  return (
    <main>
      <div className="relative block py-24 lg:pt-0 px-4">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="flex justify-between">
            <div className="w-full lg:w-4/12 px-4 items-left">
              <h6 className="text-blueGray-700 text-xl font-bold">Tickets</h6>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <Select name="clist" options={companies} value={selectedOption} onChange={handleChange} placeholder="Select Entity" />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
            <div className="w-full lg:w-2/12 px-4 items-right">
              <Link to="/admin/TicketForm">
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button">
                Ticket
              </button>
              </Link>
            </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto py-24 lg:pt-0" key={companyData}>
          {/* Projects table */}
          {companyData?<AdminTicketList companyId={companyData}></AdminTicketList>:''}
        </div>
      </div>
    </main>
  );
}

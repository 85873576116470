import React, { useState } from "react";

// components
import { getData } from "../../serverRequest";
import CardStats from "../../components/Cards/CardStats.js";
import LoadingSpinner from "../../components/Loadder";
import ApproverTicketsTable from "../../components/Tables/ApproverTicketsTable";

export default function Dashboard() {
  // const [open, setOpen] = React.useState(true);
  const [openTicket, setOpenTicket] = React.useState(true);
  const [closedTicket, setclosedTicket] = React.useState(true);
  const [totalTicket, settotalTicket] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const [approvalTickets, setApprovalTickets] = useState([]);
  const [companyApprover, setCompanyApprover] = React.useState(false);
  const [newapprovalTickets, setNewApprovalTickets] = React.useState(true);
  const [approvedApprovalTickets, setApprovedApprovalTickets] = React.useState(true);
  const [totalApprovalTickets, setTotalApprovalTickets] = React.useState(true);
  const [companyOpenTicket, setCompanyOpenTicket] = React.useState(true);
  const [companyClosedTicket, setCompanyClosedTicket] = React.useState(true);
  const [companyTotalTicket, setCompanyTotalTicket] = React.useState(true);

  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };

  const filterTicketData = (tickets_data) => {
    let openTicketCount = 0;
    let closeTicketCount = 0;
    let totalTicketCount = 0;
    if (tickets_data) {
      totalTicketCount = tickets_data.length;
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] !== 'Resolved') {
          openTicketCount++;
        }
        else if (tickets_data[i]['status'] === 'Resolved') {
          closeTicketCount++;
        }
      }
    }

    return ({ "open": openTicketCount, "close": closeTicketCount, "total": totalTicketCount })
  }

  const filterApprovalData = (tickets_data) => {
    let newRequest = [];
    if (tickets_data) {
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] === 'Approval Pending') {
          newRequest.push(tickets_data[i])
        }
      }
    }
    let openTicketCount = 0;
    let closeTicketCount = 0;
    let totalTicketCount = 0;
    if (tickets_data) {
      totalTicketCount = tickets_data.length;
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] === 'Approval Pending') {
          openTicketCount++;
        }
        else {
          closeTicketCount++;
        }
      }
    }
    if (newRequest) {
      setApprovalTickets(newRequest)
    }

    return ({ "open": openTicketCount, "close": closeTicketCount, "total": totalTicketCount })

    //return (newRequest)
  }

  const filterCompanyData = (tickets_data) => {
    let openTicketCount = 0;
    let closeTicketCount = 0;
    let totalTicketCount = 0;
    if (tickets_data) {
      totalTicketCount = tickets_data.length;
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] !== 'Resolved') {
          openTicketCount++;
        }
        else if (tickets_data[i]['status'] === 'Resolved') {
          closeTicketCount++;
        }
      }
    }

    return ({ "open": openTicketCount, "close": closeTicketCount, "total": totalTicketCount })
  }

  React.useEffect(() => {

    async function fetchData() {
      let resData = await getData('/api/tickets/')
      let tickets_data = resData['data']['tickets_data']
      let approval_tickets = resData['data']['approval_tickets_data']
      let company_tickets = resData['data']['company_tickets_data']
      //console.log(approval_tickets);
      let TicketData = await filterTicketData(tickets_data)
      let is_approver = localStorage.getItem('companyApprover')
      if (is_approver === 'true') {
        setCompanyApprover(true)
        let approvalRequest = filterApprovalData(approval_tickets)
        if (approvalRequest) {

          setNewApprovalTickets({ title: "New Approval Tickets", count: approvalRequest['open'], color: '#cafdf5' });
          setApprovedApprovalTickets({ title: "Approved Tickets", count: approvalRequest['close'], color: '#90ffba' });
          setTotalApprovalTickets({ title: "Total Approval Tickets", count: approvalRequest['total'], color: '#d6d4ff' });
        }
        let companyData = filterCompanyData(company_tickets)
        if (companyData) {
          setCompanyOpenTicket({ title: "Company Open Tickets", count: companyData['open'], color: '#cafdf5' });
          setCompanyClosedTicket({ title: "Company Closed Tickets", count: companyData['close'], color: '#90ffba' });
          setCompanyTotalTicket({ title: "Company Total Tickets", count: companyData['total'], color: '#d6d4ff' });
        }
        //console.log(companyApprover);
      }


      setOpenTicket({ title: "Open Ticket", count: TicketData['open'], color: '#cafdf5' });
      setclosedTicket({ title: "Closed Ticket", count: TicketData['close'], color: '#90ffba' });
      settotalTicket({ title: "Total Ticket", count: TicketData['total'], color: '#d6d4ff' });
      setLoading(false);
    }
    fetchData();


  }, [])
  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div className="flex flex-wrap py-2">
          <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
            <a href="/user/serviceType"><button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              New Request
            </button></a>
          </div>

        </div>
        <div className="relative bg-lightBlue-800 md:pt-24 pb-24 pt-12">

          <div className="px-4 md:px-10 mx-auto w-full">
            <div></div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                <a href={"/user/userSolutions/?filter=open"}><CardStats
                  statSubtitle={openTicket.title}
                  statTitle={openTicket.count}
                  statIconName="fa fa-cogs"
                  statIconColor="bg-red-500"
                  statfilter='open'
                /></a>
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                <a href={"/user/userSolutions/?filter=closed"}><CardStats
                  statSubtitle={closedTicket.title}
                  statTitle={closedTicket.count}
                  statIconName="fas fa-cogs"
                  statIconColor="bg-orange-500"
                  statfilter='closed'
                /></a>
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                <a href={"/user/userSolutions/?filter=all"}><CardStats
                  statSubtitle={totalTicket.title}
                  statTitle={totalTicket.count}
                  statIconName="fas fa-cogs"
                  statIconColor="bg-pink-500"
                  statfilter='all'
                /></a>
              </div>
            </div>
            <div className="flex flex-wrap py-16">
              {companyApprover ?
                <>
                  <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <a href={"/user/userApprovals/?filter=approvalPending"}><CardStats
                      statSubtitle={newapprovalTickets.title}
                      statTitle={newapprovalTickets.count}
                      statIconName="fa fa-cogs"
                      statIconColor="bg-red-500"
                      statfilter='approvalPending'
                    /></a>
                  </div>
                  <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <a href={"/user/userApprovals/?filter=approved"}><CardStats
                      statSubtitle={approvedApprovalTickets.title}
                      statTitle={approvedApprovalTickets.count}
                      statIconName="fas fa-cogs"
                      statIconColor="bg-orange-500"
                      statfilter='approved'
                    /></a>
                  </div>
                  <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <a href={"/user/userApprovals"}><CardStats
                      statSubtitle={totalApprovalTickets.title}
                      statTitle={totalApprovalTickets.count}
                      statIconName="fas fa-cogs"
                      statIconColor="bg-pink-500"
                      statfilter='allapproval'
                    /></a>
                  </div>
                </>
                : ''}
            </div>
            <div className="flex flex-wrap pb-16">
              {companyApprover ?
                <>
                  <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <a href={"/user/companyTickets/?filter=open"}><CardStats
                      statSubtitle={companyOpenTicket.title}
                      statTitle={companyOpenTicket.count}
                      statIconName="fa fa-cogs"
                      statIconColor="bg-red-500"
                      statfilter='companyopen'
                    /></a>
                  </div>
                  <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <a href={"/user/companyTickets/?filter=closed"}><CardStats
                      statSubtitle={companyClosedTicket.title}
                      statTitle={companyClosedTicket.count}
                      statIconName="fas fa-cogs"
                      statIconColor="bg-orange-500"
                      statfilter='companyclosed'
                    /></a>
                  </div>
                  <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <a href={"/user/companyTickets"}><CardStats
                      statSubtitle={companyTotalTicket.title}
                      statTitle={companyTotalTicket.count}
                      statIconName="fas fa-cogs"
                      statIconColor="bg-orange-500"
                      statfilter='companyclosed'
                    /></a>
                  </div>
                </>
                : ''}
            </div>

          </div>
        </div>
        <div className="relative block py-24 lg:pt-0 ">
          {companyApprover ? <>
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                ""
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                    <h3
                      className={
                        "font-semibold text-lg " +
                        ""
                      }
                    >
                      Tickets Required My Approvals
                    </h3>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto mb-0 px-4 py-3">
                <ApproverTicketsTable approvalTickets={approvalTickets}></ApproverTicketsTable>
              </div>
            </div>
          </> : ''}

        </div>
      </>)}
    </>
  );
}

import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function SaleData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const saleId = searchParams.get('sale_id');
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState();
  const [client, setClient] = useState();
  const [receivingPo, setReceivingPo] = useState();
  const [invoice, setInvoice] = useState();
  const [sellingBasePrice, setSellingBasePrice] = useState();
  const [tax, setTax] = useState();
  const [dcNo, setDcNo] = useState();
  const [remarks, setRemarks] = useState();
  const [saleDate, setSaleDate] = useState();
  const [location, setLocation] = useState();
  //const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
     //Prevent page reload
     setLoading(true);
     event.preventDefault();
     let { productId, client, receivingPo, invoice, sellingBasePrice, tax, dcNo, remarks, saleDate, location } = document.forms[0];
     let reqData = {
       'sale_id': saleId,
       'product_id': productId ? productId.value : '',
       'client': client ? client.value : '',
       'receiving_po': receivingPo ? receivingPo.value : '',
       'invoice': invoice ? invoice.value : '',
       'selling_base_price': sellingBasePrice ? sellingBasePrice.value : '',
       'tax': tax ? tax.value : '',
       'dc_no': dcNo ? dcNo.value : '',
       'remarks': remarks ? remarks.value : '',
       'sale_date': saleDate ? saleDate.value : '',
       'location': location ? location.value : '',
     }

     let resData = await postData('/api/sales/', reqData); 
     if (resData['data']['code'] === 200) {
       setLoading(false);
       setShowModal(true);
       window.location.href = '/sales'; 
     }
   };

   React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/sales?sale_id='+saleId)
      let sales_data = resData['data']['sales']
      console.log(sales_data);
      if(sales_data){
        setProductId(sales_data['product_id']);
        setClient(sales_data['client']);
        setReceivingPo(sales_data['receiving_po']);
        setInvoice(sales_data['invoice']);
        setSellingBasePrice(sales_data['selling_base_price']);
        setTax(sales_data['tax']);
        setDcNo(sales_data['dc_no']);
        setRemarks(sales_data['remarks']);
        setSaleDate(sales_data['sale_date']);
        setLocation(sales_data['location']);
      }
     
      setLoading(false);
    }
    fetchData();


  }, [])

  return (
    <div className="sale-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Sale ID : {saleId}</h6>
                <button className="cursor-pointer opacity-30 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"> {/*onClick={() => setIsEditable(isEditable?false:true)}*/} 
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="productId">Product ID</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="productId" defaultValue={productId} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="client">Client</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="client" defaultValue={client} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="receivingPo">Receiving PO</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="receivingPo" defaultValue={receivingPo} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="invoice">Invoice</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="invoice" defaultValue={invoice} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="sellingBasePrice">Selling Base Price</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="sellingBasePrice" defaultValue={sellingBasePrice} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="tax">Tax</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="tax" defaultValue={tax} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="dcNo">DC No.</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="dcNo" defaultValue={dcNo} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="remarks">Remarks</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="remarks" defaultValue={remarks} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="saleDate">Sale Date</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="saleDate" defaultValue={saleDate} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="location">Location</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="location" defaultValue={location} />
              </div>
            </div>
            </div>
            {/*{isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}   */}       
          </div>
        </form>
      )}
    </div>
  );
}

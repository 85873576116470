import React, { useState } from "react";
import LoadingSpinner from "../../../components/Loadder"; 
import { postData } from "../../../serverRequest";
import Select from 'react-select';
import AdminProfilePhoto from "../../../assets/img/testimonial.png";

export default function UserForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);  
  const [userEmail] = useState();
  const [userCompany] = useState();
  const [userStatus, setUserStatus] = useState(); 
  const [userFirstName] = useState();
  const [userMiddleName] = useState();
  const [userLastName] = useState();
  const [userMobileNumber] = useState();
  const [userApprover] = useState();
  const [userGender] = useState('');
  const [userDesignation] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setLoading(true);
    
    const { first_name, middle_name, last_name, email, mobile_number, company, user_status, is_approver, gender, designation } = document.forms[0];
  
    const formData = {
      'first_name': first_name ? first_name.value : '',
      'middle_name': middle_name ? middle_name.value : '',
      'last_name': last_name ? last_name.value : '',
      'email': email ? email.value : '',
      'mobile_number': mobile_number ? mobile_number.value : '',
      'company': company ? company.value : '',
      'user_status': user_status ? user_status.value : '',
      'is_approver': is_approver ? is_approver.value : '', 
      'gender': gender ? gender.value : '',
      'designation': designation ? designation.value : '',
    };
  
    try {
      const res = await postData("/api/employee/", formData);
      
      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = '/admin/users';
      } else {
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };
   

  return (
    <div className="user-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">User Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full flex justify-center items-center mb-6">
              <img 
                src={AdminProfilePhoto} 
                alt="User Profile" 
                className="w-20 h-20 rounded-full object-cover" 
              />
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="first_name">First Name</label>
                <input 
                  type="text"
                  id="first_name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userFirstName}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="middle_name">Middle Name</label>
                <input 
                  type="text"
                  id="middle_name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userMiddleName}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="last_name">Last Name</label>
                <input 
                  type="text"
                  id="last_name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userLastName}
                />
              </div>
            </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">Email</label>
                <input 
                  type="email"
                  id="email"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userEmail}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="mobile_number">Mobile Number</label>
                <input 
                  type="text"
                  id="mobile_number"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userMobileNumber}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="is_approver">Gender</label>
                <input 
                  type="text"
                  id="gender"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userGender}/>
              </div>
            </div>
            </div>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="status">Status</label>
                <select 
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userStatus} name="user_status" onChange={setUserStatus}>
                <option value="">Select Status</option>
                <option value="hardware">Active</option>
                <option value="software">Inactive</option>
                </select>
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="is_approver">Is Approver</label>
                <input 
                  type="text"
                  id="is_approver"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userApprover}/>
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="company">Company</label>
                <input 
                  type="text"
                  id="company"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={userCompany}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="is_approver">Designation</label>
                <input 
                  type="text"
                  id="designation"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled
                  defaultValue={userDesignation}/>
              </div>
            </div>
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"
                />                            
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import { BlobProvider, Document, Page, Text } from '@react-pdf/renderer';
import { toast, ToastContainer } from 'react-toastify';
import AttendanceToolbar from './AttendanceToolbar.js';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../AttendanceCalendar.css';
import { getData, postData } from '../../serverRequest';

const localizer = momentLocalizer(moment);
Modal.setAppElement('#root');

const isFutureDate = (date) => {
  return moment(date).isAfter(moment(), 'day');
};

// Helper function for GET requests
// const getData = async (url) => {
//   const response = await fetch(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }
//   return await response.json();
// };

// // Helper function for POST requests
// const postData = async (url, data) => {
//   const response = await fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   });
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }
//   return await response.json();
// };

const AttendanceCalendar = () => {
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [attendance, setAttendance] = useState('');
  const [loginTime, setLoginTime] = useState('');
  const [logoutTime, setLogoutTime] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const data = await getData('/api/attendance');
        setEvents(data.map(event => ({
          ...event,
          title: event.title.replace(/(Login|Logout): (\d{1,2}:\d{2}) (AM|PM) (AM|PM)?/g, (match, p1, p2, p3) => `${p1}: ${moment(`${p2} ${p3}`, 'hh:mm A').format('hh:mm A')}`)
        })));
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        toast.error('Failed to load attendance data.');
      }
    };

    fetchAttendanceData();
  }, []);

  const saveAttendanceData = async (newEvents) => {
    try {
      await postData('/api/attendance', newEvents);
      toast.success('Attendance data saved successfully.');
    } catch (error) {
      console.error('Error saving attendance data:', error);
      toast.error('Failed to save attendance data.');
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    if (isFutureDate(start)) {
      toast.error('Cannot mark attendance for future dates.');
      return;
    }
    setCurrentSlot({ start, end });
    setModalIsOpen(true);
    setIsEditing(false);
    setAttendance('');
    setLoginTime('');
    setLogoutTime('');
    setRemarks('');
  };

  const handleSelectEvent = (event) => {
    if (isFutureDate(event.start)) {
      toast.error('Cannot edit attendance for future dates.');
      return;
    }
    const attendance = event.title.includes('Present') ? 'P' : event.title.includes('Absent') ? 'A' : 'H';
    const loginTime = event.title.match(/Login: (\d{1,2}:\d{2}) (AM|PM)/)?.[0] || '';
    const logoutTime = event.title.match(/Logout: (\d{1,2}:\d{2}) (AM|PM)/)?.[0] || '';
    setCurrentSlot({ start: event.start, end: event.end });
    setAttendance(attendance);
    setLoginTime(loginTime);
    setLogoutTime(logoutTime);
    setRemarks(event.remarks || '');
    setIsEditing(true);
    setEditingEvent(event);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setAttendance('');
    setLoginTime('');
    setLogoutTime('');
    setRemarks('');
    setCurrentSlot(null);
    setIsEditing(false);
    setEditingEvent(null);
  };

  const handleSaveAttendance = () => {
    // if (attendance === 'P' || attendance === 'H') {
    //   if (!loginTime || !logoutTime) {
    //    toast.error('Please enter login and logout times for Present or Half Day attendance.');
    //    return;
    //  }
    // }

    if (isEditing && !remarks) {
      toast.error('Remarks are required when editing.');
      return;
    }

    if (attendance === 'P' || attendance === 'A' || attendance === 'H') {
      const title = attendance === 'P' ? 'Present' : attendance === 'A' ? 'Absent' : 'Half Day';
      // const formattedLoginTime = attendance !== 'A' ? `Login: ${moment(loginTime, 'hh:mm A').format('hh:mm A')}` : '';
      // const formattedLogoutTime = attendance !== 'A' ? `Logout: ${moment(logoutTime, 'hh:mm A').format('hh:mm A')}` : '';
      const description = `${title}`; //\n${formattedLoginTime}\n${formattedLogoutTime}`;

      let updatedEvents;
      if (isEditing && editingEvent) {
        updatedEvents = events.map(event =>
          event === editingEvent ? { ...event, title: description, remarks } : event
        );
      } else {
        updatedEvents = [
          ...events,
          {
            start: currentSlot.start,
            end: currentSlot.end,
            title: description,
            allDay: true,
            className: 'custom-event',
            remarks,
          },
        ];
      }

      setEvents(updatedEvents);
      saveAttendanceData(updatedEvents); // Save updated events to the backend
      handleCloseModal();
    } else {
      toast.error('Invalid input. Please select Present, Half Day, or Absent.');
    }
  };

  const eventPropGetter = (event) => {
    const bgColor = event.title.includes('Present')
      ? 'bg-green-500'
      : event.title.includes('Absent')
        ? 'bg-red-500'
        : 'bg-yellow-500';

    return {
      className: `custom-event text-white ${bgColor} p-1 rounded relative`,
      style: { whiteSpace: 'pre-wrap' },
    };
  };

  const EventComponent = ({ event }) => (
    <div className="flex items-center justify-between">
      <div dangerouslySetInnerHTML={{ __html: event.title.replace(/\n/g, '<br>') }} />
    </div>
  );

  const generatePdf = () => (
    <Document>
      <Page>
        <Text>Attendance Sheet</Text>
        {events.map(event => (
          <Text key={event.start}>
            {moment(event.start).format('MMMM Do YYYY')}: {event.title}
          </Text>
        ))}
      </Page>
    </Document>
  );

  return (
    <div className="m-5 p-3 bg-gray-100 min-h-screen w-full">
      <div className="rounded-t bg-white px-6 py-6">
        <div className="text-center flex justify-between">
          <h6 className="text-blueGray-700 text-xl py-1 font-bold">Attendance Sheet</h6>
          <div className="w-full lg:w-3/12 px-4">
            <div className="relative w-">
              <DatePicker selected={startDate} onChange={date => setStartDate(date)} placeholderText="Start Date" className="w-full px-3 py-2 border rounded"/>
            </div>
          </div>
          <div className="w-full lg:w-3/12 px-4">
            <div className="relative w-">
              <DatePicker selected={endDate} onChange={date => setEndDate(date)} placeholderText="End Date" className="w-full px-3 py-2 border rounded"/>
            </div>
          </div>
          <BlobProvider document={generatePdf()}>
            {({ url }) => (
              <a 
                href={url} 
                download="attendance_sheet.pdf" 
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                Download
              </a>
            )}
          </BlobProvider>
        </div>
      </div>
      <div className='mt-2 p-2 bg-white rounded shadow'>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 550 }}
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventPropGetter}
          components={{
            event: EventComponent,
            toolbar: AttendanceToolbar, 
          }}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Attendance Input"
        >
          <div className="modal-content px-3 py-2">
            <h2 className="text-blueGray-700 text-xl font-bold text-center">{isEditing ? 'Edit Attendance' : 'Mark Attendance'}</h2>
              <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2" htmlFor="enter-attendance">
                Attendance :
              <select 
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={attendance}
                onChange={e => setAttendance(e.target.value)}>
                <option value="">Select Attendance</option>
                <option value="P">Present</option>
                <option value="A">Absent</option>
                <option value="H">Half Day</option>
              </select>
            </label>
            {/* {attendance !== 'A' && (
              <>
                <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2" htmlFor="login-time">
                  Login time :
                  <input
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    type="time"
                    value={loginTime}
                    onChange={(e) => setLoginTime(e.target.value)}
                  />
                </label>
                <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2" htmlFor="logout-time">
                  Logout time :
                  <input
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    type="time"
                    value={logoutTime}
                    onChange={(e) => setLogoutTime(e.target.value)}
                  />
                </label>
              </>
            )} */}        
              <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                Remarks:
              <textarea
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={remarks}
                onChange={e => setRemarks(e.target.value)}
              />
              </label>
              <button onClick={handleSaveAttendance}
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow 
                                 hover:shadow-lg outline-none focus:outline-none mr-1 mt-2 mb-1 w-full ease-linear transition-all duration-150">Save</button>
              <button onClick={handleCloseModal}
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow 
                                 hover:shadow-lg outline-none focus:outline-none mr-1 mt-2 mb-1 w-full ease-linear transition-all duration-150">Cancel</button>
          </div>
        </Modal>        
      </div>
      <ToastContainer />
    </div>
  );
};

export default AttendanceCalendar;

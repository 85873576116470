import React from 'react';
import AdminMonthlyTicket from './AdminMonthlyTickets';

const AdminTasks = () => {
  return (
    <div>
      <AdminMonthlyTicket />
    </div>
  );
}

export default AdminTasks;

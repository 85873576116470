import React, { useState } from "react";
import LoadingSpinner from "../../../components/Loadder"; 
import { getData, postData } from "../../../serverRequest";
import AdminProfilePhoto from "../../../assets/img/testimonial.png";

export default function UserData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const userId = searchParams.get('user_id');
  const [showModal, setShowModal] = React.useState(false);
  const [userEid, setUserEid] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();  
  const [userCompany, setUserCompany] = useState();
  const [userStatus, setUserStatus] = useState(null); 
  const [userFirstName, setUserFirstName] = useState('');
  const [userMiddleName, setUserMiddleName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [userApprover, setUserApprover] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userDesignation, setUserDesignation] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
     //Prevent page reload
     setLoading(true);
     event.preventDefault();
     let { first_name, middle_name, last_name, email, mobile_number, company, status, is_approver, gender, designation } = document.forms[0];
     let reqData = {
       'user_id': userId,
       'id': userId,
       'first_name': first_name ? first_name.value : '',
       'middle_name': middle_name ? middle_name.value : '',
       'last_name': last_name ? last_name.value : '',
       'email': email ? email.value : '',
       'mobile_number': mobile_number ? mobile_number.value : '',
       'company': company ? company.value : '',
       'status': status ? status.value : '',
       'is_approver': is_approver ? is_approver.value : '',
       'gender': gender ? gender.value : '',
       'designation': designation ? designation.value : '',
     }

     let resData = await postData('/api/employee/', reqData); 
     if (resData['data']['code'] === 200) {
       setLoading(false);
       setShowModal(true);
       window.location.href = '/admin/users'; 
     }
   };

   React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/employee?employee_id='+userId)
      let users_data = resData['data']['employee_data']
      console.log(users_data);
      if(users_data){
        let user_name = users_data['first_name'] +' '+ users_data['last_name']
        setUserEid(users_data['employee_id']);
        setUserName(user_name);
        setUserEmail(users_data['email']);
        setUserCompany(users_data['city']);
        setUserFirstName(users_data['first_name']);
        setUserMiddleName(users_data['middle_name']);
        setUserLastName(users_data['last_name']);
        setUserMobileNumber(users_data['mobile_number']);
        setUserApprover(users_data['is_approver']);
        setUserStatus(users_data['status']);
        setUserGender(users_data['gender']);
        setUserDesignation(users_data['designation'])
      }
     
      setLoading(false);
    }
    fetchData();


  }, [])
 

  return (
    <div className="user-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">User ID : {userEid}</h6>
                <button className="cursor-pointer opacity-30  px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" onClick={() => setIsEditable(isEditable?false:true)}>
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full flex justify-center items-center mb-6">
              <img 
                src={AdminProfilePhoto} 
                alt="User Profile" 
                className="w-20 h-20 rounded-full object-cover" 
              />
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="first_name">First Name</label>
                <input 
                  type="text"
                  id="first_name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userFirstName}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="middle_name">Middle Name</label>
                <input 
                  type="text"
                  id="middle_name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userMiddleName}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="last_name">Last Name</label>
                <input 
                  type="text"
                  id="last_name"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userLastName}
                />
              </div>
            </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">Email</label>
                <input 
                  type="email"
                  id="email"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userEmail}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="mobile_number">Mobile Number</label>
                <input 
                  type="text"
                  id="mobile_number"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userMobileNumber}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="is_approver">Gender</label>
                <input 
                  type="text"
                  id="gender"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userGender}/>
              </div>
            </div>
            </div>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="status">Status</label>
                <input 
                  type="text"
                  id="status"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled
                  defaultValue={userStatus ? 'Active' : 'Inactive'}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="is_approver">Is Approver</label>
                <input 
                  type="text"
                  id="is_approver"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled
                  defaultValue={userApprover ? 'Yes' : 'No'}/>
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="company">Company</label>
                <input 
                  type="text"
                  id="company"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled
                  defaultValue={userCompany}
                />
              </div>
            </div>             
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="is_approver">Designation</label>
                <input 
                  type="text"
                  id="designation"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  disabled={isEditable?false:true}
                  defaultValue={userDesignation}/>
              </div>
            </div>
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}
          </div>
        </form>
      )}
    </div>
  );
}

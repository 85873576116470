import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder";
import { postData } from "../../../../serverRequest";

export default function ProductWarrantyForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [serialNumber] = useState();
  const [warrantyStartDate] = useState();
  const [warrantyEndDate] = useState();
  const [assetsName] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const { serial_number, warranty_start_date, warranty_end_date, asset_name } = document.forms[0];

    const formData = {
      'serial_number': serial_number ? serial_number.value : '',
      'warranty_start_date': warranty_start_date ? warranty_start_date.value : '',
      'warranty_end_date': warranty_end_date ? warranty_end_date.value : '',
      'asset_name': asset_name ? asset_name.value: '',
    };

    try {
      const res = await postData("/api/inventory/", formData);

      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = "/admin/inventory";
      } else {
        console.error("Failed to submit form:", res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  return (
    <div className="product-warranty-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product Warranty Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="serialNumber">
                    Serial Number
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="serialNumber" defaultValue={serialNumber} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="warrantyStartDate">
                    Warranty Start Date
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="warrantyStartDate" defaultValue={warrantyStartDate} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="warrantyEndDate">
                    Warranty End Date
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="warrantyEndDate" defaultValue={warrantyEndDate} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="assetName">
                    Assets Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="assetName" defaultValue={assetsName} />
                </div>
              </div>
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
              <input
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                type="Submit"
                value="Submit"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState } from "react";
import LoadingSpinner from "../../../components/Loadder"; 
import { getData, postData } from "../../../serverRequest";

export default function AdminData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const adminId = searchParams.get('admin_id');
  const [showModal, setShowModal] = useState(false);
  const [adminEid, setAdminEid] = useState();
  const [adminName, setAdminName] = useState();
  const [adminEmail, setAdminEmail] = useState();
  const [adminCompany, setAdminCompany] = useState();
  const [adminLocation, setAdminLocation] = useState(); 
  //const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
     //Prevent page reload
     setLoading(true);
     event.preventDefault();
     let { name, email, company, location } = document.forms[0];
     let reqData = {
       'admin_id': adminId,
       'id': adminId,
       'name': name ? name.value : '',
       'email': email ? email.value : '',
       'company': company ? company.value : '',
       'location': location ? location.value : '',
     }

     let resData = await postData('/api/employee/', reqData); 
     if (resData['data']['code'] === 200) {
       setLoading(false);
       setShowModal(true);
       window.location.href = '/admin/admins'; 
     }
   };

   React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/employee/admins?admin_id='+adminId)
      let admins_data = resData['data']['admins']
      console.log(admins_data);
      if(admins_data){
        let admin_name = admins_data['employee_first_name'] + ' ' + admins_data['employee_last_name']
        setAdminEid(admins_data['employee_id']);
        setAdminName(admin_name);
        setAdminEmail(admins_data['employee_email']);
        setAdminCompany(admins_data['company_name']);
        setAdminLocation(admins_data['location']);
      }
     
      setLoading(false);
    }
    fetchData();


  }, [])

  return (
    <div className="admin-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Admin ID : {adminEid}</h6>
                <button className="cursor-pointer opacity-30 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"> {/*onClick={() => setIsEditable(isEditable?false:true)}*/} 
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Name</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name= "name" defaultValue={adminName} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Email</label>
                <input type="email"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="email" defaultValue={adminEmail} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Company</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="company" defaultValue={adminCompany} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Location</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="location" defaultValue={adminLocation} />
              </div>
            </div>  
            </div>
            {/*{isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}   */}       
          </div>
        </form>
      )}
    </div>
  );
}

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import UserNavbar from "../components/Navbars/UserNavbar";
import Footer from "../components/Footers/Footer";

// views


import ServiceList from "./users/ServiceList.js";
import NewRequest from "./users/NewRequests.js";
import Solutions from "./users/Solutions.js";
import Profile from "./users/Profile.js";
import Dashboard from "./users/Dashboard.js";
import ServiceType from "./users/ServiceType.js";
import HeaderStats from "../components/Headers/HeaderStats";
import UserTicket from "../components/Cards/UserTicket";
import ApproverTickets from "./users/ApproverTickets";
import ApproverAssignedTicket from "../components/Cards/ApproverAssignedTicket";
import CompanyTickets from "../components/Cards/CompanyTickets";

export default function User() {
  return (
    <>
      <div className="relative bg-blueGray-100">
        <UserNavbar />
        
        <div className="px-4 py-50 md:px-12 mx-auto w-full -m-24">
          <Switch>
            <Route path="/user/dashboard" exact component={Dashboard} />
            <Route path="/user/serviceType" exact component={ServiceType} />
            <Route path="/user/serviceList" exact component={ServiceList} />
            <Route path="/user/newRequest" exact component={NewRequest} />
            <Route path="/user/userSolutions" exact component={Solutions} />
            <Route path="/user/profile" exact component={Profile} />
            <Route path="/user/ticket" exact component={UserTicket} />
            <Route path="/user/userApprovals" exact component={ApproverTickets} />
            <Route path="/user/approverTicket" exact component={ApproverAssignedTicket} />
            <Route path="/user/companyTickets" exact component={CompanyTickets} />
            <Redirect from="/user" to="/user/dashboard" />
          </Switch>
          <Footer />
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import Select from 'react-select';
import { mode } from '../data/data';
import axios from 'axios';
import LoadingSpinner from "../Loadder";
import { getData, postData } from "../../serverRequest";
import moment from 'moment';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function AdminAssigneddTicket(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(true);
    var ticket_id = searchParams.get('ticket_id');
    const [showModal, setShowModal] = React.useState(false);
    const [requestType, setRequestType] = useState();
    const [ticketType, setTicketType] = React.useState();
    const [ticketMode, setTicketMode] = React.useState();
    const [ticketPriority, setTicketPriority] = React.useState();
    const [ticketAssignedTo, setTicketAssignedTo] = React.useState();
    const [ticketTitle, setTicketTitle] = React.useState();
    const [ticketId, setTicketId] = React.useState();
    const [UserTicketId, setUserTicketId] = React.useState();
    const [ticketCreatedOn, setTicketCreatedOn] = React.useState();
    const [ticketDescription, setTicketDescription] = React.useState();
    const [ticketStatus, setTicketStatus] = React.useState();
    const [ticketStatusList, setTicketStatusList] = React.useState();
    const [ticketNotify, setTicketNotify] = React.useState();
    const [ticketConnectionId, setTicketConnectionId] = React.useState();
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [dataUrls, setDataUrls] = React.useState([]);
    const [requesterName, setRequesterName] = React.useState();
    const [requesterEmail, setRequesterEmail] = React.useState();
    const [requesterEntity, setRequesterEntity] = React.useState();
    const [ticketRemarks, setTicketRemarks] = React.useState();
    const [ticketModes, setTicketModes] = React.useState();
    const [ticketPriorities, setTicketPriorities] = React.useState();
    const [companyAdmins, setCompanyAdmins] = React.useState();
    const [ticketComments, setTicketComments] = React.useState();

    const [filePreviews, setFilePreviews] = useState([]);
    const [message, setMessage] = useState('');
    const [glData, setGlData] = React.useState();
    const [hsnData, setHsnData] = React.useState();
    const [hsnAltData, setHsnAltData] = React.useState();
    const [vendorData, setVendorData] = React.useState();
    const [approvalDate, setApprovalDate] = React.useState();
    const [approvalRequired, setApprovalRequired] = React.useState();


    const handleFileUpload = () => {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);
        }

        axios.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                setMessage(response.data.message);
            })
            .catch((error) => {
                setMessage('Upload failed');
                console.error(error);
            });
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles(files);

        // Generate file previews
        const previews = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            previews.push(URL.createObjectURL(file));
        }
        setFilePreviews(previews);
    };
    const handleDrop = (acceptedFiles) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    };

    const handleRemove = (file) => {
        setSelectedFiles(selectedFiles.filter((f) => f !== file));
    };

    const formatDate = (dateString) => {
        var localTime = moment.utc(dateString).toDate();
        localTime = moment(localTime).subtract({ 'hours': 0, 'minutes': 0 }).format('YYYY-MM-DD HH:mm:ss');
        //return moment.utc(dateString).local().format('DD/MM/YYYY h:MM A');
        return localTime
    };

    const formatTicketType = (ticket_type_data) => {
        let ticket_type_datas = [];
        if (ticket_type_data) {
            for (let i = 0; i < ticket_type_data.length; i++) {
                let href = '/user/newRequest?tt=' + ticket_type_data[i]['id']
                ticket_type_datas.push({ value: ticket_type_data[i]['id'], label: ticket_type_data[i]['type'], href: href, current: true, description: '', imagelink: '', icon: 'fa fa-laptop' })
            }
        }

        return ticket_type_datas
    }


    React.useEffect(() => {
        async function fetchData() {
            console.log(props.ticket_id);
            if(ticket_id===null){
                let ticket_props_id = props.ticket_id;
                if(ticket_props_id){
                    ticket_id=ticket_props_id
                }
            }
            let url = '/api/tickets/ticket/?ticket_id=' + ticket_id;
            let resData = await getData(url)
            //console.log(resData);
            let ticket_data = resData['data']['ticket_data']
            let employee_data = resData['data']['employee']
            let admin_data = resData['data']['admins']
            let modes = resData['data']['mode']
            let priorities = resData['data']['priority']
            let ticket_types = resData['data']['ticket_types']
            let comments = resData['data']['comments']
            let status_list = resData['data']['status_list']
            if(ticket_data){
                if (ticket_data.gl_data) {
                    //console.log(ticket_data.gl_data);
                    setGlData(ticket_data.gl_data)
                }
                if (ticket_data.hsn_data) {
                    setHsnData(ticket_data.hsn_data)
                }
                if (ticket_data.hsn_alt_data) {
                    setHsnAltData(ticket_data.hsn_alt_data)
                }
                if (ticket_data.vendor_data) {
                    setVendorData(ticket_data.vendor_data)
                }
            }

            if (comments && comments.length > 0) {
                setTicketComments(comments);
            }
            if (modes) {
                setTicketModes(modes)
                for (let i = 0; i <= modes.length; i++) {

                    if (ticket_data && modes[i] && modes[i].value === ticket_data.mode) {
                        setTicketMode({ value: modes[i].value, label: mode[i].label })
                    }
                }
            }
            if (priorities) {
                setTicketPriorities(priorities)
                for (let i = 0; i <= priorities.length; i++) {

                    if (ticket_data && priorities[i] && priorities[i].value === ticket_data.priority) {
                        setTicketPriority({ value: priorities[i].value, label: priorities[i].label })
                    }
                }
            }
            if (status_list) {
                //console.log(status_list);
                let statusListData = []
                for (let i = 0; i < status_list.length; i++) {
                    statusListData.push({ value: status_list[i].id, label: status_list[i].status })
                    if (status_list[i] && status_list[i].status === ticket_data.status) {
                        setTicketStatus({ value: status_list[i].id, label: status_list[i].status })
                    }
                }

                setTicketStatusList(statusListData)
            }
            if (admin_data) {
                let adminListData = []
                for (let i = 0; i < admin_data.length; i++) {
                    adminListData.push({ value: admin_data[i].id, label: admin_data[i].employee_first_name })
                    if (admin_data[i].employee_first_name === ticket_data.assigned_to) {
                        setTicketAssignedTo({ value: admin_data[i].id, label: admin_data[i].employee_first_name })
                    }
                }
                setCompanyAdmins(adminListData)
            }
            if (ticket_data) {

                setTicketType(ticket_data.ticket_type)
                //setTicketAssignedTo(ticket_data.assigned_to)
                setTicketTitle(ticket_data.title)
                setUserTicketId(ticket_data.user_ticket_id)
                setTicketId(ticket_data.id)
                //setTicketMode(ticket_data.mode)
                //setTicketPriority(ticket_data.priority)
                setTicketNotify(ticket_data.approver_id)
                setTicketDescription(ticket_data.description)
                //setTicketStatus(ticket_data.status)
                setTicketConnectionId(ticket_data.connection_id)
                if (ticket_data.is_approval_required) {
                    setApprovalRequired('True')
                }
                if (ticket_data.approved_on) {
                    setApprovalDate(formatDate(ticket_data.approved_on))
                }
                let urls_data = ticket_data.attachments;
                //console.log(typeof (urls_data))
                let dataUrlSet = []
                if (urls_data) {
                    let data_url = urls_data.split(',')
                    //console.log(data_url);
                    for (let i = 0; i <= data_url.length; i++) {
                        if (data_url[i]) {
                            //console.log("I am heare");
                            let dataPath = data_url[i].trim();

                            let dataPathN = dataPath.replace(/'/g, '').replace(/\[/g, '').replace(/\]/g, '')
                            //console.log(dataPathN);
                            dataUrlSet.push(dataPathN);
                        }

                    }
                }
                //console.log(dataUrlSet);
                setDataUrls(dataUrlSet);
                setLoading(false);
            }
            if (employee_data) {
                setRequesterName(employee_data.name);
                setRequesterEmail(employee_data.email)
                setRequesterEntity(employee_data.company)
            }
        }
        fetchData();
    }, [])

    const handleSubmit = async (event) => {
        //Prevent page reload
        setLoading(true);
        event.preventDefault();
        //console.log(document.forms[1]);
        let { ticket_type, mode, priority, location, approver_id, assigned_to, subject, description, anydesk_id, status, remarks } = document.forms[1];
        //console.log(assigned_to);
        let reqData = {
            'ticket_id': UserTicketId,
            'id': ticketId,
            'description': description.value,
            'assigned_to_id': assigned_to ? assigned_to.value : '',
            'mode': mode ? mode.value : '',
            'priority': priority ? priority.value : '',
            'approver_id': approver_id ? approver_id : '',
            'location': location ? location.value : '',
            'title': subject.value,
            'attachment': '',
            'connection_id': anydesk_id ? anydesk_id.value : '',
            'ticket_status_id': status ? parseInt(status.value) : '',
        }

        //console.log(reqData);
        let resData = await postData('/api/tickets/ticket/', reqData)
        //console.log("This is data")
        //console.log(resData)
        if (resData['data']['code'] === 200) {
            if (remarks.value) {
                let reqCommentData = {
                    'ticket_id': ticketId,
                    'message': remarks.value
                }
                //console.log("This is data")
                let resCommentData = await postData('/api/tickets/comment/', reqCommentData)
                //console.log("This is data")
                ////console.log(resCommentData)
                if (resCommentData['data']['code'] === 200) {
                    //console.log("Thicket comment saved");
                }
                else {
                    //setErrorMessages({ name: "uname", message: errors.uname });
                }
            }
            setLoading(false);
            setShowModal(true);
            //console.log("This is good example");
            window.location.href = '/admin/tickets';
        }
        else {
            //setErrorMessages({ name: "uname", message: errors.uname });
        }
    };
    const handleModelClose = async (event) => {
        setShowModal(false);
        window.location.href = '/user/dashboard';
    }

    return (
        <>
            {loading ? (
                <LoadingSpinner></LoadingSpinner>
            ) : (<>
                <form onSubmit={handleSubmit}>
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Ticket ID: {UserTicketId}</h6>

                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Requester: {requesterName}
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Email Id
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={requesterEmail}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Entity
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={requesterEntity}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Anydesk Id
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketConnectionId}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>


                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Basic Details
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Ticket Type
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketType}
                                            disabled
                                            name="ticket_type"

                                        />
                                    </div>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Mode
                                        </label>
                                        <Select options={ticketModes} defaultValue={ticketMode} name="mode" onChange={setTicketMode} />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Priority
                                        </label>
                                        <Select options={ticketPriorities} defaultValue={ticketPriority} name="priority" onChange={setTicketPriority} />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Approver Email
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketNotify}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Approval
                                        </label>

                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={approvalRequired ? (approvalDate ? 'Approved On: ' + approvalDate : 'Required') : 'Not Required'}
                                            disabled
                                            name=""
                                        />

                                    </div>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Status
                                        </label>
                                        {ticketStatus.label === 'Approval Pending' ? (<>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                defaultValue={ticketStatus.label}
                                                disabled
                                                name=""
                                            /></>) : (<>
                                                <Select name="status"
                                                    defaultValue={ticketStatus}
                                                    options={ticketStatusList}
                                                    onChange={setTicketStatus}
                                                />
                                            </>)}

                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Assigned To
                                        </label>
                                        <Select name="assigned_to"
                                            options={companyAdmins}
                                            defaultValue={ticketAssignedTo}
                                            onChange={setTicketAssignedTo}
                                        />
                                    </div>
                                </div>

                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Requirement Specific
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text" name="subject"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketTitle}
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>
                                {ticketType === 'GL Code Creation/Alteration' ?
                                    (<><h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        GL Code Form
                                    </h6>
                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Business
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.business : ''}
                                                        disabled
                                                        name="business"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        GL code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.glcode : ''}
                                                        disabled
                                                        name="glcode"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        GL Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.glname : ''}
                                                        disabled
                                                        name="glname"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Group
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.group : ''}
                                                        disabled
                                                        name="group"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Bill Wise Details Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.bwda : ''}
                                                        disabled
                                                        name="bwda"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Cost Centre Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.cca : ''}
                                                        disabled
                                                        name="cca"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Inventory Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.inv : ''}
                                                        disabled
                                                        name="inv"
                                                    />
                                                </div>
                                            </div>
                                        </div></>) : ''}
                                {ticketType === 'HSN Creation' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        HSN Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Entity Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.business : ''}
                                                    disabled
                                                    name="hsnbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    SAC/HSN Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.sac_hsn_code : ''}
                                                    disabled
                                                    name="sac_hsn_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.description : ''}
                                                    disabled
                                                    name="description"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Under
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.under : ''}
                                                    disabled
                                                    name="under"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Category
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.category : ''}
                                                    disabled
                                                    name="category"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Units
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.units : ''}
                                                    disabled
                                                    name="units"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.gst : ''}
                                                    disabled
                                                    name="gst"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Set Alter GST details
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.set_alter_gst_details : ''}
                                                    disabled
                                                    name="set_alter_gst_details"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Calculation Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.calculation_type : ''}
                                                    disabled
                                                    name="calculation_type"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Taxability
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.tax_ability : ''}
                                                    disabled
                                                    name="tax_ability"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Tax Type- Integrated
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.tax_type_integrated : ''}
                                                    disabled
                                                    name="tax_type_integrated"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Supply
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.type_of_supply : ''}
                                                    disabled
                                                    name="type_of_supply"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.type_of_ledger : ''}
                                                    disabled
                                                    name="type_of_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Purchase Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.purchase_ledger : ''}
                                                    disabled
                                                    name="purchase_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Credit Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.credit_type : ''}
                                                    disabled
                                                    name="credit_type"
                                                />
                                            </div>
                                        </div>

                                    </div></>) : ''}
                                    {ticketType === 'HSN Alteration' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        HSN Alteration Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Entity Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.business : ''}
                                                    disabled
                                                    name="hsnbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    SAC/HSN Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.sac_hsn_code : ''}
                                                    disabled
                                                    name="sac_hsn_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.type_of_ledger : ''}
                                                    disabled
                                                    name="type_of_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Purchase Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.purchase_ledger : ''}
                                                    disabled
                                                    name="purchase_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Credit Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.credit_type : ''}
                                                    disabled
                                                    name="credit_type"
                                                />
                                            </div>
                                        </div>

                                    </div></>) : ''}
                                {ticketType === 'Vendor Creation' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        Vendor Creation Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.business : ''}
                                                    disabled
                                                    name="vendorbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GL code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gl_code : ''}
                                                    disabled
                                                    name="gl_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GL Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gl_name : ''}
                                                    disabled
                                                    name="gl_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Main Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.main_group : ''}
                                                    disabled
                                                    name="main_group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.group : ''}
                                                    disabled
                                                    name="group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Sub Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.sub_group : ''}
                                                    disabled
                                                    name="sub_group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Contact Person Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.contact_person : ''}
                                                    disabled
                                                    name="contact_person"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Contact No
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.contact_number : ''}
                                                    disabled
                                                    name="contact_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Email ID
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.email_id : ''}
                                                    disabled
                                                    name="email_id"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Address of the Vendor
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vendor_address : ''}
                                                    disabled
                                                    name="vendor_address"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.city : ''}
                                                    disabled
                                                    name="city"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    PIN
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.pin : ''}
                                                    disabled
                                                    name="pin"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    State
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.state : ''}
                                                    disabled
                                                    name="state"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Registered/ Unregistered under GST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.is_gst_registered : ''}
                                                    disabled
                                                    name="is_gst_registered"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST No.
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gst_number : ''}
                                                    disabled
                                                    name="gst_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST State
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gst_state : ''}
                                                    disabled
                                                    name="gst_state"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    PAN No.
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.pan_number : ''}
                                                    disabled
                                                    name="pan_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_name : ''}
                                                    disabled
                                                    name="bank_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Address
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_address : ''}
                                                    disabled
                                                    name="bank_address"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Account No
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_account_number : ''}
                                                    disabled
                                                    name="bank_account_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank IFSC
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_ifsc : ''}
                                                    disabled
                                                    name="bank_ifsc"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bill Wise Details Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vbwda : ''}
                                                    disabled
                                                    name="vbwda"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Cost Centre Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vcca : ''}
                                                    disabled
                                                    name="vcca"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Inventory Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vinv : ''}
                                                    disabled
                                                    name="vinv"
                                                />
                                            </div>
                                        </div>
                                    </div></>) : ''}
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Description
                                        </label>
                                        <SunEditor name="description" height="250px" defaultValue={ticketDescription} disable />
                                        
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Attachments
                            </h6>
                            <div className="flex flex-wrap">


                                {dataUrls.map((file, index) => (
                                    <div key={index} className="preview w-full lg:w-3/12 px-4">
                                        <img src={file} />

                                    </div>
                                ))}

                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Remarks
                            </h6>
                            <div className="flex flex-wrap">

                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Remarks
                                        </label>
                                        {ticketComments ? ticketComments.map((item, index) => (
                                            <><label><b>{item.created_by}: </b> {formatDate(item.created_on)}</label>
                                                <p
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >{item.comment}</p><br></br></>
                                        )) : ''}
                                        <textarea
                                            type="text" name="remarks"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            rows="4"


                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="text-center mt-6 lg:w-3/12 px-4">
                            <input
                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="Submit"
                                value="Submit"
                            >

                            </input>
                        </div>
                    </div>

                </form>
            </>)
            }
        </>
    );
}

import React, { useState, useEffect } from "react";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import AdminProfilePhoto from "../../assets/img/testimonial.png";
import WelcomeBg from "../../assets/img/register_bg_2.png";



export default function AdminProfile() {    
  // State variables to hold editable fields
  const [fullName, setFullName] = useState(localStorage.getItem('userName'));
  const [gender, setGender] = useState("Male");
  const [preferredLanguage, setPreferredLanguage] = useState("English");
  const [country, setCountry] = useState("India");
  const [timezone, setTimezone] = useState("GMT+05:30");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [username, setUsername] = useState(localStorage.getItem('userName'));

  // Username
  //const username = "Anurag"; 

  // Update current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <AdminNavbar transparent />
      <main className="min-h-screen w-full">

        {/* Welcome Message Section */}
        <section className="py-0 px-6 text-center text-sm bg-lightBlue-200 pt-2 pb-2" style={{ backgroundImage: `url(${WelcomeBg})`, backgroundSize: '30%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <h3 className="text-2xl font-semibold mb-1">Welcome, {username}</h3>
          <p className="text-sl text-gray-600">
            {currentTime.toLocaleString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            {currentTime.toLocaleTimeString()}
          </p>
        </section>

        {/* Admin Profile Section */}
        <section className="py-6 px-6 pt-10">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                src={AdminProfilePhoto}
                alt="Admin Profile"
                className="rounded-full w-20 h-20 mr-4"
              />
            </div>
            <div>
              <h2 className="text-2xl font-semibold pb-2">{fullName}</h2>
              <p className="text-s text-lightBlue-600">{email}</p>
            </div>
          </div>
        </section>

        {/* Editable Input Fields */}
        <section className="py-6 px-6">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 pr-2 mb-4 flex flex-col" style={{ width: "50%" , padding: "0rem 1rem" }}>
              <label htmlFor="fullName" className="text-gray-500 font-semibold mb-2 text-sm">Full Name</label>
              <input type="text" id="fullName" className="rounded-lg w-full px-4 py-2 border focus:border-blue-500 focus:outline-none" value={fullName} onChange={(e) => setFullName(e.target.value)} readOnly />
            </div>
            <div className="w-full md:w-1/2 pl-2 mb-4 flex flex-col" style={{ width: "50%" , padding: "0rem 1rem" }}>
              <label htmlFor="gender" className="text-gray-500 font-semibold mb-2 text-sm">Gender</label>
              <input type="text" id="gender" className="rounded-lg w-full px-4 py-2 border focus:border-blue-500 focus:outline-none" value={gender} onChange={(e) => setGender(e.target.value)} readOnly />
            </div>
            <div className="w-full md:w-1/2 pr-2 mb-4 flex flex-col" style={{ width: "50%" , padding: "0rem 1rem" }}>
              <label htmlFor="preferredLanguage" className="text-gray-500 font-semibold mb-2 text-sm">Language</label>
              <input type="text" id="preferredLanguage" className="rounded-lg w-full px-4 py-2 border focus:border-blue-500 focus:outline-none" value={preferredLanguage} onChange={(e) => setPreferredLanguage(e.target.value)} readOnly />
            </div>
            <div className="w-full md:w-1/2 pl-2 mb-4 flex flex-col" style={{ width: "50%" , padding: "0rem 1rem" }}>
              <label htmlFor="country" className="text-gray-500 font-semibold mb-2 text-sm">Country</label>
              <input type="text" id="country" className="rounded-lg w-full px-4 py-2 border focus:border-blue-500 focus:outline-none" value={country} onChange={(e) => setCountry(e.target.value)} readOnly/>
            </div>
            <div className="w-full md:w-1/2 pr-2 mb-4 flex flex-col" style={{ width: "50%" , padding: "0rem 1rem" }}>
              <label htmlFor="timezone" className="text-gray-500 font-semibold mb-2 text-sm">Timezone</label>
              <input type="text" id="timezone" className="rounded-lg w-full px-4 py-2 border focus:border-blue-500 focus:outline-none" value={timezone} onChange={(e) => setTimezone(e.target.value)} readOnly />
            </div>  
          </div>
        </section>

        {/* Add Email Button */}
        <section className="py-6 px-6 pt-0">
          <div className="flex flex-col">
          <div className="flex">
           <h3 className="text-xl font-semibold pb-2">My email Address</h3>
          </div>
          <div className="flex items-center">
            <i className="fas fa-envelope text-xl text-orange-500"></i>
            <p className="text-sm text-lightblue-600 pl-3">{email}</p>
          </div>
           
          </div>
        </section>
      </main>
    </>
  );
}

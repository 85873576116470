import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const CompanyReportChart = ({ totalTickets }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (totalTickets.length > 0) {
      if (chartRef.current !== null) {
        chartRef.current.destroy();
      }

      const ctx = document.getElementById('barChart').getContext('2d');
      const filteredTickets = totalTickets.filter(ticket => ticket.total > 0);
      const labels = filteredTickets.map(ticket => ticket.company_name);
      const data = filteredTickets.map(ticket => ticket.total);

      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: 'Total Tickets',
            data: data,
            backgroundColor: filteredTickets.map(() => `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`),
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Total Tickets by Company'
            }
          },
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
              }
            },
            y: {
              beginAtZero: true
            }
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10
            }
          }
        }
      });
    }
  }, [totalTickets]);

  return <canvas id="barChart" width="400" height="400"></canvas>;
};

export default CompanyReportChart;

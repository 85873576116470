import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const PieChart = ({ totalTickets }) => {
  useEffect(() => {
    if (totalTickets.length > 0) {
      const ctx = document.getElementById('pieChart').getContext('2d');
      const labels = totalTickets.map(ticket => ticket.employee_name);
      const data = totalTickets.map(ticket => ticket.total);

      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: 'Ticket Distribution',
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(153, 102, 255, 0.5)',
              'rgba(255, 159, 64, 0.5)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Ticket Distribution by Employee'
            }
          }
        }
      });
    }
  }, [totalTickets]);

  return <canvas id="pieChart" width="400" height="400"></canvas>;
};

export default PieChart;

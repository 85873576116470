import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { postData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";

export default function CodeVerification() {
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [cookies, setCookie] = useCookies(['token']);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let shouldRenderContent = true;

    // User Login info
    const database = [
        {
            username: "user1",
            password: "pass1"
        },
        {
            username: "user2",
            password: "pass2"
        }
    ];

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    useEffect(() => {
        const cookies = document.cookie.split(';');
        console.log(cookies);
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('token=')) {
                let token = cookie.substring(6);
                if(token){
                    shouldRenderContent = false;
                    history.push('/user/dashboard');
                }
            }
        }

    }, [shouldRenderContent, history]);

    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();
        try {
            setLoading(true);
            var employee_id = localStorage.getItem('employeeId');
            let { vcode } = document.forms[0];
            let reqData = {
                'employee_id': employee_id,
                'verification_code': vcode.value
            }
            let resData = await postData('/api/employee/code_verification/', reqData)
            console.log(resData)
            if (resData['data']['code'] === 200) {
                window.location.href = '/setpassword';
            }
            else if (resData['data']['code'] === 403) {
                setLoading(false);
                setErrorMessages({ name: "pass", message: errors.pass });
            }
            else {
                setLoading(false);
                setErrorMessages({ name: "uname", message: errors.uname });
            }

        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };



    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );
    if (shouldRenderContent) {
    return (
        <>
            {/* <Navbar transparent /> */}
            <main>
                {loading ? (
                    <LoadingSpinner></LoadingSpinner>
                ) : (
                    <section className="relative w-full h-full py-40 min-h-screen">
                        <div
                            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
                            style={{
                                backgroundImage:
                                    "url(" + require("../../assets/img/register_bg_2.png").default + ")",
                            }}
                        ></div>
                        <div className="container mx-auto px-4 h-full">
                            <div className="flex content-center items-center justify-center h-full">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">

                                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                            <div className="text-blueGray-600 text-center mb-3 font-bold">
                                                <large>Verification</large>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Please enter the verification code sent to your email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Code"
                                                        name="vcode"
                                                        required
                                                    />
                                                    {renderErrorMessage("uname")}
                                                </div>

                                                

                                                <div className="text-center mt-6">
                                                    <input
                                                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                        type="Submit"
                                                        value="Submit"
                                                    >

                                                    </input>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </section>
                )
                }

            </main>
            {/* <LoginFooter></LoginFooter> */}
        </>
    );
    }
}

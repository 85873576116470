import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function ProductWarranty(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const productId = searchParams.get('product_id');
  const [showModal, setShowModal] = React.useState(false);
  const [serialNumber, setSerialNumber] = useState();
  const [warrantyStartDate, setWarrantyStartDate] = useState();
  const [warrantyEndDate, setWarrantyEndDate] = useState();
  const [assetsName, setAssetsName] = useState();
  const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let { serial_number, warranty_start_date, warranty_end_date, assets_name } = document.forms[0];
    let reqData = {
      'product_id': productId,
      'serial_number': serial_number ? serial_number.value : '',
      'warranty_start_date': warranty_start_date ? warranty_start_date.value : '',
      'warranty_end_date': warranty_end_date ? warranty_end_date.value : '',
      'assets_name': assets_name ? assets_name.value: '',
    };

    let resData = await postData('/api/inventory/', reqData); 
    if (resData['data']['code'] === 200) {
      setLoading(false);
      setShowModal(true);
      window.location.href = '/admin/inventory'; 
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/inventory/product_warranty_data?product_id='+productId);
      let warrantyData = resData['data']['warranty'];
      console.log(warrantyData);
      if(warrantyData){
        setSerialNumber(warrantyData['serial_number']);
        setWarrantyStartDate(warrantyData['warranty_start_date']);
        setWarrantyEndDate(warrantyData['warranty_end_date']);
        setAssetsName(warrantyData['assets_name']);
      }
      setLoading(false);
    }
    fetchData();
  }, [productId]);

  return (
    <div className="product-warranty-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product Warranty ID : {productId}</h6>
                <button className="cursor-pointer opacity-30  px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" onClick={() => setIsEditable(isEditable?false:true)}>
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="serialNumber">Serial Number</label>
                  <input type="text"
                         id="serialNumber"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="serialNumber" defaultValue={serialNumber} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="warrantyStartDate">Warranty Start Date</label>
                  <input type="date" 
                         id="warrantyStartDate"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="warrantyStartDate" defaultValue={warrantyStartDate} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="warrantyEndDate">Warranty End Date</label>
                  <input type="date" 
                         id="warrantyEndDate"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="warrantyEndDate" defaultValue={warrantyEndDate} />
                </div>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="assetName">Assets Name</label>
                  <input type="text" 
                         id="assetName"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="assetName" defaultValue={assetsName} />
                </div>
              </div>  
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { getData } from "../../../../serverRequest";
import LoadingSpinner from "../../../../components/Loadder";
import moment from 'moment';
import { Link } from 'react-router-dom';

// components
import IncidentListTable from "../../../../components/Tables/IncidentListTable";

export default function IncidentList() {
    const [open, setOpen] = React.useState(true);
    const [totalIncidents, setTotalIncidents] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    
    const ticketFilter = searchParams.get('filter');
    //console.log(ticketFilter);
    const toggleDrawer = () => {
      setOpen(!open);
    };
  
    const formatDate = (dateString) => {
      return moment(dateString).format('DD/MM/YYYY');
    };
  
    const filterIncidentData = (incident_data) => {
      let totalIncidents = [];
      if(incident_data){
        for (let i = 0; i < incident_data.length; i++) {
            totalIncidents.push(incident_data[i])
        }
      }
     
      setTotalIncidents(totalIncidents);
      return ({ "total": totalIncidents })
    }
    React.useEffect(() => {
      async function fetchData() {
        let resData = await getData('/api/incident/reports')
        let incident_data = resData['data']['incidents']
        console.log(incident_data);
        let incidentData = await filterIncidentData(incident_data)
  
        setLoading(false);
      }
      fetchData();
    }, [])
    return (
        <>

            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Incident Report</h6>
                            <Link to="/admin/company/incidentForm">
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button">
                             Add
                            </button>
                            </Link>
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (<>
                        <div
                            className={
                                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                                ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                            }
                        >

                            <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                                {/* Projects table */}
                                <IncidentListTable totalIncidents={totalIncidents}></IncidentListTable>

                            </div>
                        </div>
                    </>)}
                </div>
            </main>

        </>
    );
}

import React, { useState } from "react";
import { getData } from "../../../serverRequest";
import { getCompanyList } from "../../../serverRequest";
import Select from 'react-select';
import LoadingSpinner from "../../../components/Loadder";
import moment from 'moment';
import { Link } from 'react-router-dom';

// components
import MonthlyTicketListTable from "../../../components/Tables/MonthlyTicketListTable.js";

export default function MonthlyTicketList() {
    const [open, setOpen] = React.useState(true);
    const [totalTickets, setTotalTickets] = React.useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyData, setCompanyData] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    
    const handleChange = selectedOption => {
        setCompanyData(selectedOption.value);
        setSelectedOption(selectedOption);
      };
    
    const fetchCompanyData = async () => {
    let companyList = await getCompanyList();
    if (companyList && companyList['data']) {
      companyList = companyList['data']
      let companyData = companyList[0]['value']
      await setCompanyData(companyData);
      setCompanies(companyList);
      setSelectedOption(companyList[0]);
    }
  }  

    const ticketFilter = searchParams.get('filter');
    //console.log(ticketFilter);
    const toggleDrawer = () => {
      setOpen(!open);
    };
  
    const formatDate = (dateString) => {
      return moment(dateString).format('DD/MM/YYYY');
    };
  
    const filterTicketData = (tickets_data) => {
      let totalTickets = [];
      if(tickets_data){
        for (let i = 0; i < tickets_data.length; i++) {
            totalTickets.push(tickets_data[i])
        }
      }
     
      setTotalTickets(totalTickets);
      return ({ "total": totalTickets })
    }
    React.useEffect(() => {
      async function fetchData() {
        let resData = await getData('/api/tickets/monthly')
        let tickets_data = resData['data']['tickets']
        console.log(tickets_data);
        let ticketData = await filterTicketData(tickets_data)
  
        setLoading(false);
      }
      fetchData();
  
  
    }, [])
    return (
        <>

            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Monthly Tickets</h6>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <Select name="clist" options={companies} value={selectedOption} onChange={handleChange} placeholder="Select Entity" />
                                </div>
                            </div>
                            <Link to="/admin/monthlyTicketForm">
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button">
                             Add 
                            </button>
                            </Link>
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (<>
                        <div
                            className={
                                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                                ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                            }
                        >

                            <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                                {/* Projects table */}
                                <MonthlyTicketListTable totalTickets={totalTickets}></MonthlyTicketListTable>

                            </div>
                        </div>
                    </>)}
                </div>
            </main>

        </>
    );
}

import React, { useState } from "react";
import Select from 'react-select';
import axios from 'axios';
import LoadingSpinner from "../Loadder";
import { getData, postData } from "../../serverRequest";
import moment from 'moment';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function ApproverAssignedTicket(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(true);
    const ticket_id = searchParams.get('ticket_id');
    const [showModal, setShowModal] = React.useState(false);
    // const [requestType, setRequestType] = useState();
    const [ticketType, setTicketType] = React.useState();
    const [ticketMode, setTicketMode] = React.useState();
    const [ticketPriority, setTicketPriority] = React.useState();
    const [ticketAssignedTo, setTicketAssignedTo] = React.useState();
    const [ticketTitle, setTicketTitle] = React.useState();
    const [ticketId, setTicketId] = React.useState();
    const [UserTicketId, setUserTicketId] = React.useState();
    // const [ticketCreatedOn, setTicketCreatedOn] = React.useState();
    const [ticketDescription, setTicketDescription] = React.useState();
    const [ticketStatus, setTicketStatus] = React.useState();
    const [ticketStatusList, setTicketStatusList] = React.useState();
    const [ticketNotify, setTicketNotify] = React.useState();
    const [ticketConnectionId, setTicketConnectionId] = React.useState();
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [dataUrls, setDataUrls] = React.useState([]);
    const [requesterName, setRequesterName] = React.useState();
    const [requesterEmail, setRequesterEmail] = React.useState();
    const [requesterEntity, setRequesterEntity] = React.useState();
    // const [ticketRemarks, setTicketRemarks] = React.useState();
    // const [ticketModes, setTicketModes] = React.useState();
    // const [ticketPriorities, setTicketPriorities] = React.useState();
    // const [companyAdmins, setCompanyAdmins] = React.useState();
    const [ticketComments, setTicketComments] = React.useState();

    // const [filePreviews, setFilePreviews] = useState([]);
    const [message, setMessage] = useState('');
    const [glData, setGlData] = React.useState();
    const [hsnData, setHsnData] = React.useState();
    const [vendorData, setVendorData] = React.useState();
    const [approvalDate, setApprovalDate] = React.useState();
    const [approvalRequired, setApprovalRequired] = React.useState();
    const [remarks, setRemarks] = React.useState();
    const [showAlert, setShowAlert] = React.useState();
    const [dbTicketId, setdbTicketId] = React.useState();


    const handleFileUpload = () => {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);
        }

        axios.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                setMessage(response.data.message);
            })
            .catch((error) => {
                setMessage('Upload failed');
                console.error(error);
            });
    };

    const approveTicket = async (event) => {
        //Prevent page reload
        setLoading(true);
        event.preventDefault();

        let reqData = {
            'ticket_id': UserTicketId,
            'id': UserTicketId,
            'ticket_status_id': 7,
        }
        //console.log(reqData);
        let resData = await postData('/api/tickets/ticket/', reqData)
        //console.log(resData)
        if (resData['data']['code'] === 200) {
            if (remarks) {
                let reqCommentData = {
                    'ticket_id': ticketId,
                    'message': remarks
                }
                //console.log(reqCommentData)
                let resCommentData = await postData('/api/tickets/comment/', reqCommentData)
                //console.log("This is data")
                ////console.log(resCommentData)
                if (resCommentData['data']['code'] === 200) {
                    //console.log("Thicket comment saved");
                }
                else {
                    //setErrorMessages({ name: "uname", message: errors.uname });
                }
            }
            setLoading(false);
            setShowModal(true);
            //console.log("This is good example");
            window.location.href = '/user/dashboard';
        }
        else {
            //setErrorMessages({ name: "uname", message: errors.uname });
        }
    };

    const rejectTicket = async (event) => {
        //Prevent page reload
        setLoading(true);
        event.preventDefault();

        if (remarks) {
            let reqData = {
                'ticket_id': UserTicketId,
                'id': UserTicketId,
                'ticket_status_id': 6,
            }
            //console.log(reqData);
            let resData = await postData('/api/tickets/ticket/', reqData)
            //console.log(resData)
            if (resData['data']['code'] === 200) {
                if (remarks) {
                    let reqCommentData = {
                        'ticket_id': ticketId,
                        'message': remarks
                    }
                    //console.log(reqCommentData)
                    let resCommentData = await postData('/api/tickets/comment/', reqCommentData)
                    //console.log("This is data")
                    ////console.log(resCommentData)
                    if (resCommentData['data']['code'] === 200) {
                        //console.log("Thicket comment saved");
                    }
                    else {
                        //setErrorMessages({ name: "uname", message: errors.uname });
                    }
                }
                setLoading(false);
                setShowModal(true);
                //console.log("This is good example");
                window.location.href = '/user/dashboard';
            }
            else {
                //setErrorMessages({ name: "uname", message: errors.uname });
            }

        }
        else {
            setLoading(false);
            setShowAlert(true);
        }


    };

    // const handleFileChange = (e) => {
    //     const files = e.target.files;
    //     setSelectedFiles(files);

    //     // Generate file previews
    //     const previews = [];
    //     for (let i = 0; i < files.length; i++) {
    //         const file = files[i];
    //         previews.push(URL.createObjectURL(file));
    //     }
    //     setFilePreviews(previews);
    // };
    // const handleDrop = (acceptedFiles) => {
    //     setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    // };

    // const handleRemove = (file) => {
    //     setSelectedFiles(selectedFiles.filter((f) => f !== file));
    // };

    const formatDate = (dateString) => {
        var localTime  = moment.utc(dateString).toDate();
        localTime = moment(localTime).subtract({'hours': 0, 'minutes': 0}).format('YYYY-MM-DD HH:mm:ss');
        //return moment.utc(dateString).local().format('DD/MM/YYYY h:MM A');
        return localTime
    };

    // const formatTicketType = (ticket_type_data) => {
    //     let ticket_type_datas = [];
    //     if (ticket_type_data) {
    //         for (let i = 0; i < ticket_type_data.length; i++) {
    //             let href = '/user/newRequest?tt=' + ticket_type_data[i]['id']
    //             ticket_type_datas.push({ value: ticket_type_data[i]['id'], label: ticket_type_data[i]['type'], href: href, current: true, description: '', imagelink: '', icon: 'fa fa-laptop' })
    //         }
    //     }

    //     return ticket_type_datas
    // }


    React.useEffect(() => {
        async function fetchData() {
            let url = '/api/tickets/ticket/?ticket_id=' + ticket_id;
            let resData = await getData(url)
            //console.log(resData);
            let ticket_data = resData['data']['ticket_data']
            let employee_data = resData['data']['employee']
            // let admin_data = resData['data']['admins']
            // let modes = resData['data']['mode']
            // let priorities = resData['data']['priority']
            // let ticket_types = resData['data']['ticket_types']
            let comments = resData['data']['comments']
            let status_list = resData['data']['status_list']
            if (ticket_data.gl_data) {
                //console.log(ticket_data.gl_data);
                setGlData(ticket_data.gl_data)
            }
            if (ticket_data.hsn_data) {
                setHsnData(ticket_data.hsn_data)
            }
            if (ticket_data.vendor_data) {
                setVendorData(ticket_data.vendor_data)
            }
            if (comments.length > 0) {
                setTicketComments(comments);
            }
            if (status_list) {
                let statusListData = []
                for (let i = 0; i < status_list.length; i++) {
                    statusListData.push({ value: status_list[i].id, label: status_list[i].status })
                    if (status_list[i] && status_list[i].status === ticket_data.status) {
                        setTicketStatus({ value: status_list[i].id, label: status_list[i].status })
                    }
                }

                setTicketStatusList(statusListData)
            }
            // if (admin_data) {
            //     let adminListData = []
            //     for (let i = 0; i < admin_data.length; i++) {
            //         adminListData.push({ value: admin_data[i].id, label: admin_data[i].employee_first_name })
            //         if (admin_data[i].employee_first_name === ticket_data.assigned_to) {
            //             setTicketAssignedTo({ value: admin_data[i].id, label: admin_data[i].employee_first_name })
            //         }
            //     }
            //     setCompanyAdmins(adminListData)
            // }
            if (ticket_data) {

                setTicketType(ticket_data.ticket_type)
                setTicketAssignedTo(ticket_data.assigned_to)
                setTicketTitle(ticket_data.title)
                setUserTicketId(ticket_data.user_ticket_id)
                setTicketId(ticket_data.id)
                setTicketMode(ticket_data.mode)
                setTicketPriority(ticket_data.priority)
                setTicketNotify(ticket_data.approver_id)
                setTicketDescription(ticket_data.description)
                //setTicketStatus(ticket_data.status)
                setTicketConnectionId(ticket_data.connection_id)
                if (ticket_data.is_approval_required) {
                    setApprovalRequired('Required')
                }
                if (ticket_data.approved_on) {
                    setApprovalDate(formatDate(ticket_data.approved_on))
                }
                let urls_data = ticket_data.attachments;
                //console.log(typeof (urls_data))
                let dataUrlSet = []
                if (urls_data) {
                    let data_url = urls_data.split(',')
                    //console.log(data_url);
                    for (let i = 0; i <= data_url.length; i++) {
                        if (data_url[i]) {
                            //console.log("I am heare");
                            let dataPath = data_url[i].trim();

                            let dataPathN = dataPath.replace(/'/g, '').replace(/\[/g, '').replace(/\]/g, '')
                            //console.log(dataPathN);
                            dataUrlSet.push(dataPathN);
                        }

                    }
                }
                //console.log(dataUrlSet);
                setDataUrls(dataUrlSet);
                setLoading(false);
            }
            if (employee_data) {
                setRequesterName(employee_data.name);
                setRequesterEmail(employee_data.email)
                setRequesterEntity(employee_data.company)
            }
        }
        fetchData();
    }, [])

    const handleSubmit = async (event) => {
        //Prevent page reload
        setLoading(true);
        event.preventDefault();
        //console.log(document.forms[1]);
        let { remarks, business, glcode, glname, group, bwda, cca, inv, hsnbusiness, sac_hsn_code,
            hsndescription, under, category, units, gst, set_alter_gst_details,
            calculation_type, tax_ability, tax_type_integrated, type_of_supply, 
            type_of_ledger, purchase_ledger, credit_type, vendorbusiness, gl_code, gl_name, 
            main_group, vgroup, sub_group, contact_person, contact_number, email_id, vendor_address, 
            city, pin, state, is_gst_registered, gst_number,
            gst_state, pan_number, bank_name, bank_address,
            bank_account_number, bank_ifsc, vbwda, vcca, vinv,
            hsnaltbusiness, sac_hsn_alt_code, alt_type_of_ledger, alt_purchase_ledger,
            alt_credit_type, accounting_allocation, } = document.forms[0];
        let reqData = {
            'ticket_id': UserTicketId,
            'id': ticketId,
        }
        if (ticketType === 'GL Code Creation/Alteration') {
            reqData['gl_code_data'] = {
              'business': business ? business.value : '',
              'glcode': glcode ? glcode.value : '',
              'glname': glname ? glname.value : '',
              'group': group ? group.value : '',
              'bwda': bwda ? bwda.value : '',
              'cca': cca ? cca.value : '',
              'inv': inv ? inv.value : ''
            }
          }
          else if (ticketType === 'HSN Creation') {
            reqData['hsn_data'] = {
              'business': hsnbusiness ? hsnbusiness.value : '',
              'sac_hsn_code': sac_hsn_code ? sac_hsn_code.value : '',
              'description': hsndescription ? hsndescription.value : '',
              'under': under ? under.value : '',
              'category': category ? category.value : '',
              'units': units ? units.value : '',
              'gst': gst ? gst.value : '',
              'set_alter_gst_details': set_alter_gst_details ? set_alter_gst_details.value : '',
              'calculation_type': calculation_type ? calculation_type.value : '',
              'tax_ability': tax_ability ? tax_ability.value : '',
              'tax_type_integrated': tax_type_integrated ? tax_type_integrated.value : '',
              'type_of_supply': type_of_supply ? type_of_supply.value : '',
              'type_of_ledger': type_of_ledger ? type_of_ledger.value : '',
              'purchase_ledger': purchase_ledger ? purchase_ledger.value : '',
              'credit_type': credit_type ? credit_type.value : ''
            }
          }
          else if (ticketType === 'HSN Alteration') {
            reqData['hsn_alt_data'] = {
              'business': hsnaltbusiness ? hsnaltbusiness.value : '',
              'sac_hsn_alt_code': sac_hsn_alt_code ? sac_hsn_alt_code.value : '',
              'type_of_ledger': alt_type_of_ledger ? alt_type_of_ledger.value : '',
              'purchase_ledger': alt_purchase_ledger ? alt_purchase_ledger.value : '',
              'credit_type': alt_credit_type ? alt_credit_type.value : '',
              'accounting_allocation': accounting_allocation?accounting_allocation.value:''
            }
          }
          else if (ticketType === 'Vendor Creation') {
            reqData['vendor_data'] = {
              'business': vendorbusiness ? vendorbusiness.value : '',
              'gl_code': gl_code ? gl_code.value : '',
              'gl_name': gl_name ? gl_name.value : '',
              'main_group': main_group ? main_group.value : '',
              'group': vgroup ? vgroup.value : '',
              'sub_group': sub_group ? sub_group.value : '',
              'contact_person': contact_person ? contact_person.value : '',
              'contact_number': contact_number ? contact_number.value : '',
              'email_id': email_id ? email_id.value : '',
              'vendor_address': vendor_address ? vendor_address.value : '',
              'city': city ? city.value : '',
              'pin': pin ? pin.value : '',
              'state': state ? state.value : '',
              'is_gst_registered': is_gst_registered ? is_gst_registered.value : '',
              'gst_number': gst_number ? gst_number.value : '',
              'gst_state': gst_state ? gst_state.value : '',
              'pan_number': pan_number ? pan_number.value : '',
              'bank_name': bank_name ? bank_name.value : '',
              'bank_address': bank_address ? bank_address.value : '',
              'bank_account_number': bank_account_number ? bank_account_number.value : '',
              'bank_ifsc': bank_ifsc ? bank_ifsc.value : '',
              'vbwda': vbwda ? vbwda.value : '',
              'vcca': vcca ? vcca.value : '',
              'vinv': vinv ? vinv.value : ''
            }
          }
          //console.log(reqData);

        //console.log(reqData);
        let resData = await postData('/api/tickets/ticket/', reqData)
        //console.log("This is data")
        //console.log(resData)
        if (resData['data']['code'] === 200) {
            if (remarks.value) {
                let reqCommentData = {
                    'ticket_id': ticketId,
                    'message': remarks.value
                }
                //console.log("This is data")
                let resCommentData = await postData('/api/tickets/comment/', reqCommentData)
                //console.log("This is data")
                ////console.log(resCommentData)
                if (resCommentData['data']['code'] === 200) {
                    //console.log("Thicket comment saved");
                }
                else {
                    //setErrorMessages({ name: "uname", message: errors.uname });
                }
            }
            setLoading(false);
            setShowModal(true);
            //console.log("This is good example");
            window.location.href = '/user/userApprovals';
        }
        else {
            //setErrorMessages({ name: "uname", message: errors.uname });
        }
    };
    const handleModelClose = async (event) => {
        setShowModal(false);
        window.location.href = '/user/dashboard';
    }

    return (
        <>
            {loading ? (
                <LoadingSpinner></LoadingSpinner>
            ) : (<>
                <form onSubmit={handleSubmit}>
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Ticket ID: {UserTicketId}</h6>

                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Requester: {requesterName}
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Email Id
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={requesterEmail}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Entity
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={requesterEntity}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Anydesk Id
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketConnectionId}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>


                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Baisc Details
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Ticket Type
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketType}
                                            disabled
                                            name="ticket_type"

                                        />
                                    </div>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Mode
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketMode}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Priority
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketPriority}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Approver Email
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketNotify}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Approval
                                        </label>

                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={approvalRequired ? (approvalDate ? 'Approved On: ' + approvalDate : 'Required') : 'Not Required'}
                                            disabled
                                            name=""
                                        />

                                    </div>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Status
                                        </label>
                                        {ticketStatus.label === 'Approval Pending' ? (<>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                defaultValue={ticketStatus.label}
                                                disabled
                                                name=""
                                            /></>) : (<>
                                                <Select name="status"
                                                    defaultValue={ticketStatus}
                                                    options={ticketStatusList}
                                                    onChange={setTicketStatus}
                                                    disabled
                                                />
                                            </>)}

                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Assigned To
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketAssignedTo}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>

                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Requirement Specific
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text" name="subject"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketTitle}
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>
                                {ticketType === 'GL Code Creation/Alteration' ?
                                    (<><h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        GL Code Form
                                    </h6>
                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Business
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.business : ''}
                                                        
                                                        name="business"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        GL code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.glcode : ''}
                                                        
                                                        name="glcode"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        GL Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.glname : ''}
                                                        
                                                        name="glname"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Group
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.group : ''}
                                                        
                                                        name="group"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Bill Wise Details Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.bwda : ''}
                                                        
                                                        name="bwda"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Cost Centre Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.cca : ''}
                                                        
                                                        name="cca"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Inventory Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.inv : ''}
                                                        
                                                        name="inv"
                                                    />
                                                </div>
                                            </div>
                                        </div></>) : ''}
                                {ticketType === 'HSN Creation/Alteration' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        HSN Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Entity Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.business : ''}
                                                    
                                                    name="hsnbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    SAC/HSN Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.sac_hsn_code : ''}
                                                    
                                                    name="sac_hsn_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.description : ''}
                                                    
                                                    name="description"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Under
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.under : ''}
                                                    
                                                    name="under"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Category
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.category : ''}
                                                    
                                                    name="category"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Units
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.units : ''}
                                                    
                                                    name="units"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.gst : ''}
                                                    
                                                    name="gst"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Set Alter GST details
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.set_alter_gst_details : ''}
                                                    
                                                    name="set_alter_gst_details"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Calculation Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.calculation_type : ''}
                                                    
                                                    name="calculation_type"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Taxability
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.tax_ability : ''}
                                                    
                                                    name="tax_ability"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Tax Type- Integrated
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.tax_type_integrated : ''}
                                                    
                                                    name="tax_type_integrated"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Supply
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.type_of_supply : ''}
                                                    
                                                    name="type_of_supply"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.type_of_ledger : ''}
                                                    
                                                    name="type_of_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Purchase Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.purchase_ledger : ''}
                                                    
                                                    name="purchase_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Credit Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.credit_type : ''}
                                                    
                                                    name="credit_type"
                                                />
                                            </div>
                                        </div>

                                    </div></>) : ''}
                                {ticketType === 'Vendor Creation' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        Vendor Creation Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.business : ''}
                                                    
                                                    name="vendorbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GL code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gl_code : ''}
                                                    
                                                    name="gl_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GL Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gl_name : ''}
                                                    
                                                    name="gl_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Main Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.main_group : ''}
                                                    
                                                    name="main_group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.group : ''}
                                                    
                                                    name="group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Sub Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.sub_group : ''}
                                                    
                                                    name="sub_group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Contact Person Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.contact_person : ''}
                                                    
                                                    name="contact_person"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Contact No
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.contact_number : ''}
                                                    
                                                    name="contact_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Email ID
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.email_id : ''}
                                                    
                                                    name="email_id"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Address of the Vendor
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vendor_address : ''}
                                                    
                                                    name="vendor_address"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.city : ''}
                                                    
                                                    name="city"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    PIN
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.pin : ''}
                                                    
                                                    name="pin"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    State
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.state : ''}
                                                    
                                                    name="state"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Registered/ Unregistered under GST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.is_gst_registered : ''}
                                                    
                                                    name="is_gst_registered"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST No.
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gst_number : ''}
                                                    
                                                    name="gst_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST State
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gst_state : ''}
                                                    
                                                    name="gst_state"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    PAN No.
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.pan_number : ''}
                                                    
                                                    name="pan_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_name : ''}
                                                    
                                                    name="bank_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Address
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_address : ''}
                                                    
                                                    name="bank_address"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Account No
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_account_number : ''}
                                                    
                                                    name="bank_account_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank IFSC
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_ifsc : ''}
                                                    
                                                    name="bank_ifsc"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bill Wise Details Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vbwda : ''}
                                                    
                                                    name="vbwda"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Cost Centre Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vcca : ''}
                                                    
                                                    name="vcca"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Inventory Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vinv : ''}
                                                    
                                                    name="vinv"
                                                />
                                            </div>
                                        </div>
                                    </div></>) : ''}
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Description
                                        </label>
                                        <SunEditor name="description" height="250px" defaultValue={ticketDescription} disable />
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Attachments
                            </h6>
                            <div className="flex flex-wrap">


                                {dataUrls.map((file, index) => (
                                    <div key={index} className="preview w-full lg:w-3/12 px-4">
                                        <img src={file} />

                                    </div>
                                ))}

                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Remarks
                            </h6>
                            <div className="flex flex-wrap">

                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Remarks
                                        </label>
                                        {ticketComments ? ticketComments.map((item, index) => (
                                            <><label><b>{item.created_by}: </b>{formatDate(item.created_on)}</label>
                                                <p
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >{item.comment}</p><br></br></>
                                        )) : ''}

                                    </div>
                                </div>
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            {approvalRequired === "Required" ? (<>
                                <hr className="mt-6 border-b-1 border-blueGray-300" />

                                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    Remarks
                                    {showAlert ? (
                                        <div
                                            className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
                                        >
                                            <span className="text-xl inline-block mr-5 align-middle">
                                                <i className="fas fa-bell" />
                                            </span>
                                            <span className="inline-block align-middle mr-8">
                                                <b className="capitalize">Error !</b> Request you to please provide the remarks for rejecting the request.
                                            </span>
                                            <button
                                                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                                                onClick={() => setShowAlert(false)}
                                            >
                                                <span>×</span>
                                            </button>
                                        </div>
                                    ) : null}
                                </h6>
                                <div className="flex flex-wrap">
                                    <textarea
                                        type="text" name="remarks"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        rows="4"
                                        onChange={v => setRemarks(v.target.value)}
                                        defaultValue={remarks}


                                    ></textarea><br></br>
                                </div>

                                <div className="flex flex-wrap mt-6">
                                    <div className="w-full lg:w-3/12 px-4">
                                        <button
                                            className="bg-blueGray-800 text-center text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            onClick={approveTicket}
                                        >
                                            Approve
                                        </button>
                                    </div>
                                    <div className="w-full lg:w-3/12 px-4">
                                        <button
                                            className="bg-red-600 text-center text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            onClick={rejectTicket}
                                        >
                                            Reject
                                        </button>
                                    </div>

                                </div>
                            </>) : ''}


                        </div>
                        <div className="text-center mt-6 lg:w-3/12 px-4">
                            <input
                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="Submit"
                                value="Submit"
                            >

                            </input>
                        </div>
                    </div>

                </form>
            </>)
            }
        </>
    );
}

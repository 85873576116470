import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const BarChart = ({ totalTickets }) => {
  useEffect(() => {
    if (totalTickets.length > 0) {
      const ctx = document.getElementById('barChart').getContext('2d');
      const labels = totalTickets.map(ticket => ticket.employee_name);
      const data = totalTickets.map(ticket => ticket.total);

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: 'Total Tickets',
            data: data,
            backgroundColor: totalTickets.map(() => `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`),
            borderColor: 'rgba(0, 0, 0, 0)', 
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Total Tickets by Employee'
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }
  }, [totalTickets]);

  return <canvas id="barChart" width="400" height="400"></canvas>;
};

export default BarChart;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { getData } from "../../../../serverRequest";
import LoadingSpinner from "../../../../components/Loadder";
import moment from 'moment';
// components
import SummarisedCompanyReportTable from "../../../../components/Tables/SummarisedCompanyReportTable";
import CompanyReportChart from "./CompanyReportChart.js";


export default function SummarisedCompanyReport({ color }) {
    const [open, setOpen] = React.useState(true);
    const [openTickets, setOpenTickets] = React.useState([]);
    const [closedTicket, setclosedTicket] = React.useState();
    const [totalTickets, setTotalTickets] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);

    const ticketFilter = searchParams.get('filter');
    //console.log(ticketFilter);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY');
    };

    const filterTicketData = (tickets_data) => {
        console.log(tickets_data);
       
        let totalTicket = [];
        if (tickets_data) {
            for (let i = 0; i < tickets_data.length; i++) {
                let ticket_set = {
                    'company_id': tickets_data[i]['company_id'],
                    'company_name': tickets_data[i]['company_name'],
                    'assigned': 0,
                    'approved': 0,
                    'pending': 0,
                    'resolved': 0,
                    'reopen': 0,
                    'inprogress': 0,
                    'total': 0
                }
                for (let j = 0; j < tickets_data[i]['dataset'].length; j++) {
                    if (tickets_data[i]['dataset'][j]['status'] === 'Resolved') {
                        ticket_set['resolved'] = tickets_data[i]['dataset'][j]['total']
                    }
                    else if (tickets_data[i]['dataset'][j]['status'] === 'Assigned') {
                        ticket_set['assigned'] = tickets_data[i]['dataset'][j]['total']
                    }
                    else if (tickets_data[i]['dataset'][j]['status'] === 'Approved') {
                        ticket_set['approved'] = tickets_data[i]['dataset'][j]['total']
                    }
                    else if (tickets_data[i]['dataset'][j]['status'] === 'Approval Pending') {
                        ticket_set['pending'] = tickets_data[i]['dataset'][j]['total']
                    }
                    else if (tickets_data[i]['dataset'][j]['status'] === 'Reopen') {
                        ticket_set['reopen'] = tickets_data[i]['dataset'][j]['total']
                    }
                    else if (tickets_data[i]['dataset'][j]['status'] === 'IN Progress') {
                        ticket_set['inprogress'] = tickets_data[i]['dataset'][j]['total']
                    }
                }
                ticket_set['total'] = ticket_set['resolved'] + ticket_set['assigned'] + ticket_set['approved'] + ticket_set['pending'] + + ticket_set['reopen']
                totalTicket.push(ticket_set)
            }
        }
        
        setTotalTickets(totalTicket);
        return ({ "total": totalTicket })
    }
    React.useEffect(() => {
        async function fetchData() {
            console.log("tickets_data");
            let resAdminData = await getData('/api/reports/summarised_company_report')
            let tickets_admin_data = resAdminData['data']['tickets_data']
            console.log(resAdminData);
            //console.log(tickets_data);
            let ticketData = await filterTicketData(tickets_admin_data)

            setLoading(false);
        }
        fetchData();
    }, [])

   return (
    <>
        {loading ? (
            <LoadingSpinner></LoadingSpinner>
        ) : (
            <>
                <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " + (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
                    <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                        <div className="w-full xl:w-12/12 px-4">
                            <main>
                             <div className="w-full mt-6">
                                    <div className="flex justify-center">
                                        <div className="w-full xl:w-12/12 px-4">
                                            <div className="flex justify-center">
                                                <div className="w-full">
                                                    <CompanyReportChart totalTickets={totalTickets} /> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative block py-24 lg:pt-0 px-4">
                                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                                        <div className="text-center flex justify-between">
                                            <h6 className="text-blueGray-700 text-xl font-bold pb-6">Company Report</h6>                                            
                                        </div>
                                        <SummarisedCompanyReportTable totalTickets={totalTickets}></SummarisedCompanyReportTable>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
);

    
}

SummarisedCompanyReport.defaultProps = {
    color: "light",
};

SummarisedCompanyReport.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};

import React, { useState } from "react";

// components
import { getData } from "../../serverRequest";

import PendingApproval from "../../components/Cards/PendingApproval.js";
import LoadingSpinner from "../../components/Loadder";
// components

import CardRingChart from "../../components/Cards/CardRingChart.js";
import CardStats from "../../components/Cards/CardStats.js";
import CardTicketOverview from "../../components/Cards/CardTicketOverview.js";

export default function Dashboard() {
  const [open, setOpen] = React.useState(true);
  const [openTicket, setOpenTicket] = React.useState(true);
  const [closedTicket, setclosedTicket] = React.useState(true);
  const [totalTicket, settotalTicket] = React.useState(true);
  const [monthlyOpenTicket, setMonthlyOpenTicket] = React.useState(true);
  const [monthlyInProgressTicket, setMonthlyInProgressTicket] = React.useState(true);
  const [monthlyClosedTicket, setMonthlyClosedTicket] = React.useState(true);
  const [monthlyTicket, setMonthlyTicket] = React.useState(null);
  const [statusTicket, setStatusTicket] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const filterTicketData = (tickets_data) => {
    let openTicketCount = 0;
    let closeTicketCount = 0;
    let totalTicketCount = 0;
    let monthlyOpenTicketCount = 0;
    let monthlyInProgressTicketCount = 0;
    let monthlyClosedTicketCount = 0;
    
    if (tickets_data) {
      totalTicketCount = tickets_data.length;
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] === 'New' || tickets_data[i]['status'] === 'Assigned' || tickets_data[i]['status'] === 'Approval Pending' || tickets_data[i]['status'] === 'IN Progress'
        || tickets_data[i]['status'] === 'Reopen' || tickets_data[i]['status'] === 'Approved') {
          openTicketCount++;
        }
        else if (tickets_data[i]['status'] === 'Resolved') {
          closeTicketCount++;
        }
        else if (tickets_data[i]['status'] === 'New') {
          monthlyOpenTicketCount++;
        }
        else if (tickets_data[i]['status'] === 'IN Progress') {
          monthlyInProgressTicketCount++;
        }
        else if (tickets_data[i]['status'] === 'Closed') {
          monthlyClosedTicketCount++;
        }
      }
    }

    return ({ "open": openTicketCount, "close": closeTicketCount, "total": totalTicketCount, "total_open": monthlyOpenTicketCount, "total_inprogress": monthlyInProgressTicketCount, "total_closed": monthlyClosedTicketCount })
  }
  React.useEffect(() => {

    async function fetchData() {
      let resData = await getData('/api/tickets/dashboard/?admin=1')
      let tickets_data = resData['data']['tickets_data']
      // let TicketData = await filterTicketData(tickets_data)
      setOpenTicket({ title: "Open Ticket", count: tickets_data['open_ticket'], color: '#cafdf5' });
      setclosedTicket({ title: "Closed Ticket", count: tickets_data['close_ticket'], color: '#90ffba' });
      settotalTicket({ title: "Total Ticket", count: tickets_data['total_ticket'], color: '#d6d4ff' });
      setMonthlyOpenTicket({ title: "Monthly Open", count: tickets_data['monthly_open_ticket'], color: '#cafdf5' });
      setMonthlyInProgressTicket({ title: "Monthly In Progress", count: tickets_data['monthly_inprogress_ticket'], color: '#ffcc00' });
      setMonthlyClosedTicket({ title: "Monthly Closed", count: tickets_data['monthly_closed_ticket'], color: '#90ffba' });
      setMonthlyTicket(tickets_data['month_wise_tickets'])
      setStatusTicket(tickets_data['status_wise_tickets'])
      setLoading(false);
    }
    fetchData();


  }, [])
  return (
    <>{loading ? (
      <LoadingSpinner />
    ) : (<>
      <div className="relative bg-lightBlue-800 md:pt-8 pb-8">
        <div className="px-4 md:px-8 mx-auto w-full">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
              <a href={"/admin/tickets/filter/?filter=open"}>
                <CardStats
                  statSubtitle={openTicket.title}
                  statTitle={openTicket.count}
                  statIconName="fa fa-cog fa-spin"
                  statIconColor="bg-red-500"
                  statfilter='open'
                />
              </a>
            </div>
            <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
              <a href={"/admin/tickets/filter/?filter=closed"}>
                <CardStats
                  statSubtitle={closedTicket.title}
                  statTitle={closedTicket.count}
                  statIconName="fas fa-cog fa-spin"
                  statIconColor="bg-yellow-500"
                  statfilter='closed'
                />
              </a>
            </div>
            <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
              <a href={"/admin/tickets/filter/?filter=all"}>
                <CardStats
                  statSubtitle={totalTicket.title}
                  statTitle={totalTicket.count}
                  statIconName="fas fa-cog fa-spin"
                  statIconColor="bg-purple-500"
                  statfilter='all'
                />
              </a>
            </div>
            <div className="w-full lg:w-4/12 xl:w-4/12 px-4 py-2">
                  <a href={"/admin/tickets/filter/?filter=total_open"}>
                    <CardStats
                      statSubtitle={monthlyOpenTicket.title}
                      statTitle={monthlyOpenTicket.count}
                      statIconName="fa fa-cog fa-spin"
                      statIconColor="bg-orange-500"
                      statfilter='total_open'
                    />
                  </a>
                </div>
                <div className="w-full lg:w-4/12 xl:w-4/12 px-4 py-2">
                  <a href={"/admin/tickets/filter/?filter=total_closed"}>
                    <CardStats
                      statSubtitle={monthlyClosedTicket.title}
                      statTitle={monthlyClosedTicket.count}
                      statIconName="fa fa-cog fa-spin"
                      statIconColor="bg-green-500"
                      statfilter='total_closed'
                    />
                  </a>
                </div>
                <div className="w-full lg:w-4/12 xl:w-4/12 px-4 py-2">
                  <a href={"/admin/tickets/filter/?filter=total_inprogress"}>
                    <CardStats
                      statSubtitle={monthlyInProgressTicket.title}
                      statTitle={monthlyInProgressTicket.count}
                      statIconName="fa fa-cog fa-spin"
                      statIconColor="bg-lightBlue-500"
                      statfilter='total_inprogress'
                    />
                  </a>
                </div>
          </div>
        </div>
      </div>
      <div className="relative block py-24 lg:pt-0">
        <div className="flex flex-wrap lg:pt-4 pb-2 pl-1 pr-2 ">

          <div className="w-full xl:w-6/12 px-4" key={openTicket}>
            {monthlyTicket?<CardRingChart monthlyData={monthlyTicket} />:''}
          </div>

          <div className="w-full xl:w-6/12 px-4">
            {statusTicket?<CardTicketOverview monthlyData={monthlyTicket} />:''}
          </div>
        </div>

        {/* <div className="w-full xl:w-12/12 px-4">
          <PendingApproval />
        </div> */}
      </div>
    </>)}

    </>
  );
}

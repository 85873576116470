import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AdminAssignedTicket from "../../../components/Cards/AdminAssignedTicket";
import moment from 'moment';
import { getData, postData } from "../../../serverRequest";
import LoadingSpinner from "../../../components/Loadder";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { right } from "@popperjs/core";
import MonthlyTicketList from "../../admin/tickets/MonthlyTicketList.js"
// const ticketCardsData = [
//   {
//     id: 1,
//     title: "Ticket 1",
//     description: "Ticket 1 description",
//     status: "Assigned",
//     glData: {},
//   },
//   {
//     id: 2,
//     title: "Ticket 2",
//     description: "Ticket 2 description",
//     status: "In Progress",
//     glData: {},
//   },
//   {
//     id: 3,
//     title: "Ticket 3",
//     description: "Ticket 3 description",
//     status: "Approval Pending",
//     glData: {},
//   },
//   {
//     id: 4,
//     title: "Ticket 4",
//     description: "Ticket 4 description",
//     status: "Result",
//     glData: {},
//   },
// ];

function Modal({ ticket_id, onClose }) {
  //alert(ticket_id)
  return (
    <div className="modal modal-height">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <AdminAssignedTicket ticket_id={ticket_id} /> {/* Render content from another JavaScript file */}
          </div>

        </div>
      </div>
    </div>
  );
}

function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-5 w-5 text-yellow-700"
    >
      <path
        fillRule="evenodd"
        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const AdminMonthlyTickets = () => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [columnsState, setColumnsState] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [ticketCardsData, setTicketCardsData] = React.useState([]);
  const [closedTicket, setclosedTicket] = React.useState();
  const [totalTickets, setTotalTickets] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isSuperadmin, setSuperadmin] = React.useState(JSON.parse(localStorage.getItem('companyAdmin')));
  
  const searchParams = new URLSearchParams(window.location.search);
  const ticketFilter = searchParams.get('filter');
  //console.log(ticketFilter);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };
  const Capitalize = (str_txt) => {
    return str_txt.charAt(0).toUpperCase() + str_txt.slice(1);
  }
  const filterTicketData = (tickets_data) => {
    //console.log(tickets_data);
    let ticket_data_set = []
    if (tickets_data) {
      for (let i = 0; i < tickets_data.length; i++) {
        ticket_data_set.push({
          id: tickets_data[i].id,
          title: tickets_data[i].title,
          description: tickets_data[i].description,
          status: tickets_data[i].status,
          user_ticket_id: tickets_data[i].user_ticket_id,
          created_by_fl: tickets_data[i].created_by.slice(0, 1),
          created_by: tickets_data[i].created_by
        })
      }
    }


    return ({ "total": ticket_data_set })
  }
  async function fetchData() {
    let resData = await getData('/api/tickets/?current_month=1')
    let tickets_data = resData['data']['tickets_data']
    //console.log(tickets_data);
    let ticketData = await filterTicketData(tickets_data)
    console.log(ticketData['total']);
    setTicketCardsData(ticketData['total']);
    const columns = [
      {
        id: "3",
        name: "Assigned",
        tickets: ticketData['total'].filter((ticket) => ticket.status === "Assigned"),
      },
      {
        id: "8",
        name: "In Progress",
        tickets: ticketData['total'].filter((ticket) => ticket.status === "IN Progress"),
      },
      {
        id: "5",
        name: "Approval Pending",
        tickets: ticketData['total'].filter((ticket) => ticket.status === "Approval Pending"),
      },
      {
        id: "2",
        name: "Resolved",
        tickets: ticketData['total'].filter((ticket) => ticket.status === "Resolved"),
      },
    ];
    setColumnsState(columns);
    console.log(columns);

    setLoading(false);
  }
  React.useEffect(() => {
    fetchData();
  }, [])

  const openTicketModal = (ticket) => {
    //alert(ticket)
    setSelectedTicket(ticket);
    setShowModal(true);
  };

  const saveTicket = async (ticket_id, status) => {
    //Prevent page reload
    setLoading(true);
    if (ticket_id) {
        let reqData = {
            'ticket_id': ticket_id,
            'ticket_status_id': status
        }
        let resStatusData = await postData('/api/tickets/ticket/', reqData)
        if (resStatusData['data']['code'] === 200) {
          alert("Ticket status updated")
          setLoading(false);
        }
        else {
            //setErrorMessages({ name: "uname", message: errors.uname });
        }
    }
};
  
  const onDragEnd = (result) => {
    // Check if the drag ended outside of a droppable area
    if (!result.destination) return;

    const { source, destination, draggableId } = result;
    console.log(result);
    // If the ticket was dropped back into its original position, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index)
      return;

    // Reorder tickets in the columns
    const newColumns = [...columnsState];
    const sourceColumn = newColumns.find((col) => col.id === source.droppableId);
    const destColumn = newColumns.find((col) => col.id === destination.droppableId);
    const [movedTicket] = sourceColumn.tickets.splice(source.index, 1);
    destColumn.tickets.splice(destination.index, 0, movedTicket);
    saveTicket(draggableId, destination.droppableId)
    // Update the state with the new columns order
    setColumnsState(newColumns);
  };

  return (
    <>
      {isSuperadmin ? (
        <MonthlyTicketList />
      ) : (      
        loading ? (
          <LoadingSpinner></LoadingSpinner>
    ) : (<>
      <div className="w-full h-full flex justify-center items-center bg-gray-100 relative">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="admin-monthly-tickets-container flex overflow-x-auto h-full">
            {columnsState.map((column) => (
              <div
                className="ticket-column flex-shrink-0 w-72 p-1 bg-blue-100 rounded-lg shadow-md mr-4 text-center"
                key={column.id} style={{ width: "260px", backgroundColor: "#b3d4fc" }}>
                <h2 className="text-lg font-bold mb-8 text-white">{column.name}</h2>
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ minHeight: "calc(100vh - 200px)" }}
                    >
                      {column.tickets.map((ticket, index) => (
                        <Draggable
                          key={ticket.id}
                          draggableId={ticket.user_ticket_id}
                          index={index}
                        >
                          {(provided) => (
                            <a href={'/admin/ticket/?ticket_id=' + ticket.user_ticket_id}>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="ticket-card p-1 bg-white rounded shadow-md cursor-pointer mb-4"
                              // onClick={() => openTicketModal(ticket.user_ticket_id)}
                              >
                                <Card sx={{ maxWidth: 345 }}>
                                  <CardHeader

                                    // action={
                                    //   <IconButton aria-label="settings">
                                    //     <MoreVertIcon />
                                    //   </IconButton>
                                    // }
                                    subheader={Capitalize(ticket.title)}

                                  />

                                  <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                    {column.name}
                                    </Typography>
                                  </CardContent>
                                  <CardActions disableSpacing>

                                    <div className="lg:w-9/12">
                                      {Capitalize(ticket.user_ticket_id)}
                                    </div>
                                    <div className="lg:w-3/12">


                                      <Avatar sx={{ bgcolor: red[500], alignItems: right }} aria-label="recipe">
                                        {Capitalize(ticket.created_by_fl)}
                                      </Avatar>
                                    </div>
                                  </CardActions>

                                </Card>
                                {/* <p className="text-lg font-semibold mb-2">
                              {ticket.title}
                            </p> */}
                                {/* Optional chaining combined with logical AND operator applied here */}
                                {/* <p className="text-xs">{ticket.glData && ticket.glData.exampleProperty}</p> */}
                              </div>
                            </a>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}

          </div>
        </DragDropContext>
        {/* {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none modal-height"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-6xl">
              
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Ticket ID : {selectedTicket}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                
                <div className="relative p-6 flex-auto">
                <AdminAssignedTicket ticket_id={selectedTicket} />
                </div>
               
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null
        
        
         } */}
      </div>
    
    </>))}

    </>
  );
};

export default AdminMonthlyTickets;

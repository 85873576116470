import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { postData } from "../../../../serverRequest";

export default function TallyForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);  
  const [entityName] = useState();
  const [owner] = useState();  
  const [backupSize] = useState();  
  const [sharedWith] = useState(); 
  const [backupDate] = useState(); 
  const [tallyBackupTime] = useState(); 
  const [driveLink] = useState(); 
  const [uploadedBy] = useState(); 
  const [remarks] = useState(); 

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    
    const { entityName, owner, backupSize, sharedWith, backupDate, tallyBackupTime, driveLink, uploadedBy, remarks } = document.forms[0];
  
    const formData = {
      'entityName': entityName ? entityName.value : '',
      'owner': owner ? owner.value : '',
      'backupSize': backupSize ? backupSize.value :'',
      'sharedWith': sharedWith ? sharedWith.value :'',
      'backupDate': backupDate ? backupDate.value :'',
      'tallyBackupTime': tallyBackupTime ? tallyBackupTime.value :'',
      'driveLink': driveLink ? driveLink.value :'',
      'uploadedBy': uploadedBy ? uploadedBy.value :'',
      'remarks': remarks ? remarks.value :'',
    };
  
    try {
      const res = await postData("/api/employee/", formData);
      
      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = '/admin/admins';
      } else {
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };
   
  return (
    <div className="admin-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Tally Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Entity Name</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name= "entityName" defaultValue={entityName} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Owner</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="owner" defaultValue={owner} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Backup Size</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="backupSize" defaultValue={backupSize} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Shared With</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="sharedWith" defaultValue={sharedWith} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Backup Date</label>
                <input type="date" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="backupDate" defaultValue={backupDate} />
              </div>
            </div> 
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Tally Backup Time (24 hours)</label>
                <input type="time" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="tallyBackupTime" defaultValue={tallyBackupTime} />
              </div>
            </div> 
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Drive Link</label>
                <input type="url" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="driveLink" defaultValue={driveLink} />
              </div>
            </div> 
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Uploaded By</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="uploadedBy" defaultValue={uploadedBy} />
              </div>
            </div> 
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Remarks</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="remarks" defaultValue={remarks} />
              </div>
            </div> 
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"
                />                            
            </div>          
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function ProductsData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const productId = searchParams.get('product_id');
  const [showModal, setShowModal] = useState(false);
  const [productName, setProductName] = useState();
  const [productCategory, setProductCategory] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productTax, setProductTax] = useState();
  const [productInvoiceNo, setProductInvoiceNo] = useState();
  const [productPoNo, setProductPoNo] = useState();
  const [productLocation, setProductLocation] = useState();
  const [productModelNo, setProductModelNo] = useState();
  const [productSerialNo, setProductSerialNo] = useState();
  const [productQuantity, setProductQuantity] = useState();
  const [productRemarks, setProductRemarks] = useState();
  const [productPurchaseDate, setProductPurchaseDate] = useState();

  const handleSubmit = async (event) => {
    // Prevent page reload
    setLoading(true);
    event.preventDefault();
    let { name, category, price, tax, invoice_no, po_no, location, model_no, serial_no, quantity, remarks, purchase_date } = document.forms[0];
    let reqData = {
      'product_id': productId,
      'id': productId,
      'name': name ? name.value : '',
      'category': category ? category.value : '',
      'price': price ? price.value : '',
      'tax': tax ? tax.value : '',
      'invoice_no': invoice_no ? invoice_no.value : '',
      'po_no': po_no ? po_no.value : '',
      'location': location ? location.value : '',
      'model_no': model_no ? model_no.value : '',
      'serial_no': serial_no ? serial_no.value : '',
      'quantity': quantity ? quantity.value : '',
      'remarks': remarks ? remarks.value : '',
      'purchase_date': purchase_date ? purchase_date.value : '',
    }

    let resData = await postData('/api/product/', reqData); 
    if (resData['data']['code'] === 200) {
      setLoading(false);
      setShowModal(true);
      window.location.href = '/products'; 
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/product?product_id=' + productId);
      let product_data = resData['data']['product'];
      console.log(product_data);
      if(product_data){
        setProductName(product_data['name']);
        setProductCategory(product_data['category']);
        setProductPrice(product_data['price']);
        setProductTax(product_data['tax']);
        setProductInvoiceNo(product_data['invoice_no']);
        setProductPoNo(product_data['po_no']);
        setProductLocation(product_data['location']);
        setProductModelNo(product_data['model_no']);
        setProductSerialNo(product_data['serial_no']);
        setProductQuantity(product_data['quantity']);
        setProductRemarks(product_data['remarks']);
        setProductPurchaseDate(product_data['purchase_date']);
      }
     
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="product-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product ID : {productId}</h6>
                <button className="cursor-pointer opacity-30 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"> {/*onClick={() => setIsEditable(isEditable?false:true)}*/} 
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Name</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name= "name" defaultValue={productName} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Category</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="category" defaultValue={productCategory} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Price</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="price" defaultValue={productPrice} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Tax</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="tax" defaultValue={productTax} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Invoice No.</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="invoice_no" defaultValue={productInvoiceNo} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">PO No.</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="po_no" defaultValue={productPoNo} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Location</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="location" defaultValue={productLocation} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Model No.</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="model_no" defaultValue={productModelNo} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Serial No.</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="serial_no" defaultValue={productSerialNo} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Quantity</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="quantity" defaultValue={productQuantity} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Remarks</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="remarks" defaultValue={productRemarks} />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Purchase Date</label>
                <input type="date" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       disabled
                       name="purchase_date" defaultValue={productPurchaseDate} />
              </div>
            </div>  
            </div>
            {/*{isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}   */}       
          </div>
        </form>
      )}
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import Login from "./views/Login";
import User from './views/User';
import Admin from './views/Admin';
import EmailVerification from './views/ForgotPassword/EmailVerification';
import CodeVerification from './views/ForgotPassword/CodeVerification';
import ResetPassword from './views/ForgotPassword/ResetPassword';
// import Register from './views/Register';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
 
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/fpassword" exact component={EmailVerification} />
      <Route path="/vuser" exact component={CodeVerification} />
      <Route path="/setpassword" exact component={ResetPassword} />
      {/* <Route path="/register" exact component={Register} /> */}

      <Route path="/user" component={User} />
      <Route path="/admin" component={Admin} />
      {/* add redirect for first page */}
      
    </Switch>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

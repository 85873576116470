import React, { useState, useEffect } from "react";
import { getData } from "../../../../serverRequest";
import LoadingSpinner from "../../../../components/Loadder"; 
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "../DatePicker.css";

// Components
import ProductsListTable from "../../../../components/Tables/ProductsListTable";

export default function ProductsList() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchData = async () => {
        try {
            const res = await getData('/api/products'); 
            if (res.data && res.data.products) {
                setProducts(res.data.products);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY');
    };

    return (
        <>
            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Products</h6>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative mb-3">
                                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} placeholderText="Start Date" className="w-full mb-3 px-3 py-2 border rounded"/>
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w- mb-3">
                                    <DatePicker selected={endDate} onChange={date => setEndDate(date)} placeholderText="End Date" className="w-full mb-3 px-3 py-2 border rounded"/>
                                </div>
                            </div>
                            <Link to="/admin/inventories/productsForm">
                                <button
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                >
                                    Add
                                </button>
                            </Link>
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                            <div className="block w-full overflow-x-auto py-4 px-6">
                                <ProductsListTable products={products} formatDate={formatDate} />
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </>
    );
}

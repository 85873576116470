import React, { useState } from "react";
import { getData, postData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";
import SunEditor from 'suneditor-react';
import moment from 'moment';
import 'suneditor/dist/css/suneditor.min.css';


export default function UserTicket(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(true);
    const ticket_id = searchParams.get('ticket_id');
    const [showModal, setShowModal] = React.useState(false);
    // const [requestType, setRequestType] = useState();
    const [ticketType, setTicketType] = React.useState();
    const [ticketMode, setTicketMode] = React.useState();
    const [ticketPriority, setTicketPriority] = React.useState();
    const [ticketAssignedTo, setTicketAssignedTo] = React.useState();
    const [ticketTitle, setTicketTitle] = React.useState();
    const [ticketId, setTicketId] = React.useState();
    // const [ticketCreatedOn, setTicketCreatedOn] = React.useState();
    const [ticketDescription, setTicketDescription] = React.useState();
    const [ticketStatus, setTicketStatus] = React.useState();
    const [ticketNotify, setTicketNotify] = React.useState();
    const [ticketConnectionId, setTicketConnectionId] = React.useState();
    // const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [dataUrls, setDataUrls] = React.useState([]);
    const [glData, setGlData] = React.useState();
    const [hsnData, setHsnData] = React.useState();
    const [hsnAltData, setHsnAltData] = React.useState();
    const [vendorData, setVendorData] = React.useState();
    const [approvalRequired, setApprovalRequired] = React.useState();
    const [remarks, setRemarks] = React.useState();
    const [showAlert, setShowAlert] = React.useState();
    const [dbTicketId, setdbTicketId] = React.useState();
    const [isReopened, setIsReopened] = React.useState(false);
    const [ticketComments, setTicketComments] = React.useState();
    // const formatTicketType = (ticket_type_data) => {
    //     let ticket_type_datas = [];
    //     if (ticket_type_data) {
    //         for (let i = 0; i < ticket_type_data.length; i++) {
    //             let href = '/user/newRequest?tt=' + ticket_type_data[i]['id']
    //             ticket_type_datas.push({ value: ticket_type_data[i]['id'], label: ticket_type_data[i]['type'], href: href, current: true, description: '', imagelink: '', icon: 'fa fa-laptop' })
    //         }
    //     }

    //     return ticket_type_datas
    // }
    const formatDate = (dateString) => {
        var localTime = moment.utc(dateString).toDate();
        localTime = moment(localTime).subtract({ 'hours': 0, 'minutes': 0 }).format('YYYY-MM-DD HH:mm:ss');
        //return moment.utc(dateString).local().format('DD/MM/YYYY h:MM A');
        return localTime
    };
    React.useEffect(() => {
        async function fetchData() {
            let url = '/api/tickets/ticket/?ticket_id=' + ticket_id;
            let resData = await getData(url)
            let ticket_data = resData['data']['ticket_data']
            let comments = resData['data']['comments']
            //console.log(ticket_data);
            if (ticket_data) {
                if (ticket_data.resolved_on && !ticket_data.reopened_on) {
                    setIsReopened(true)
                    let now = moment()
                    //console.log(now);
                    let estimated_reopen_time = moment(ticket_data.resolved_on).add(1, 'd')
                    //console.log(estimated_reopen_time);
                    if (now.isAfter(estimated_reopen_time)) {
                        //console.log("I can't reope");
                        setIsReopened(false)
                    }
                }

                setTicketType(ticket_data.ticket_type)
                setTicketAssignedTo(ticket_data.assigned_to)
                setTicketTitle(ticket_data.title)
                setTicketId(ticket_data.user_ticket_id)
                setTicketMode(ticket_data.mode)
                setTicketPriority(ticket_data.priority)
                setTicketNotify(ticket_data.approver_id)
                setTicketDescription(ticket_data.description)
                setTicketStatus(ticket_data.status)
                setTicketConnectionId(ticket_data.connection_id)
                setdbTicketId(ticket_data.id)
                if (ticket_data.gl_data) {
                    //console.log(ticket_data.gl_data);
                    setGlData(ticket_data.gl_data)
                }
                if (ticket_data.hsn_data) {
                    setHsnData(ticket_data.hsn_data)
                }
                if (ticket_data.hsn_alt_data) {
                    setHsnAltData(ticket_data.hsn_alt_data)
                }
                if (ticket_data.vendor_data) {
                    setVendorData(ticket_data.vendor_data)
                }
                //console.log(ticket_data.is_approval_required);
                let username = localStorage.getItem('userName');
                if (ticket_data.is_approval_required && ticket_data.created_by !== username && (ticket_data.status !== "Approved" || ticket_data.status !== "Rejected")) {
                    setApprovalRequired("required")
                }
                if (comments.length > 0) {
                    setTicketComments(comments);
                }
                let urls_data = ticket_data.attachments;
                //console.log(typeof (urls_data))
                let dataUrlSet = []
                if (urls_data) {
                    let data_url = urls_data.split(',')
                    //console.log(data_url);
                    for (let i = 0; i <= data_url.length; i++) {
                        if (data_url[i]) {
                            //console.log("I am heare");
                            let dataPath = data_url[i].trim();

                            let dataPathN = dataPath.replace(/'/g, '').replace(/\[/g, '').replace(/\]/g, '')
                            //console.log(dataPathN);
                            dataUrlSet.push(dataPathN);
                        }

                    }
                }
                //console.log(dataUrlSet);
                setDataUrls(dataUrlSet);
                setLoading(false);
            }
        }
        fetchData();
    }, [])


    const reponeTicket = async (event) => {
        //Prevent page reload
        setLoading(true);
        event.preventDefault();

        if (remarks) {
            let reqData = {
                'ticket_id': ticketId,
                'id': ticketId,
                'ticket_status_id': 10,
            }
            //console.log(reqData);
            let resData = await postData('/api/tickets/ticket/', reqData)
            //console.log(resData)
            if (resData['data']['code'] === 200) {
                if (remarks) {
                    let reqCommentData = {
                        'ticket_id': dbTicketId,
                        'message': remarks
                    }
                    //console.log(reqCommentData)
                    let resCommentData = await postData('/api/tickets/comment/', reqCommentData)
                    //console.log("This is data")
                    ////console.log(resCommentData)
                    if (resCommentData['data']['code'] === 200) {
                        //console.log("Thicket comment saved");
                    }
                    else {
                        //setErrorMessages({ name: "uname", message: errors.uname });
                    }
                }
                setLoading(false);
                setShowModal(true);
                //console.log("This is good example");
                window.location.href = '/user/dashboard';
            }
            else {
                //setErrorMessages({ name: "uname", message: errors.uname });
            }

        }
        else {
            setLoading(false);
            setShowAlert(true);
        }


    };

    const saveRemarks = async (event) => {
        //Prevent page reload
        setLoading(true);
        event.preventDefault();

        if (remarks) {
            let reqCommentData = {
                'ticket_id': dbTicketId,
                'message': remarks
            }
            //console.log(reqCommentData)
            let resCommentData = await postData('/api/tickets/comment/', reqCommentData)
            //console.log("This is data")
            ////console.log(resCommentData)
            if (resCommentData['data']['code'] === 200) {
                //console.log("Thicket comment saved");
            }
            else {
                //setErrorMessages({ name: "uname", message: errors.uname });
            }
        }
        setLoading(false);
        setShowModal(true);
        //console.log("This is good example");
        window.location.reload();



    };

    const handleModelClose = async (event) => {
        setShowModal(false);
        window.location.href = '/user/dashboard';
    }

    return (
        <>

            {loading ? (
                <LoadingSpinner></LoadingSpinner>
            ) : (<>
                {showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-sm">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Thank you for your response.
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => handleModelClose}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                            Your ticket has been created. Ticket Progress will be updated through email.
                                            Please check the ticket status after sometime.
                                        </p>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => handleModelClose}
                                        >
                                            Close
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                ) : (<>

                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">My Request</h6>

                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Requester
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Ticket ID
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketId}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Requesting For
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketType}
                                            disabled
                                        />
                                    </div>
                                </div>

                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Baisc Details
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Mode
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketMode}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Priority
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketPriority}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Approver Email
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketNotify}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Engineer
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketAssignedTo}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Status
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketStatus}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Anydesk Id
                                        </label>
                                        <input
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketConnectionId}
                                            name="notify_to"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Requirement Specific
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text" name="subject"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            defaultValue={ticketTitle}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {ticketType === 'GL Code Creation/Alteration' ?
                                    (<><h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        GL Code Form
                                    </h6>
                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Business
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.business : ''}
                                                        disabled
                                                        name="business"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        GL code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.glcode : ''}
                                                        disabled
                                                        name="glcode"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        GL Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.glname : ''}
                                                        disabled
                                                        name="glname"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Group
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.group : ''}
                                                        disabled
                                                        name="group"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Bill Wise Details Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.bwda : ''}
                                                        disabled
                                                        name="bwda"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Cost Centre Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.cca : ''}
                                                        disabled
                                                        name="cca"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Inventory Applicable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={glData ? glData.inv : ''}
                                                        disabled
                                                        name="inv"
                                                    />
                                                </div>
                                            </div>
                                        </div></>) : ''}
                                {ticketType === 'HSN Creation' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        HSN Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Entity Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.business : ''}
                                                    disabled
                                                    name="hsnbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    SAC/HSN Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.sac_hsn_code : ''}
                                                    disabled
                                                    name="sac_hsn_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.description : ''}
                                                    disabled
                                                    name="description"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Under
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.under : ''}
                                                    disabled
                                                    name="under"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Category
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.category : ''}
                                                    disabled
                                                    name="category"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Units
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.units : ''}
                                                    disabled
                                                    name="units"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.gst : ''}
                                                    disabled
                                                    name="gst"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Set Alter GST details
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.set_alter_gst_details : ''}
                                                    disabled
                                                    name="set_alter_gst_details"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Calculation Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.calculation_type : ''}
                                                    disabled
                                                    name="calculation_type"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Taxability
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.tax_ability : ''}
                                                    disabled
                                                    name="tax_ability"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Tax Type- Integrated
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.tax_type_integrated : ''}
                                                    disabled
                                                    name="tax_type_integrated"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Supply
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.type_of_supply : ''}
                                                    disabled
                                                    name="type_of_supply"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.type_of_ledger : ''}
                                                    disabled
                                                    name="type_of_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Purchase Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.purchase_ledger : ''}
                                                    disabled
                                                    name="purchase_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Credit Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnData ? hsnData.credit_type : ''}
                                                    disabled
                                                    name="credit_type"
                                                />
                                            </div>
                                        </div>

                                    </div></>) : ''}
                                {ticketType === 'HSN Alteration' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        HSN Alteration Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Entity Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.business : ''}
                                                    disabled
                                                    name="hsnbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    SAC/HSN Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.sac_hsn_code : ''}
                                                    disabled
                                                    name="sac_hsn_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Type of Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.type_of_ledger : ''}
                                                    disabled
                                                    name="type_of_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Purchase Ledger
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.purchase_ledger : ''}
                                                    disabled
                                                    name="purchase_ledger"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Credit Type
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={hsnAltData ? hsnAltData.credit_type : ''}
                                                    disabled
                                                    name="credit_type"
                                                />
                                            </div>
                                        </div>

                                    </div></>) : ''}
                                {ticketType === 'Vendor Creation' ? (<>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        Vendor Creation Form
                                    </h6>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Business
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.business : ''}
                                                    disabled
                                                    name="vendorbusiness"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GL code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gl_code : ''}
                                                    disabled
                                                    name="gl_code"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GL Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gl_name : ''}
                                                    disabled
                                                    name="gl_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Main Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.main_group : ''}
                                                    disabled
                                                    name="main_group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.group : ''}
                                                    disabled
                                                    name="group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Sub Group
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.sub_group : ''}
                                                    disabled
                                                    name="sub_group"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Contact Person Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.contact_person : ''}
                                                    disabled
                                                    name="contact_person"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Contact No
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.contact_number : ''}
                                                    disabled
                                                    name="contact_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Email ID
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.email_id : ''}
                                                    disabled
                                                    name="email_id"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Address of the Vendor
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vendor_address : ''}
                                                    disabled
                                                    name="vendor_address"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.city : ''}
                                                    disabled
                                                    name="city"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    PIN
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.pin : ''}
                                                    disabled
                                                    name="pin"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    State
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.state : ''}
                                                    disabled
                                                    name="state"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Registered/ Unregistered under GST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.is_gst_registered : ''}
                                                    disabled
                                                    name="is_gst_registered"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST No.
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gst_number : ''}
                                                    disabled
                                                    name="gst_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    GST State
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.gst_state : ''}
                                                    disabled
                                                    name="gst_state"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    PAN No.
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.pan_number : ''}
                                                    disabled
                                                    name="pan_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_name : ''}
                                                    disabled
                                                    name="bank_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Address
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_address : ''}
                                                    disabled
                                                    name="bank_address"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank Account No
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_account_number : ''}
                                                    disabled
                                                    name="bank_account_number"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bank IFSC
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.bank_ifsc : ''}
                                                    disabled
                                                    name="bank_ifsc"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Bill Wise Details Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vbwda : ''}
                                                    disabled
                                                    name="vbwda"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Cost Centre Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vcca : ''}
                                                    disabled
                                                    name="vcca"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Inventory Applicable
                                                </label>

                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={vendorData ? vendorData.vinv : ''}
                                                    disabled
                                                    name="vinv"
                                                />
                                            </div>
                                        </div>
                                    </div></>) : ''}
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Description
                                        </label>
                                        <SunEditor name="description" height="250px" defaultValue={ticketDescription} disable />

                                    </div>
                                </div>

                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Attachments
                            </h6>
                            <div className="flex flex-wrap">


                                {dataUrls.map((file, index) => (
                                    <div key={index} className="preview w-full lg:w-3/12 px-4">
                                        <img src={file} />

                                    </div>
                                ))}

                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Remarks
                            </h6>
                            <div className="flex flex-wrap">

                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Remarks
                                        </label>
                                        {ticketComments ? ticketComments.map((item, index) => (
                                            <><label><b>{item.created_by}: </b> {formatDate(item.created_on)}</label>
                                                <p
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >{item.comment}</p><br></br></>
                                        )) : ''}
                                        {(ticketStatus!='Resolved')? (<>
                                        <div className="flex flex-wrap">
                                            <textarea
                                                type="text" name="remarks"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                rows="4"
                                                onChange={v => setRemarks(v.target.value)}
                                                defaultValue={remarks}


                                            ></textarea><br></br>
                                        </div>
                                        <div className="flex flex-wrap mt-6">
                                            <div className="w-full lg:w-3/12 px-4">
                                                <button
                                                    className="bg-blueGray-800 text-center text-white active:bg-blueGray-800 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                    onClick={saveRemarks}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                        </>) : ''}
                                    </div>
                                    
                                </div>
                            </div>

                            {isReopened ? (<>
                                <hr className="mt-6 border-b-1 border-blueGray-300" />

                                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    Remarks
                                    {showAlert ? (
                                        <div
                                            className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
                                        >
                                            <span className="text-xl inline-block mr-5 align-middle">
                                                <i className="fas fa-bell" />
                                            </span>
                                            <span className="inline-block align-middle mr-8">
                                                <b className="capitalize">Error !</b> Request you to please provide the remarks for reopening the request.
                                            </span>
                                            <button
                                                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                                                onClick={() => setShowAlert(false)}
                                            >
                                                <span>×</span>
                                            </button>
                                        </div>
                                    ) : null}
                                </h6>
                                <div className="flex flex-wrap">
                                    <textarea
                                        type="text" name="remarks"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        rows="4"
                                        onChange={v => setRemarks(v.target.value)}
                                        defaultValue={remarks}


                                    ></textarea><br></br>
                                </div>

                                <div className="flex flex-wrap mt-6">
                                    <div className="w-full lg:w-3/12 px-4">
                                        <button
                                            className="bg-blueGray-800 text-center text-white active:bg-blueGray-800 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            onClick={reponeTicket}
                                        >
                                            Reopen
                                        </button>
                                    </div>

                                </div>
                            </>) : ''}


                        </div>


                    </div></>)
                }
            </>)}
        </>);
}

import React from "react";
import { Chart, PieController, ArcElement, Legend, Tooltip, Title } from 'chart.js';

Chart.register(PieController, ArcElement, Title, Legend, Tooltip);

export default function CardRingChart({ monthlyData }) {
  console.log(monthlyData);
  React.useEffect(() => {
    const mappedData = Array.from({ length: 12 }, (_, index) => {
      const monthData = monthlyData.find(item => item.m === (index + 1));
      return monthData ? monthData.total : 0;
    });

    let config = {
      type: "doughnut",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
          {
            label: "Total Tickets",
            backgroundColor: [
              "#FFD700", // Gold              
              "#8A2BE2", // Blue Violet
              "#87CEEB", // Sky Blue
              "#FF4500", // Orange Red
              "#00FF7F", // Spring Green
              "#1E90FF", // Dodger Blue
              "#00CED1", // Dark Turquoise
              "#ADFF2F", // Green Yellow
              "#8B008B", // Dark Magenta
              "#FFA07A", // Light Salmon
              "#FF1493", // Deep Pink
              "#32CD32", // Lime Green
            ],
            data: mappedData,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Tickets Overview",
        },
        legend: {
          position: "bottom",
        },
      },
    };

    let ctx = document.getElementById("ring-chart").getContext("2d");
    window.myDoughnut = new Chart(ctx, config);
  }, [monthlyData]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Monthly Data
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Tickets Overview
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px mb-3">
            <canvas id="ring-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/Loadder";
import { getData, postData, postFormData } from "../../../../serverRequest";
import Select from 'react-select';
import { Category } from "@mui/icons-material";

export default function ReimbursementForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [appliedDate] = useState();
  const [reimbursementDate, setReimbursementDate] = useState();
  const [reimbursementType, setReimbursementType] = useState();
  const [natureOfExpense, setNatureOfExpense] = useState(); // New state variable
  const [reimbursementAmount, setReimbursementAmount] = useState();
  const [description, setDescription] = useState();
  const [status] = useState('New');
  const [selectedImages, setSelectedImages] = useState([]);
  const [categories, setCategories] = useState([]);


  const handleImageChange = (e) => {
    const files = e.target.files;
    const updatedImages = [...selectedImages];

    for (const file of files) {
      updatedImages.push(file);
    }

    setSelectedImages(updatedImages);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    for (const file of selectedImages) {
      formData.append('images', file);
    }

    try {
      let token = '';
      // const cookiest = document.cookie
      const cookies = document.cookie.split(';');
      //console.log(cookies);
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('token=')) {
          token = cookie.substring(6);
        }
      }

      const response = await postFormData('/api/tickets/upload_file/', formData);
      // const response = await axios.post('http://localhost:8000/api/tickets/upload_file/', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     'Authorization': token,
      //   },
      // });
      //console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);



    const formData = {
      'employeeId': localStorage.getItem('employeeId'),
      'reimbursementDate': reimbursementDate ? reimbursementDate : '',
      'reimbursementType': reimbursementType ? reimbursementType : '',
      'natureOfExpense': natureOfExpense ? natureOfExpense : '', // New field
      'reimbursementAmount': reimbursementAmount ? reimbursementAmount : '',
      'description': description ? description : '',
      'status': status ? status : 'N',
    };
    console.log(formData);

    try {
      const res = await postData("/api/reimbursement/", formData);

      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = '/admin/admins';
      } else {
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const formatCategories = (category_data) => {
    let categories_data = [];
    if (category_data) {
      for (let i = 0; i < category_data.length; i++) {
        categories_data.push({ value: category_data[i]['id'], label: category_data[i]['name'] })
      }

    }
    return categories_data;
  }

  useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/reimbursement/category/');
      let categoryData = resData['data']['categories'];
      if (resData['data']['code'] === '200' && categoryData.length > 0) {
        let dataSet = await formatCategories(categoryData);
        setCategories(dataSet);
      }

      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="admin-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Reimbursement Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
              {/* <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="appliedDate">Applied Date</label>
                  <input type="date"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="appliedDate" defaultValue={appliedDate} />
                </div>
              </div> */}
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="reimbursementDate">Reimbursement Date</label>
                  <input type="date"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="reimbursementDate" defaultValue={reimbursementDate} onChange={(e) => setReimbursementDate(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="reimbursementType">Reimbursement Type</label>
                  <Select
                    name="ticket_type"
                    options={categories}
                    defaultValue={reimbursementType}
                    onChange={(selectedOption) => setReimbursementType(selectedOption.value)}
                  />
                  {/* <select 
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={reimbursementType} name="reimbursementType" onChange={(e) => setReimbursementType(e.target.value)}>
                <option value="">Select Type</option>
                <option value="type_1">Type 1</option>
                <option value="type_2">Type 2</option>
                </select> */}
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="natureOfExpense">Nature of Expense</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="natureOfExpense" defaultValue={natureOfExpense} onChange={(e) => setNatureOfExpense(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="reimbursementAmount">Reimbursement Amount</label>
                  <input type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="reimbursementAmount" defaultValue={reimbursementAmount} onChange={(e) => setReimbursementAmount(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="description">Description</label>
                  <textarea
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="description" defaultValue={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
              </div>
              {/* <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="status">Status</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="status" defaultValue={status} />
                </div>
              </div> */}
            </div>
            <div className="px-4">
              <h2>Image Upload</h2>
              <input type="file" multiple onChange={handleImageChange} />
              <div>
                {selectedImages.map((image, index) => (
                  <div key={index}>
                    <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }} />
                    <button onClick={() => handleRemoveImage(index)}>Remove</button>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
              <input
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                type="Submit"
                value="Submit"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { getData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";
// components
//import { categories } from "../../components/data/data.js";

export default function ServiceType() {
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = useState(true);

  const formatCategory = (category_data) => {
    let categoryDatas = [];
    if (category_data) {
      for (let i = 0; i < category_data.length; i++) {
        let href = '/user/serviceList?sl=' + category_data[i]['id']
        categoryDatas.push({ label: category_data[i]['category'], href: href, current: true, description: '', imagelink: '', icon: 'fa fa-laptop' })
      }
    }

    return categoryDatas
  }

  React.useEffect(() => {

    async function fetchData() {
      let resData = await getData('/api/company/ticket_category/')
      //console.log(resData);
      let category_data = resData['data']
      //console.log(category_data);
      if (category_data['data']) {
        let TicketData = await formatCategory(category_data['data'])
        setCategories(TicketData)
        //console.log(categories);
        setLoading(false);
      }
    }
    fetchData();
  }, [])
  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div className="relative bg-lightBlue-800 pb-32 pt-12">
          <div className="flex flex-wrap py-1 justify-center">
            <div className="w-full lg:w-6/12 xl:w-4/12 px-4 item-center">
              <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                <div className="relative flex w-full flex-wrap items-stretch">
                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search your issue here"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                  />
                </div>
              </form>
            </div>

          </div>
          <div className="px-4 md:px-10 mx-auto w-full">
            <div></div>

            <div className="flex flex-wrap">
              {categories.map((item) => (
                <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"><a href={item.href}>
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <h6 className="text-xl font-semibold">{item.label}</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        {item.description}
                      </p>
                    </div>
                  </div></a>
                </div>
              ))}
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"><a href='/user/newRequest?tt=51'>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-xl font-semibold">Others</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">

                    </p>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </>)}
    </>
  );
}

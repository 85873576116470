import React, { useState } from "react";
import Select from 'react-select';
import { anydeskRequiredTypes, hsnUnits, hsnCategory, hsnGST, hsnTaxabilityType, hsnCreditType, optionSelect, approvalRequiredTypes } from '../data/data';
import { getData, postData, postFormData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";
// import Dropzone from 'react-dropzone';
// import axios from 'axios';
import { Document, Page } from 'react-pdf';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function TicketForm(props) {

  const [showModal, setShowModal] = React.useState(false);
  //const [email, setEmail] = useState("demo@gmail.com");
  const [requestType, setRequestType] = useState();
  const [ticketType, setTicketType] = React.useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedFiles, setSelectedFiles] = useState([]);

  // const [filePreviews, setFilePreviews] = useState([]);
  const [engineerlist, setEngineerlist] = useState([]);
  const [approverlist, setApproverlist] = useState([]);
  // const [message, setMessage] = useState('');
  const [ticketFor, setTicketFor] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  // const [previews, setPreviews] = useState([]);
  const [isRequiredApproval, setIsRequiredApproval] = useState(false);
  const [isAmountReq, setIsAmountReq] = useState(false);
  const [description, setDescription] = useState('');
  const [isAnydeskRequired, setIsAnydeskRequired] = useState(false);

  const handleImageChange = (e) => {
    const files = e.target.files;
    const updatedImages = [...selectedImages];

    for (const file of files) {
      updatedImages.push(file);
    }

    setSelectedImages(updatedImages);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const handleUpload = async () => {
    const formData = new FormData();

    for (const file of selectedImages) {
      formData.append('images', file);
    }

    try {
      let token = '';
      // const cookiest = document.cookie;
      const cookies = document.cookie.split(';');
      //console.log(cookies);
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('token=')) {
          token = cookie.substring(6);
        }
      }

      const response = await postFormData('/api/tickets/upload_file/', formData)
      // const response = await axios.post('http://localhost:8000/api/tickets/upload_file/', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     'Authorization': token,
      //   },
      // });
      //console.log(response.data);
      return response.data
    } catch (error) {
      console.error(error);
    }
  };

  // const handleDrop = (acceptedFiles) => {
  //   setSelectedFiles([...selectedFiles, ...acceptedFiles]);
  // };

  // const handleRemove = (file) => {
  //   setSelectedFiles(selectedFiles.filter((f) => f !== file));
  // };


  const formatTicketType = (ticket_type_data) => {
    let ticket_type_datas = [];
    if (ticket_type_data) {
      for (let i = 0; i < ticket_type_data.length; i++) {
        let href = '/user/newRequest?tt=' + ticket_type_data[i]['id']
        ticket_type_datas.push({ value: ticket_type_data[i]['id'], label: ticket_type_data[i]['type'], href: href, current: true, description: '', imagelink: '', icon: 'fa fa-laptop' })
      }
    }

    return ticket_type_datas
  }

  const formatAdminList = (admin_list) => {
    let admin_list_data = [];
    if (admin_list) {
      for (let i = 0; i < admin_list.length; i++) {
        admin_list_data.push({ value: admin_list[i]['id'], label: admin_list[i]['employee_first_name'] })
      }
    }

    return admin_list_data
  }

  const formatApproverList = (approver_list) => {
    let approver_list_data = [];
    if (approver_list) {
      for (let i = 0; i < approver_list.length; i++) {
        approver_list_data.push({ value: approver_list[i]['employee_email'], label: approver_list[i]['employee_first_name'] })
      }
    }

    return approver_list_data
  }
  const handleChange = (event) => {
    //console.log("I am here");
    //console.log(event); //Get Content Inside Editor
    setDescription(event);
  }
  React.useEffect(() => {
    async function fetchData() {
      let url = '/api/company/ticket_type/';
      let resData = await getData(url)
      let ticket_type_data = resData['data'];

      if (ticket_type_data['data']) {
        let ticketTypeData = await formatTicketType(ticket_type_data['data'])
        //console.log(ticketTypeData);
        setTicketType(ticketTypeData)
        const sublistData = await ticketTypeData.filter(item => item.value == props.sublist)
        //console.log(sublistData);
        setRequestType(sublistData)
        if(sublistData.length>0){
          setTicketFor(sublistData[0]['label'])
        }
        if (approvalRequiredTypes.includes(sublistData[0]['label'])) {
          setIsRequiredApproval(true);
        }
        //console.log(ticketFor);
        //console.log(ticketFor);
        
        if(anydeskRequiredTypes.indexOf(ticketFor) > -1){
          setIsAnydeskRequired(true)
          //console.log(isAnydeskRequired);
        }
        
        let company_id = localStorage.getItem('companyId')
        let url = '/api/employee/admins/?company_id=' + company_id;
        let resData = await getData(url)
        let admins_data = resData['data']
        //console.log(admins_data);
        if (admins_data['admins']) {
          let adminsData = await formatAdminList(admins_data['admins'])
          setEngineerlist(adminsData)
        }
        if (admins_data['approvers']) {
          console.log(admins_data['approvers']);
          let approverData = await formatApproverList(admins_data['approvers'])
          setApproverlist(approverData)
        }
        setLoading(false);
      }
    }
    fetchData();
  }, [])
  const handleSubmit = async (event) => {
    //Prevent page reload
    setLoading(true);
    event.preventDefault();
    let { ticket_type, mode, priority, location, approver_id, assigned_to, subject,
      anydesk_id, business, glcode, glname, group, bwda, cca, inv, hsnbusiness, sac_hsn_code,
      hsndescription, under, category, units, gst, set_alter_gst_details,
      calculation_type, tax_ability, tax_type_integrated, type_of_supply,
      type_of_ledger, purchase_ledger, credit_type, vendorbusiness, gl_code, gl_name,
      main_group, vgroup, sub_group, contact_person, contact_number, email_id, vendor_address,
      city, pin, state, is_gst_registered, gst_number,
      gst_state, pan_number, bank_name, bank_address,
      bank_account_number, bank_ifsc, vbwda, vcca, vinv,
      hsnaltbusiness, sac_hsn_alt_code, alt_type_of_ledger, alt_purchase_ledger,
      alt_credit_type, accounting_allocation, cc_mail_ids, plimpact, amount } = document.forms[0];
    let reqData = {
      'ticket_type': ticket_type.value,
      'description': description ? description : '',
      'assigned_to_id': assigned_to ? assigned_to.value : '',
      'mode': mode ? mode.value : '',
      'priority': priority ? priority.value : '',
      'approver_id': approver_id ? approver_id.value : '',
      'location': location ? location.value : '',
      'title': subject ? subject.value : '',
      'ticket_type_id': ticket_type.value,
      'ticket_status_id': 4,
      'attachments': '',
      'connection_id': anydesk_id ? anydesk_id.value : '',
      'cc_mail_ids': cc_mail_ids ? cc_mail_ids.value : '',
    }
    if (ticketFor === 'GL Code Creation/Alteration') {
      reqData['gl_code_data'] = {
        'business': business ? business.value : '',
        'glcode': glcode ? glcode.value : '',
        'glname': glname ? glname.value : '',
        'group': group ? group.value : '',
        'bwda': bwda ? bwda.value : '',
        'cca': cca ? cca.value : '',
        'inv': inv ? inv.value : ''
      }
    }
    else if (ticketFor === 'HSN Creation') {
      reqData['hsn_data'] = {
        'business': hsnbusiness ? hsnbusiness.value : '',
        'sac_hsn_code': sac_hsn_code ? sac_hsn_code.value : '',
        'description': hsndescription ? hsndescription.value : '',
        'under': under ? under.value : '',
        'category': category ? category.value : '',
        'units': units ? units.value : '',
        'gst': gst ? gst.value : '',
        'set_alter_gst_details': set_alter_gst_details ? set_alter_gst_details.value : '',
        'calculation_type': calculation_type ? calculation_type.value : '',
        'tax_ability': tax_ability ? tax_ability.value : '',
        'tax_type_integrated': tax_type_integrated ? tax_type_integrated.value : '',
        'type_of_supply': type_of_supply ? type_of_supply.value : '',
        'type_of_ledger': type_of_ledger ? type_of_ledger.value : '',
        'purchase_ledger': purchase_ledger ? purchase_ledger.value : '',
        'credit_type': credit_type ? credit_type.value : ''
      }
    }
    else if (ticketFor === 'HSN Alteration') {
      reqData['hsn_alt_data'] = {
        'business': hsnaltbusiness ? hsnaltbusiness.value : '',
        'sac_hsn_alt_code': sac_hsn_alt_code ? sac_hsn_alt_code.value : '',
        'type_of_ledger': alt_type_of_ledger ? alt_type_of_ledger.value : '',
        'purchase_ledger': alt_purchase_ledger ? alt_purchase_ledger.value : '',
        'credit_type': alt_credit_type ? alt_credit_type.value : '',
      }
    }
    else if (ticketFor === 'Vendor Creation') {
      reqData['vendor_data'] = {
        'business': vendorbusiness ? vendorbusiness.value : '',
        'gl_code': gl_code ? gl_code.value : '',
        'gl_name': gl_name ? gl_name.value : '',
        'main_group': main_group ? main_group.value : '',
        'group': vgroup ? vgroup.value : '',
        'sub_group': sub_group ? sub_group.value : '',
        'contact_person': contact_person ? contact_person.value : '',
        'contact_number': contact_number ? contact_number.value : '',
        'email_id': email_id ? email_id.value : '',
        'vendor_address': vendor_address ? vendor_address.value : '',
        'city': city ? city.value : '',
        'pin': pin ? pin.value : '',
        'state': state ? state.value : '',
        'is_gst_registered': is_gst_registered ? is_gst_registered.value : '',
        'gst_number': gst_number ? gst_number.value : '',
        'gst_state': gst_state ? gst_state.value : '',
        'pan_number': pan_number ? pan_number.value : '',
        'bank_name': bank_name ? bank_name.value : '',
        'bank_address': bank_address ? bank_address.value : '',
        'bank_account_number': bank_account_number ? bank_account_number.value : '',
        'bank_ifsc': bank_ifsc ? bank_ifsc.value : '',
        'vbwda': vbwda ? vbwda.value : '',
        'vcca': vcca ? vcca.value : '',
        'vinv': vinv ? vinv.value : ''
      }
    }
    else if (ticketFor === 'Book Open/Close request') {
      reqData['other_data'] = {
        'p_n_l_impact': plimpact ? plimpact.value : '',
        'amount': amount ? amount.value : '',
      }
    }
    //console.log(reqData);

    if (selectedImages.length > 0) {
      let uploadedImage = await handleUpload();
      if (uploadedImage && uploadedImage.uploaded_images) {
        reqData['attachments'] = uploadedImage.uploaded_images
      }
    }



    //console.log(reqData);
    let resData = await postData('/api/tickets/ticket/', reqData)
    //console.log("This is data")
    //console.log(resData)
    if (resData['data']['code'] === 200) {
      setLoading(false);
      setShowModal(true);
      //console.log("This is good example");
      window.location.href = '/user/dashboard';
    }
    else {
      //setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  const handleModelClose = async (event) => {
    setShowModal(false);
    window.location.href = '/user/dashboard';
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      Thank you for connecting bizallen support team.
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => handleModelClose}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                      Your ticket has been created. Ticket Progress will be updated through email.
                      Please check the ticket status after sometime.
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleModelClose}
                    >
                      Close
                    </button>

                  </div>
                </div>
              </div>
            </div>

          </>
        ) :
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">My Request</h6>

              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Requester
                </h6>
                <div className="flex flex-wrap">
                  {/* <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={email}
                  disabled
                />
              </div>
            </div> */}
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Requesting For
                      </label>
                      <Select
                        name="ticket_type"
                        options={ticketType}
                        defaultValue={requestType}
                        onChange={setRequestType}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4">
                    <div className={isAnydeskRequired?"relative w-full mb-3 required":"relative w-full mb-3"}>
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="text"
                      >
                        Anydesk ID
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 required"
                        readOnly={isAnydeskRequired?true:false}
                        name='anydesk_id'
                      />
                    </div>
                  </div>

                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Basic Details
                </h6>
                <div className="flex flex-wrap">
                  {isRequiredApproval ? (<>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className={ticketFor === 'Others' ? "relative w-full mb-3" : "relative w-full mb-3 required"}>
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Approver
                        </label>
                        <Select name="approver_id" required={ticketFor === 'Others' ? false : true}
                          options={approverlist}  />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          CC Email
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue=""
                          name="cc_mail_ids"
                        />
                      </div>
                    </div>
                  </>)
                    : ''}

                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Select Engineer
                      </label>
                      <Select name="assigned_to" required={true}
                        options={engineerlist} />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Requirement Specific
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3 required">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Subject
                      </label>
                      <input
                        type="text" name="subject"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 required"
                        defaultValue=""
                        required
                      />
                    </div>
                  </div>
                  {props.sublist === '37' ?
                    (<><h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      GL Code Form
                    </h6>
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3 required">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Business
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue=""
                              name="business"
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3 required">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              GL code
                            </label>
                            <input
                              type="number"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 required"
                              defaultValue=""
                              name="glcode"
                              min="100000" max="999999" maxLength={6}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              GL Name
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue=""
                              name="glname"
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Group
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              defaultValue=""
                              name="group"
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Bill Wise Details Applicable
                            </label>
                            <Select name="bwda" options={optionSelect} />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Cost Centre Applicable
                            </label>
                            <Select name="cca" options={optionSelect} />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Inventory Applicable
                            </label>
                            <Select name="inv" options={optionSelect} />
                          </div>
                        </div>
                      </div></>) : ''}
                  {props.sublist === '38' ? (<>
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      HSN Form
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Entity Business
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="hsnbusiness"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            SAC/HSN Code
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="sac_hsn_code"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Description
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="hsndescription"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Under
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="under"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Category
                          </label>
                          <Select name="category" options={hsnCategory} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Units
                          </label>
                          <Select name="units" options={hsnUnits} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            GST
                          </label>
                          <Select name="gst" options={hsnGST} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Set Alter GST details
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="set_alter_gst_details"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Calculation Type
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="calculation_type"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Taxability
                          </label>
                          <Select name="tax_ability" options={hsnTaxabilityType} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Tax Type- Integrated
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="tax_type_integrated"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Type of Supply
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="type_of_supply"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Type of Ledger
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="type_of_ledger"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Purchase Ledger
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="purchase_ledger"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Credit Type
                          </label>
                          <Select name="credit_type" options={hsnCreditType} />
                        </div>
                      </div>

                    </div></>) : ''}
                  {props.sublist === '52' ? (<>
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      HSN Alteration Form
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Entity Business
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="hsnaltbusiness"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            SAC/HSN Code
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="sac_hsn_alt_code"
                          />
                        </div>
                      </div>
                      {/* <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Accounting allocation
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="accounting_allocation"
                          />
                        </div>
                      </div> */}
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Type of Ledger
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="alt_type_of_ledger"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Purchase Ledger
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="alt_purchase_ledger"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Credit Type
                          </label>
                          <Select name="alt_credit_type" options={hsnCreditType} />
                        </div>
                      </div>

                    </div></>) : ''}
                  {props.sublist === '44' ? (<>
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      Vendor Creation Form
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Business
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="vendorbusiness"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            GL code
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="gl_code"
                            min="10000000" max="999999999" maxLength={9}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            GL Name
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="gl_name"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Main Group
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="main_group"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Group
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="vgroup"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Sub Group
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="sub_group"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Contact Person Name
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="contact_person"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Contact No
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="contact_number"
                            min="1000000000" max="9999999999" maxLength={10}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Email ID
                          </label>
                          <input
                            type="email"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="email_id"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Address of the Vendor
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="vendor_address"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="city"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            PIN
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="pin"
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="state"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Registered/ Unregistered under GST
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="is_gst_registered"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            GST No.
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="gst_number"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            GST State
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="gst_state"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            PAN No.
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="pan_number"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Bank Name
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="bank_name"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Bank Address
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="bank_address"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Bank Account No
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="bank_account_number"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Bank IFSC
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            defaultValue=""
                            name="bank_ifsc"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Bill Wise Details Applicable
                          </label>
                          <Select name="vbwda" options={optionSelect} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Cost Centre Applicable
                          </label>
                          <Select name="vcca" options={optionSelect} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Inventory Applicable
                          </label>
                          <Select name="vinv" options={optionSelect} />
                        </div>
                      </div>
                    </div></>) : ''}
                  {props.sublist === '43' ? (<>
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            P&L Impact
                          </label>
                          <Select name="plimpact" options={optionSelect}
                            onChange={v => setIsAmountReq(v.value === 'Yes' ? true : false)} />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3 required">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Amount
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 required" 
                            defaultValue=""
                            name="amount"
                            required={isAmountReq}
                          />
                        </div>
                      </div>

                    </div></>) : ''}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className={props.sublist === '43' ? "relative w-full mb-3 required" : "relative w-full mb-3"}>
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Description
                      </label>
                      <SunEditor name="description" height="250px" onChange={handleChange} required={props.sublist === '43' ? true : false} />

                    </div>
                  </div>
                  <div>
                    <h2>Image Upload</h2>
                    <input type="file" multiple onChange={handleImageChange} />
                    <div>
                      {selectedImages.map((image, index) => (
                        <div key={index}>
                          <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }} />
                          <button onClick={() => handleRemoveImage(index)}>Remove</button>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>

                <div className="text-center mt-6 lg:w-3/12 px-4">
                  <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"
                  >

                  </input>
                </div>
              </form>

            </div>

          </div>}</>)
      }
    </>
  );
}
const PdfPreview = ({ fileUrl }) => {
  //console.log(fileUrl);
  return (
    <div>
      <Document file={fileUrl}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};




import React from 'react';

import moment from 'moment';

//Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
const formatDate = (dateString) => {
  return moment(dateString).format('DD/MM/YYYY');
};

const formatTicketId = (ticketId) => {
  return ticketId.toUpperCase();
};

class UserSolutionsTable extends React.Component {
  componentDidMount() {

    //initialize datatable
    $(document).ready(function () {
      var sort_col = $('#userSolutions').find("th:contains('ID')")[0].cellIndex;
      $('#userSolutions').DataTable({
        order: [[sort_col, 'desc']],
        columnDefs: [
          {
            target: 0,
            visible: false,
            searchable: false
          }
        ],
      });
    });
  }
  render() {
    //Datatable HTML
    return (




      <table id="userSolutions" class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              ID
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Ticket ID
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                ("light" === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
              }
            >
              Entity
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Ticket Title
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Ticket Type
            </th>
            {/* <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Ticket Mode
            </th> */}
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                ("light" === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
              }
            >
              Created By
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Created On
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Assigned To
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Priority
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Status
            </th>

          </tr>
        </thead>
        <tbody>
          {this.props.totalTickets.map((item, index) => (
            <tr>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.id}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a style={{ "light": '#0074cd' }} href={'/user/ticket/?ticket_id=' + item.user_ticket_id}>{formatTicketId(item.user_ticket_id)}</a>
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.company}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.title}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.ticket_type}
              </td>
              {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.mode}
              </td> */}
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.created_by}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {formatDate(item.created_on)}
              </td>

              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.assigned_to}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.priority}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" 
              style={{backgroundColor:                 
                item.status === 'Assigned' ? '#E0F2FE' :
                item.status === 'IN Progress' ? '#E6E6FA' :
                item.status === 'Approval Pending' ? '#FFF9CC' :
                item.status === 'Approved' ? '#FFEFD5' :
                item.status === 'Rejected' ? '#FADBD8' :
                item.status === 'Resolved' ? '#BDF3D8' :'#FFFFFF'}}>                
               {item.status === 'Assigned' ? <i className="fas fa-circle mr-4" style={{color: '#5BC0DE'}}></i> :
                item.status === 'IN Progress' ? <i className="fas fa-circle mr-4" style={{color: '#800080'}}></i> :
                item.status === 'Approval Pending' ? <i className="fas fa-circle mr-4" style={{color: '#FFFF00'}}></i> :
                item.status === 'Approved' ? <i className="fas fa-circle mr-4" style={{color: '#FFA500'}}></i> :
                item.status === 'Rejected' ? <i className="fas fa-circle mr-4" style={{color: '#FF0000'}}></i> :
                item.status === 'Resolved' ? <i className="fas fa-circle mr-4" style={{color: '#00FF00'}}></i> :                
              <i className="fas fa-circle mr-4" style={{color: '#D3D3D3'}}></i>}{item.status}
              </td>

            </tr>
          ))}
        </tbody>

      </table>



    );
  }
}
export default UserSolutionsTable;
import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function TallyData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const tallyId = searchParams.get('tally_id');
  const [showModal, setShowModal] = useState(false);
  const [entityName, setEntityName] = useState();
  const [owner, setOwner] = useState();
  const [backupSize, setBackupSize] = useState();
  const [sharedWith, setSharedWith] = useState();
  const [backupDate, setBackupDate] = useState();
  const [backupTime, setBackupTime] = useState();
  const [driveLink, setDriveLink] = useState();
  const [uploadedBy, setUploadedBy] = useState();
  const [remarks, setRemarks] = useState();

  const handleSubmit = async (event) => {
     // Prevent page reload
     setLoading(true);
     event.preventDefault();
     let { entity_name, owner, backup_size, shared_with, backup_date, backup_time, drive_link, uploaded_by, remarks } = document.forms[0];
     let reqData = {
       'tally_id': tallyId,
       'entity_name': entity_name ? entity_name.value : '',
       'owner': owner ? owner.value : '',
       'backup_size': backup_size ? backup_size.value : '',
       'shared_with': shared_with ? shared_with.value : '',
       'backup_date': backup_date ? backup_date.value : '',
       'backup_time': backup_time ? backup_time.value : '',
       'drive_link': drive_link ? drive_link.value : '',
       'uploaded_by': uploaded_by ? uploaded_by.value : '',
       'remarks': remarks ? remarks.value : ''
     }

     let resData = await postData('/api/tally/', reqData); 
     if (resData['data']['code'] === 200) {
       setLoading(false);
       setShowModal(true);
       window.location.href = '/admin/tally'; 
     }
   };

   useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/tally?admin_id=' + tallyId);
      let tally_data = resData['data']['tally'];
      console.log(tally_data);
      if(tally_data){
        setEntityName(tally_data['entity_name']);
        setOwner(tally_data['owner']);
        setBackupSize(tally_data['backup_size']);
        setSharedWith(tally_data['shared_with']);
        setBackupDate(tally_data['backup_date']);
        setBackupTime(tally_data['backup_time']);
        setDriveLink(tally_data['drive_link']);
        setUploadedBy(tally_data['uploaded_by']);
        setRemarks(tally_data['remarks']);
      }
     
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="tally-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Tally ID : {tallyId}</h6>
                <button className="cursor-pointer opacity-30 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"> {/*onClick={() => setIsEditable(isEditable?false:true)}*/} 
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="entity_name">Entity Name</label>
                  <input type="text"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="entity_name" defaultValue={entityName} />
                </div>
              </div>            
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="owner">Owner</label>
                  <input type="text"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="owner" defaultValue={owner} />
                </div>
              </div>            
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="backup_size">Backup Size</label>
                  <input type="text" 
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="backup_size" defaultValue={backupSize} />
                </div>
              </div>            
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="shared_with">Shared With</label>
                  <input type="text" 
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="shared_with" defaultValue={sharedWith} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="backup_date">Backup Date</label>
                  <input type="date" 
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="backup_date" defaultValue={backupDate} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="backup_time">Backup Time (24 hours)</label>
                  <input type="time"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="backup_time" defaultValue={backupTime} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="drive_link">Drive Link</label>
                  <input type="url"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="drive_link" defaultValue={driveLink} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="uploaded_by">Uploaded By</label>
                  <input type="text"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="uploaded_by" defaultValue={uploadedBy} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="remarks">Remarks</label>
                  <input type="text"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled
                         name="remarks" defaultValue={remarks} />
                </div>
              </div>
            </div>
            {/*{isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}*/}       
          </div>
        </form>
      )}
    </div>
  );
}

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views

import AdminDashboard from "../views/admin/AdminDashboard.js";
import AdminTickets from "./admin/tickets/AdminTickets.js";
import Ticket from "./admin/tickets/Ticket.js";
import AdminReport from "./admin/reports/AdminReport.js";
import CompanyReport from "./admin/reports/CompanyReport.js";
import FarReport from "./admin/reports/FarReport.js"
import WarrantyReport from "./admin/reports/WarrantyReport.js";
import AssignmentReport from "./admin/reports/AssignmentReport.js";
import SubscriptionReport from "./admin/reports/SubscriptionReport.js";
import AdminProfilePage from "./admin/AdminProfilePage.js";
import EntityList from "./admin/entity/EntityList.js";
import AdminList from "./admin/admins/AdminList.js";
import UserList from "./admin/users/UserList.js";
import AdminData from "./admin/admins/AdminData.js";
import EntityData from "./admin/entity/EntityData.js";
import UserData from "./admin/users/UserData.js";
import EntityForm from "./admin/entity/EntityForm.js";
import AdminForm from "./admin/admins/AdminForm.js";
import UserForm from "./admin/users/UserForm.js";
import TicketForm from "./admin/tickets/TicketForm.js";
import MonthlyTicketForm from "./admin/tickets/MonthlyTicketForm.js";
import AdminTasks from "./admin/tickets/AdminTasks.js";
import ProductList from "./admin/inventory/Product/ProductList.js";
import ProductAssignmentList from "./admin/inventory/ProductAssignment/ProductAssignmentList.js";
import ProductWarrantyList from "./admin/inventory/ProductWarranty/ProductWarrantyList.js";
import SubscriptionList from "./admin/inventory/Subscription/SubscriptionList.js";
import TimesheetList from "./admin/timesheet/TimesheetList.js";
import ReimbursementList from "./admin/company/reimbursement/ReimbursementList.js";
import TallyList from "./admin/company/tally/TallyList.js";
import IncidentList from "./admin/company/incident/IncidentList.js";
import ProductForm from "./admin/inventory/Product/ProductForm.js";
import ProductAssignmentForm from "./admin/inventory/ProductAssignment/ProductAssignmentForm.js";
import ProductWarrantyForm from "./admin/inventory/ProductWarranty/ProductWarrantyForm.js";
import SubscriptionForm from "./admin/inventory/Subscription/SubscriptionForm.js";
import TimesheetForm from "./admin/timesheet/TimesheetForm.js";
import ReimbursementForm from "./admin/company/reimbursement/ReimbursementForm.js";
import TallyForm from "./admin/company/tally/TallyForm.js";
import IncidentForm from "./admin/company/incident/IncidentForm.js";
import TallyData from "./admin/company/tally/TallyData.js";
import IncidentData from "./admin/company/incident/IncidentData.js";
import ProductData from "./admin/inventory/Product/ProductData.js";
import ProductAssignmentData from "./admin/inventory/ProductAssignment/ProductAssignmentData.js";
import ProductWarrantyData from "./admin/inventory/ProductWarranty/ProductWarrantyData.js";
import SubscriptionData from "./admin/inventory/Subscription/SubscriptionData.js";
import AdminFilterTickets from "./admin/tickets/AdminFilterTickets.js";
import Attendance from "./admin/Attendance.js";
import ProductsList from "./admin/inventories/products/ProductsList.js";
import SaleList from "./admin/inventories/sale/SaleList.js";
import StockList from "./admin/inventories/stock/StockList.js";
import ProductsForm from "./admin/inventories/products/ProductsForm.js";
import SaleForm from "./admin/inventories/sale/SaleForm.js";
import ProductsData from "./admin/inventories/products/ProductsData.js"; 
import SaleData from "./admin/inventories/sale/SaleData.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative overflow-x-hidden md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        
        <div className="relative md:pt-24 pt-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={AdminDashboard} />
            <Route path="/admin/tickets" exact component={AdminTickets} />
            <Route path="/admin/ticket" exact component={Ticket} />
            <Route path="/admin/tickets/filter" exact component={AdminFilterTickets} />
            <Route path="/admin/reports/adminReport" exact component={AdminReport} />
            <Route path="/admin/reports/companyReport" exact component={CompanyReport} />
            <Route path="/admin/reports/farReport" exact component={FarReport} />
            <Route path="/admin/reports/warrantyReport" exact component={WarrantyReport} />
            <Route path="/admin/reports/assignmentReport" exact component={AssignmentReport} />
            <Route path="/admin/reports/subscriptionReport" exact component={SubscriptionReport} />            
            <Route path="/admin/profile"exact component={AdminProfilePage} />
            <Route path="/admin/entities"exact component={EntityList} />
            <Route path="/admin/users"exact component={UserList} />
            <Route path="/admin/admins"exact component={AdminList} />
            <Route path="/admin/admin" exact component={AdminData} />
            <Route path="/admin/entity" exact component={EntityData} />
            <Route path="/admin/user" exact component={UserData} />
            <Route path="/admin/entityForm" exact component={EntityForm} />
            <Route path="/admin/adminForm" exact component={AdminForm} />
            <Route path="/admin/userForm" exact component={UserForm} />
            <Route path="/admin/ticketForm" exact component={TicketForm} />
            <Route path="/admin/monthlyTicketForm" exact component={MonthlyTicketForm} />
            <Route path="/admin/inventory/productForm" exact component={ProductForm} />
            <Route path="/admin/inventory/productAssignmentForm" exact component={ProductAssignmentForm} />
            <Route path="/admin/inventory/productWarrantyForm" exact component={ProductWarrantyForm} />
            <Route path="/admin/inventory/subscriptionForm" exact component={SubscriptionForm} />
            <Route path="/admin/timesheetForm" exact component={TimesheetForm} />
            <Route path="/admin/company/reimbursementForm" exact component={ReimbursementForm} />
            <Route path="/admin/company/tallyForm" exact component={TallyForm} />
            <Route path="/admin/company/IncidentForm" exact component={IncidentForm} />
            <Route path="/admin/company/tallyData" exact component={TallyData} />
            <Route path="/admin/company/IncidentData" exact component={IncidentData} />
            <Route path="/admin/boards" exact component={AdminTasks} />
            <Route path="/admin/inventory/products" exact component={ProductList} />
            <Route path="/admin/inventory/assignments" exact component={ProductAssignmentList} />
            <Route path="/admin/inventory/warranties" exact component={ProductWarrantyList} />
            <Route path="/admin/inventory/subscriptions" exact component={SubscriptionList} />
            <Route path="/admin/timesheetList" exact component={TimesheetList} />
            <Route path="/admin/company/reimbursementList" exact component={ReimbursementList} />
            <Route path="/admin/company/tallyList" exact component={TallyList} />
            <Route path="/admin/company/incidentList" exact component={IncidentList} />
            <Route path="/admin/inventory/product" exact component={ProductData} />
            <Route path="/admin/inventory/assignment" exact component={ProductAssignmentData} />
            <Route path="/admin/inventory/warranty" exact component={ProductWarrantyData} />
            <Route path="/admin/inventory/subscription" exact component={SubscriptionData} />
            <Route path="/admin/attendance" exact component={Attendance} />
            <Route path="/admin/inventories/productsList" exact component={ProductsList} />
            <Route path="/admin/inventories/saleList" exact component={SaleList} />
            <Route path="/admin/inventories/stockList" exact component={StockList} />
            <Route path="/admin/inventories/productsForm" exact component={ProductsForm} />
            <Route path="/admin/inventories/saleForm" exact component={SaleForm} />
            <Route path="/admin/inventories/productsData" exact component={ProductsData} />
            <Route path="/admin/inventories/saleData" exact component={SaleData} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getData } from "../../../../serverRequest";
import LoadingSpinner from "../../../../components/Loadder";
import SummarisedAdminReportTable from "../../../../components/Tables/SummarisedAdminReportTable";
import PieChart from "./PieChart";
import BarChart from "./BarChart";

export default function AdminSummarisedReport({ color }) {
  const [totalTickets, setTotalTickets] = React.useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      let resAdminData = await getData('/api/reports/summarised_admin_report');
      let tickets_admin_data = resAdminData['data']['tickets_data'];
      let ticketData = await filterTicketData(tickets_admin_data);
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      renderPieChart();
    }
  }, [totalTickets]);

  const filterTicketData = (tickets_data) => {
    let totalTicket = [];
    if (tickets_data) {
      for (let i = 0; i < tickets_data.length; i++) {
        let ticket_set = {
          'employee_id': tickets_data[i]['employee_id'],
          'employee_name': tickets_data[i]['employee_name'],
          'assigned': 0,
          'approved': 0,
          'pending': 0,
          'resolved': 0,
          'inprogress': 0,
          'total': 0
        }
        for (let j = 0; j < tickets_data[i]['dataset'].length; j++) {
          if (tickets_data[i]['dataset'][j]['status'] === 'Resolved') {
            ticket_set['resolved'] = tickets_data[i]['dataset'][j]['total']
          }
          else if (tickets_data[i]['dataset'][j]['status'] === 'Assigned') {
            ticket_set['assigned'] = tickets_data[i]['dataset'][j]['total']
          }
          else if (tickets_data[i]['dataset'][j]['status'] === 'Approved') {
            ticket_set['approved'] = tickets_data[i]['dataset'][j]['total']
          }
          else if (tickets_data[i]['dataset'][j]['status'] === 'Pending') {
            ticket_set['pending'] = tickets_data[i]['dataset'][j]['total']
          }
          else if (tickets_data[i]['dataset'][j]['status'] === 'IN Progress') {
            ticket_set['inprogress'] = tickets_data[i]['dataset'][j]['total']
          }
        }
        ticket_set['total'] = ticket_set['resolved'] + ticket_set['assigned'] + ticket_set['approved'] + ticket_set['pending']
        totalTicket.push(ticket_set)
      }
    }
    setTotalTickets(totalTicket);
    return ({ "total": totalTicket })
  }

  const renderPieChart = () => {
    // No need to implement the rendering logic here anymore
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " + (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
          <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
            <div className="w-full xl:w-12/12 px-4">
              <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                  <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                    </div>
                    <div className="flex justify-between pb-8">
                      <div className="w-1/2 pr-4">
                        <PieChart totalTickets={totalTickets} />
                      </div>
                      <div className="w-1/2 pl-4">
                        <BarChart totalTickets={totalTickets} />
                      </div>
                    </div>
                    <div>
                    <h6 className="text-blueGray-700 text-xl font-bold pb-6">Engineer Tickets</h6>
                      <SummarisedAdminReportTable totalTickets={totalTickets} />
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AdminSummarisedReport.defaultProps = {
  color: "light",
};

AdminSummarisedReport.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

import React, { useState } from "react";
import { getData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";

export default function ServiceList() {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const sublist = searchParams.get('sl');
  //console.log(categorydata);
  const [ticketType, setTicketType] = React.useState([]);
  const formatTicketType = (ticket_type_data) => {
    let ticket_type_datas = [];
    if (ticket_type_data) {
      for (let i = 0; i < ticket_type_data.length; i++) {
        let href = '/user/newRequest?tt=' + ticket_type_data[i]['id']
        ticket_type_datas.push({ label: ticket_type_data[i]['type'], href: href, current: true, description: '', imagelink: '', icon: 'fa fa-laptop' })
      }
    }

    return ticket_type_datas
  }

  React.useEffect(() => {

    async function fetchData() {
      let url = '/api/company/ticket_type/?category_id=' + sublist;
      let resData = await getData(url)
      //console.log(resData);
      let ticket_type_data = resData['data']
      //console.log(ticket_type_data);
      if (ticket_type_data['data']) {
        let ticketTypeData = await formatTicketType(ticket_type_data['data'])
        setTicketType(ticketTypeData)
        setLoading(false);
      }
    }
    fetchData();
  }, [])
  return (
    <>
{loading ? (
                    <LoadingSpinner></LoadingSpinner>
                ) : (<>
      <div className="relative bg-lightBlue-800 pb-32 pt-12">
        <div className="flex flex-wrap py-1 justify-center">
          <div className="w-full lg:w-6/12 xl:w-4/12 px-4 item-center">
            <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
              <div className="relative flex w-full flex-wrap items-stretch">
                <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  placeholder="Search your issue here"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                />
              </div>
            </form>
          </div>

        </div>
        <div className="px-4 md:px-10 mx-auto w-full">
          <div></div>

          <div className="flex flex-wrap">
            {ticketType.map((item) => (
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"><a href={item.href}>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-xl font-semibold">{item.label}</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      {item.description}
                    </p>
                  </div>
                </div></a>
              </div>
            ))}
          </div>
        </div>
      </div>

    </>)}
    </>
  );
}

import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function ProductAssignmentData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const productId = searchParams.get('product_id');
  const [showModal, setShowModal] = React.useState(false);
  const [assignedTo, setAssignedTo] = useState();
  const [assignedOn, setAssignedOn] = useState();
  const [entity, setEntity] = useState();
  const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let { assigned_to, assigned_on, entity } = document.forms[0];
    let reqData = {
      'product_id': productId,
      'assigned_to': assigned_to ? assigned_to.value : '',
      'assigned_on': assigned_on ? assigned_on.value : '',
      'entity': entity ? entity.value : '',
    };

    let resData = await postData('/api/inventory/', reqData); 
    if (resData['data']['code'] === 200) {
      setLoading(false);
      setShowModal(true);
      window.location.href = '/admin/inventory'; 
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/inventory/product_asignment_data?product_id='+productId);
      let productData = resData['data']['product'];
      console.log(productData);
      if(productData){
        setAssignedTo(productData['assigned_to']);
        setAssignedOn(productData['assigned_on']);
        setEntity(productData['entity']);
      }
      setLoading(false);
    }
    fetchData();
  }, [productId]);

  return (
    <div className="product-assignment-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product Assignment ID : {productId}</h6>
                <button className="cursor-pointer opacity-30  px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" onClick={() => setIsEditable(isEditable?false:true)}>
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="assignedTo">Assigned To</label>
                  <input type="text"
                         id="assignedTo"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="assignedTo" defaultValue={assignedTo} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="assignedOn">Assigned On</label>
                  <input type="text" 
                         id="assignedOn"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="assignedOn" defaultValue={assignedOn} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="entity">Entity</label>
                  <input type="text" 
                         id="entity"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="entity" defaultValue={entity} />
                </div>
              </div> 
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}        
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { getCompanyList } from "../../../serverRequest";
import AssignmentReportList from "./AssignmentReport/AssignmentReportList.js";
import { CSVLink } from "react-csv";

export default function AssignmentReport() {
  const [companies, setCompanies] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [tableData, setTableData] = useState([]);

  const handleChange = selectedOption => {
    setCompanyData(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  const fetchCompanyData = async () => {
    try {
      let companyList = await getCompanyList();
      if (companyList && companyList.data) {
        companyList = companyList.data;
        let companyData = companyList[0].value;
        setCompanyData(companyData);
        setCompanies(companyList);
        setSelectedOption(companyList[0]);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  React.useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleDownload = () => {
    const csvData = [
      ["Header 1", "Header 2", "Header 3"], 
      ...tableData
    ];

    const csvString = csvData.map(row => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "assignment_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <main>
      <div className="relative block py-24 lg:pt-0 px-4">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="flex justify-between">
            <div className="w-full lg:w-4/12 px-4 items-left">
              <h6 className="text-blueGray-700 text-xl font-bold">Assignment Report</h6>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <Select name="clist" options={companies} value={selectedOption} onChange={handleChange} placeholder="Select Entity" />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-6">
              <div className="w-full lg:w-2/12 px-0 items-right">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleDownload}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto py-24 lg:pt-0" key={companyData}> 
          {/* Warranty Report list */}
        <AssignmentReportList companyId={companyData} setTableData={setTableData} />
        </div> 
      </div>
    </main>
  );
}

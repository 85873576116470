import React, { useState }  from "react";

// components
import HomeNavbar from "../../components/Navbars/HomeNavbar";
import UserSolutions from "../../components/Cards/UserSolutions.js";

export default function Solutions() {
  
  return (
    <>
      <HomeNavbar transparent />
      <main>
        <div className="relative block py-24 lg:pt-0 ">
          <UserSolutions></UserSolutions>
        </div>
      </main>
      
    </>
  );
}

import React from 'react';
import { Toolbar } from 'react-big-calendar';

const AttendanceToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>Back</button>
        <button type="button" onClick={goToCurrent}>Today</button>
        {/* The Next button is removed */}
      </span>
      <span className="rbc-toolbar-label">{toolbar.label}</span>
    </div>
  );
};

export default AttendanceToolbar;

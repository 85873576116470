import React from "react";

// components
import { getData } from "../../../serverRequest";
import SummarisedCompanyReport from "./CompanyReport/SummarisedCompanyReport";

export default function CompanyReport() {
    // const [open, setOpen] = React.useState(true);
    const [openTicket, setOpenTicket] = React.useState(true);
    const [closedTicket, setclosedTicket] = React.useState(true);
    const [totalTicket, settotalTicket] = React.useState(true);
    //const [loading, setLoading] = useState(true);

    // const toggleDrawer = () => {
    //   setOpen(!open);
    // };

    const filterTicketData = (tickets_data) => {
        let openTicketCount = 0;
        let closeTicketCount = 0;
        let totalTicketCount = 0;
        if (tickets_data) {
            totalTicketCount = tickets_data.length;
            for (let i = 0; i < tickets_data.length; i++) {
                if (tickets_data[i]['status'] !== 'Resolved') {
                    openTicketCount++;
                }
                else if (tickets_data[i]['status'] === 'Resolved') {
                    closeTicketCount++;
                }
            }
        }

        return ({ "open": openTicketCount, "close": closeTicketCount, "total": totalTicketCount })
    }
    React.useEffect(() => {

        async function fetchData() {
            console.log("tickets_data");
            let resAdminData = await getData('/api/reports/full_admin_report')
            let tickets_admin_data = resAdminData['data']['tickets_data']
            console.log(resAdminData);
          //console.log(tickets_data);
          //let ticketData = await filterTicketData(tickets_admin_data)
    
         
          //setLoading(false);
        }
        //fetchData();
    


    }, [])
    return (
        <>
            <div className="relative block py-24 lg:pt-0 ">
                <SummarisedCompanyReport></SummarisedCompanyReport>
            </div>
        

        </>
    );
}

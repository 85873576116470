import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder";
import { postData } from "../../../../serverRequest";

export default function ProductForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [serialNumber] = useState();
  const [oem] = useState();
  const [modelNumber] = useState();
  const [brandName] = useState();
  const [productType] = useState();
  const [vendor] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const { serial_number, oem, model_number, brand_name, product_type, vendor } = document.forms[0];

    const formData = {
      'serial_number': serial_number ? serial_number.value : '',
      'oem': oem ? oem.value : '',
      'model_number': model_number ? model_number.value : '',
      'brand_name': brand_name ? brand_name.value : '',
      'product_type': product_type ? product_type.value : '',
      'vendor': vendor ? vendor.value : '',
    };

    try {
      const res = await postData("/api/inventory/", formData);

      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = "/admin/inventory";
      } else {
        console.error("Failed to submit form:", res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  return (
    <div className="product-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="serialNumber">
                    Serial Number
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="serialNumber" defaultValue={serialNumber} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="oem">
                    OEM
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="oem" defaultValue={oem} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="modelNumber">
                    Model Number
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="modelNumber" defaultValue={modelNumber} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="brandName">
                    Brand Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="brandName" defaultValue={brandName} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="productType">
                    Product Type
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="productType" defaultValue={productType} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="vendor">
                    Vendor
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="vendor" defaultValue={vendor} />
                </div>
              </div>
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
              <input
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                type="Submit"
                value="Submit"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { getCompanyList } from "../../../serverRequest";
import FarReportList from "./FarReport/FarReportList.js";
import { CSVLink } from "react-csv";
import FarReportListTable from "../../../components/Tables/FarReportListTable";
import { getData } from "../../../serverRequest";
import LoadingSpinner from "../../../components/Loadder";
import moment from 'moment';

export default function FarReport() {
  const [companies, setCompanies] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState();
  const [farReport, setfarReport] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);

  const ticketFilter = searchParams.get('filter');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const filterFarData = (far_data) => {
    //console.log(tickets_data);
    let far_report = [];
    if (far_data) {
      for (let i = 0; i < far_data.length; i++) {
        far_report.push(far_data[i]);
      }
    }
    //console.log(totalTicket);
    setfarReport(far_report);
    return ({ "farReport": far_data })
  }

  async function fetchFarData() {
    if (companyData) {
      let resData = await getData('/api/inventory/far_report/?company_id=' + companyData)
      let far_data = resData['data']['far_data']
      let farData = await filterFarData(far_data)
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleChange = async (selectedOption) => {
    await setCompanyData(selectedOption.value);
    await setSelectedOption(selectedOption);
    await fetchFarData();
  };

  const fetchCompanyData = async () => {
    try {
      let companyList = await getCompanyList();
      if (companyList && companyList.data) {
        companyList = companyList.data;
        let companyData = companyList[0].value;
        await setCompanyData(companyData);
        await setSelectedCompanyId(selectedCompanyId);
        setCompanies(companyList);
        setSelectedOption(companyList[0]);
        await fetchFarData();
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };


  const handleDownload = () => {

    const headers = Object.keys(farReport[0]);
    const csvRows = farReport.map(row =>
      headers.map(fieldName => JSON.stringify(row[fieldName], null, 2)).join(',')
    );
    console.log(csvRows)
    csvRows.unshift(headers.join(','));
    // const csvData = [
    //   ["S.No.",	"Poduct Name",	"OEM",	"Tagging Number",	"Serial number",	"Hardware/Software",	"Health Status",	"UserName",	"Vendor",	"Product", "Price",	"DOP",	"Invoice No",	"Location",	"Invoice copy"], 
    //   ...csvRows
    // ];
    // console.log(csvData);
    const csvString = csvRows.map(row => csvRows.join('\n'));
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "far_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <main>
      <div className="relative block py-24 lg:pt-0 px-4">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="flex justify-between">
            <div className="w-full lg:w-4/12 px-4 items-left">
              <h6 className="text-blueGray-700 text-xl font-bold">FAR Report</h6>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <Select name="clist" options={companies} value={selectedOption} onChange={handleChange} placeholder="Select Entity" />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-6">
              <div className="w-full lg:w-2/12 px-0 items-right">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleDownload}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto py-24 lg:pt-0" key={companyData}>
          {/* Far Report list */}
          <>
            {loading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : (<>
              <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " + ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
                <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4" key={companyData}>
                  {/* FarReport table */}
                  {farReport?<FarReportListTable farData={farReport}></FarReportListTable>:''}
                </div>
              </div>
            </>)}
          </>
        </div>
      </div>
    </main>
  );
}

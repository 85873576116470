import React from "react";

// components

import AdminAssigneddTicket from "../../../components/Cards/AdminAssignedTicket.js";

export default function Ticket() {
  const searchParams = new URLSearchParams(window.location.search);
  const sublist = searchParams.get('tt');
  
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <AdminAssigneddTicket sublist={sublist} />
        </div>
        
      </div>
    </>
  );
}

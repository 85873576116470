import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function SubscriptionData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const productId = searchParams.get('product_id');
  const [showModal, setShowModal] = React.useState(false);
  const [entityName, setEntityName] = useState();
  const [serviceName, setServiceName] = useState();
  const [subscriptionStartDate, setSubscriptionStartDate] = useState();
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();
  const [licenseCount, setLicenseCount] = useState();
  const [isEditable, setIsEditable] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let { entity_name, service_name, subscription_start_date, subscription_end_date, license_count } = document.forms[0];
    
    let reqData = {
      'product_id': productId,
      'entity_name': entity_name ? entity_name.value : '',
      'service_name': service_name ? service_name.value : '',
      'subscription_start_date': subscription_start_date ? subscription_start_date.value : '',
      'subscription_end_date': subscription_end_date ? subscription_end_date.value : '',
      'license_count': license_count ? license_count.value : '',
    };

    let resData = await postData('/api/inventory/', reqData); 
    if (resData['data']['code'] === 200) {
      setLoading(false);
      setShowModal(true);
      window.location.href = '/admin/inventory'; 
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/inventory/subscription_data?product_id='+productId);
      let subscriptionData = resData['data']['subscription'];
      console.log(subscriptionData);
      if(subscriptionData){
        setEntityName(subscriptionData['entity_name']);
        setServiceName(subscriptionData['service_name']);
        setSubscriptionStartDate(subscriptionData['subscription_start_date']);
        setSubscriptionEndDate(subscriptionData['subscription_end_date']);
        setLicenseCount(subscriptionData['license_count']);
      }
      setLoading(false);
    }
    fetchData();
  }, [productId]);

  return (
    <div className="subscription-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product Subscription ID : {productId}</h6>
                <button className="cursor-pointer opacity-30  px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" onClick={() => setIsEditable(isEditable?false:true)}>
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="entityName">Entity Name</label>
                  <input type="text"
                         id="entityName"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="entityName" defaultValue={entityName} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="serviceName">Service/App Name</label>
                  <input type="text" 
                         id="serviceName"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="serviceName" defaultValue={serviceName} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="subscriptionStartDate">Subscription Start Date</label>
                  <input type="date" 
                         id="subscriptionStartDate"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="subscriptionStartDate" defaultValue={subscriptionStartDate} />
                </div>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="subscriptionEndDate">Subscription End Date</label>
                  <input type="date" 
                         id="subscriptionEndDate"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="subscriptionEndDate" defaultValue={subscriptionEndDate} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="licenseCount">License Count</label>
                  <input type="number" 
                         id="licenseCount"
                         className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                         disabled={isEditable?false:true}
                         name="licenseCount" defaultValue={licenseCount} />
                </div>
              </div>  
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"/>                            
              </div>
              )}
          </div>
        </form>
      )}
    </div>
  );
}

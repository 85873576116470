import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { postData } from "../../../../serverRequest";

export default function SalesForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);  
  const [productId] = useState();
  const [client] = useState();  
  const [receivingPo] = useState();  
  const [invoice] = useState();  
  const [sellingBasePrice] = useState();  
  const [tax] = useState();  
  const [dcNo] = useState();  
  const [remarks] = useState();  
  const [saleDate] = useState();  
  const [location] = useState(); 

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    
    const { productId, client, receivingPo, invoice, sellingBasePrice, tax, dcNo, remarks, saleDate, location } = document.forms[0];
  
    const formData = {
      'productId': productId ? productId.value : '',
      'client': client ? client.value : '',
      'receivingPo': receivingPo ? receivingPo.value : '',
      'invoice': invoice ? invoice.value : '',
      'sellingBasePrice': sellingBasePrice ? sellingBasePrice.value : '',
      'tax': tax ? tax.value : '',
      'dcNo': dcNo ? dcNo.value : '',
      'remarks': remarks ? remarks.value : '',
      'saleDate': saleDate ? saleDate.value : '',
      'location': location ? location.value : '',
    };
  
    try {
      const res = await postData("/api/sales/", formData);
      
      if (res.status === 200) {
        setLoading(false);
        setShowModal(true);
        window.location.href = '/admin/sales';
      } else {
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };
   
  return (
    <div className="sales-form-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Sale Form</h6>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">  
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="product-id">Product ID</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="productId" defaultValue={productId} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="client">Client</label>
                <input type="text"
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="client" defaultValue={client} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="receiving-po">Receiving PO</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="receivingPo" defaultValue={receivingPo} />
              </div>
            </div>            
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="invoice">Invoice</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="invoice" defaultValue={invoice} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="selling-base-price">Selling Base Price</label>
                <input type="number" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="sellingBasePrice" defaultValue={sellingBasePrice} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="tax">Tax</label>
                <input type="number" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="tax" defaultValue={tax} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="dc-no">DC No.</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="dcNo" defaultValue={dcNo} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="remarks">Remarks</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="remarks" defaultValue={remarks} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="sale-date">Sale Date</label>
                <input type="date" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="saleDate" defaultValue={saleDate} />
              </div>
            </div>  
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="location">Location</label>
                <input type="text" 
                       className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                       name="location" defaultValue={location} />
              </div>
            </div>  
            </div>
            <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                    type="Submit"
                    value="Submit"
                />                            
            </div>          
          </div>
        </form>
      )}
    </div>
  );
}
